<template>
    <div class="search-box" :class="{ active: selector?.model }">
        <form
            @submit.prevent="() => null"
            v-if="!selector.model"
            class="search-box__form"
        >
            <SearchIcon />
            <input
                id="search-input"
                type="text"
                v-model="keyword"
                :placeholder="`${
                    expected?.param === 'make'
                        ? t('page-supported-cars_search-for-your-car-brand')
                        : t('page-supported-cars_search-for-your-car-model')
                }`"
            />
        </form>

        <Spinner key="spinner" v-if="selector.generation || loading" />

        <div class="results-list" :class="{ active: selector?.model }">
            <ul v-if="!loading">
                <li
                    v-for="option in expected.options
                        ? expected.options.filter((option) =>
                              option.title
                                  .toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase())
                          )
                        : []"
                    @click="
                        handleStep({
                            name: expected.param,
                            value: option,
                        })
                    "
                    :key="option.id"
                    :class="
                        isClassActive(
                            'results-list__item',
                            expected.param === 'generation'
                        )
                    "
                >
                    <div>
                        <span>{{ option?.title }}</span>
                        <p
                            class="results-list__years"
                            v-if="expected.param === 'generation'"
                        >
                            <span class="year">{{
                                t('page-supported-cars_year')
                            }}</span>
                            {{
                                option.startYear +
                                (option.endYear !== 0
                                    ? ' - ' + option.endYear
                                    : '+')
                            }}
                        </p>
                    </div>
                    <ArrowRightIcon v-if="expected.param === 'generation'" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useTranslation } from 'i18next-vue';
import SearchIcon from '@/assets/icons/SearchIcon.vue';
import Spinner from '@/assets/icons/Spinner.vue';
import ArrowRightIcon from '@/assets/icons/supportedCars/ArrowRightIcon.vue';
import {
    isClassActive,
    pathToPricing,
    facebookGoogleAnalitycs,
} from '@/utils/helpers';

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useTranslation(['page-supported-cars']);

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
});

const expected = computed(() => store.state.vehiclebrands.wizard.expected);
const selector = computed(() => store.state.vehiclebrands.wizard.selector);
const dynamicVehiclesData = computed(
    () => store.state.vehiclebrands.dynamicVehiclesData
);
const langCode = computed(() => store.state.language.lang?.code);
const adapterMinimalRequirement = computed(() =>
    dynamicVehiclesData.value?.features?.adapter === 'evo' ? true : false
);

const keyword = ref('');

function handleStep(event) {
    if (event.name === 'generation') {
        store.dispatch('vehiclebrands/getDynamicVehiclesData', {
            make: selector.value.make.id,
            model: selector.value.model.id,
            generation: event.value.id,
        });

        facebookGoogleAnalitycs('vehicle_check', {
            brand: selector.value.make.id,
            model: selector.value.model.id,
            generation: event.value.id,
        });

        setTimeout(() => {
            router.push(
                pathToPricing(
                    langCode.value,
                    dynamicVehiclesData?.value.selector?.make?.id,
                    adapterMinimalRequirement.value
                )
            );
        }, 1000);
        return;
    }

    router.push({
        name: event.name,
        params: {
            ...route.params,
            [event.name]: event.value.id,
        },
        query: route.query,
    });
}

watch(route, () => {
    keyword.value = '';
});
</script>
