import i18next from 'i18next';
import router from '../config/router.config';

export default {
    namespaced: true,
    state: {
        lang: null,
        siteId: null,
        all: [
            { name: 'English', code: 'en', icon: 'gb', siteId: 1 },
            { name: 'Deutsch', code: 'de', icon: 'de', siteId: 2 },
            { name: 'Español', code: 'es', icon: 'es', siteId: 3 },
            { name: 'Japanese', code: 'ja', icon: 'ja', siteId: 4 },
            { name: 'Русский', code: 'ru', icon: 'ru', siteId: 5 },
            { name: 'Italian', code: 'it', icon: 'it', siteId: 6 },
            { name: 'Français', code: 'fr', icon: 'fr', siteId: 7 },
        ],
    },
    mutations: {
        lang(state, lang) {
            state.lang = lang;
        },
    },
    actions: {
        changeLanguage({ commit, state }, lang) {
            let selectedLang = state.all.find((l) => l.code === lang.code);

            if (!selectedLang) {
                selectedLang = state.all.find((l) => l.code === 'en');
            }

            i18next.changeLanguage(selectedLang.code);
            commit('lang', selectedLang);
            router.push({
                name: router.currentRoute.value.name || 'home',
                params: {
                    ...router.currentRoute.value.params,
                    lang: selectedLang.code,
                },
            });
        },
        initLanguage({ commit, state }, code) {
            commit(
                'lang',
                state.all.find((lang) => lang.code === code)
            );
        },
    },
};
