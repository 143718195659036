import axios from './http.service';
import { mapLangCodeToFullName } from '@/utils/helpers';

class Faq {
  getFaq(lang, categoryId) {
    const query = {
      query: `
      {
        faqsByCategory(
          input: {
            language: ${mapLangCodeToFullName(lang)},
            environment: ${process.env.VUE_APP_WEB_CMS_ENV},
            categoryId: "${categoryId}"
          }
        ) {
          faqs {
            answer
            environment
            faqCategoryId
            question
            id
            language
          }
          result {
            message
            status
          }
        }
      }`
    }

    return axios.post(`${process.env.VUE_APP_WEB_CMS}`, query)
      .then(res => res.data);
  }
}

export default new Faq();
