import { mobileAndTabletCheck } from "@/utils/helpers";

export default {
  namespaced: true,
  state: {
    isMobile: null,
  },
  mutations: {
    isMobile(state, payload) {
      state.isMobile = payload;
    },
  },
  actions: {
    isMobile({ commit }) {
      commit("isMobile", mobileAndTabletCheck());
    },
  },
};
