import Auth from "../services/auth.service";
import app from "../main";

export default {
  namespaced: true,
  state: {
    isPasswordResetRequestModalOpen: false,
    isPasswordResetInstructionsModalOpen: false,
    isLoginModalOpen: false,
    isSignupModalOpen: false,
    redirect: null,
    isSignupWorkerModalOpen: false,
    isSignupBusinessModalOpen: false,
    isSignupSuccessModalOpen: false,
    is2FAModalOpen: false,
    isAuthenticated: null,
    role: null,
    successEmail: null,
    successRole: null,
  },
  mutations: {
    role(state, payload) {
      state.role = payload;
    },
    redirect(state, payload) {
      state.redirect = payload;
    },
    isLoginModalOpen(state, payload) {
      state.isLoginModalOpen = payload;
    },
    isSignupModalOpen(state, payload) {
      state.isSignupModalOpen = payload;
    },
    isSignupSuccessModalOpen(state, payload) {
      state.successEmail = payload.user;
      state.successRole = payload.role;
      state.isSignupSuccessModalOpen = payload.open;
    },
    isPasswordResetRequestModalOpen(state, payload) {
      state.isPasswordResetRequestModalOpen = payload;
    },
    isPasswordResetInstructionsModalOpen(state, payload) {
      state.successEmail = payload.user;
      state.isPasswordResetInstructionsModalOpen = payload.open;
    },
    is2FAModalOpen(state, payload) {
      state.is2FAModalOpen = payload;
    },
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.hasOwnProperty("isAuthenticated")
        ? payload.isAuthenticated
        : false;
      state.redirect = payload.hasOwnProperty("redirect")
        ? payload.redirect
        : null;
    },
  },
  actions: {
    login(store, user) {
      return Auth.login(user);
    },
    signup({ dispatch }, user) {
      return Auth.register(user);
    },
    isAuthenticated() {
      Auth.isAuthenticated();
    },
    authSuccess({ commit, dispatch }, payload) {
      commit("isAuthenticated", payload.isAuthenticated);
      dispatch("redirect", payload.redirect);
      commit("isSignupWorkerModalOpen", { open: false });
      commit("isSignupBusinessModalOpen", { open: false });
      app.$store.dispatch("profileNotifications/getProfileNotifications", {
        root: true,
      });
      app.$store.dispatch("countries/getStates", { root: true });
      app.$store.dispatch("skills/getAllSkills", { root: true });
      app.$store.dispatch("weekDays/getWeekDays", { root: true });
    },
    logout({ commit, dispatch }) {
      Auth.logout().finally(() => {
        commit("is2FAModalOpen", false);
        app.$store.dispatch("resetState");
        app.$router.push({ name: "login-page" });
      });
    },
    setRole({ commit }, role) {
      localStorage.setItem(ROLE_KEY, role);
      commit("role", role);
    },
    unauthorized({ state, dispatch }) {
      if (
        !state.isAuthenticated &&
        (app.$route.matched[0].name === "dashboard" ||
          app.$route.matched[0].name === "dashboard-business")
      ) {
        dispatch("logout");
      }
    },
    redirect({ state, commit }, payload) {
      if (state.redirect) {
        app.$router.push({ name: state.redirect });
        commit("redirect", null);
        return;
      }
      return app.$router.push(
        payload ? { name: payload } : app.$router.currentRoute
      );
    },
    resetState({ commit }) {
      commit("isAuthenticated", { isAuthenticated: false, redirect: null });
      commit("role", null);
    },
  },
};
