<template>
  <Transition name="fade-slide">
    <div
      v-if="modalData"
      class="modal-wrapper-dark-background"
      @click.self="close"
    >
      <div class="sale-modal">
        <div class="sale-modal__description">
          <button
            name="close-btn"
            class="close-btn"
            @click="close"
          >
            <XCloseIcon />
          </button>
          <SaleTag :text="t('shared_free-diagnostics')" />
          <p>
            {{ t("shared_enjoy-free-lifetime-diagnostics") }}
          </p>
          <h4 style="margin-top: -10px;font-size: 20px;">
            {{ t("shared_fast-easy-lifetime-access") }}
          </h4>
          <a
            class="btn-feedback-outlined"
            @click="getNow"
            href="https://www.amazon.co.uk/dp/B0CM9W4MCW"
            target="_blank"
          >
            {{ t('shared_get-now') }}
            <ArrowRightIcon />
          </a>
          <a href="javascript:void(0);" @click="close" style="
            text-align: center;
            font-weight: 600;
            color: #3973b9;
            font-size: 16px;"
          >Close</a>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from 'vuex';
import XCloseIcon from "@/assets/icons/XCloseIcon";
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon";
import { useTranslation } from "i18next-vue";
import SaleTag from "../SaleTag.vue";
import { facebookGoogleAnalitycs } from "@/utils/helpers";

const { t } = useTranslation(['shared', 'translation']);

const store = useStore();

const modalData = computed(() => store.state.modals?.isSaleModalOpen);

const img = `${process.env.VUE_APP_APP}/images/Carista-EVO-OBD-Scanner-and-home-screen-of-the-Carista-Vehicle-diagnostic-software.webp`;

function close() {
  facebookGoogleAnalitycs('get_now_close_button');

  store.dispatch("resetModalStates");
}

function getNow() {
  facebookGoogleAnalitycs('get_now');

  store.dispatch("resetModalStates");
}
</script>
