<template>
  <div id="supported-cars-wrapper" class="supported-cars-wrapper">
    <BreadCrumb class="hide-on-mobile" />
    <SupportedCarsResultPage
      v-if="
        $router.currentRoute.value.params.make &&
        $router.currentRoute.value.params.model &&
        $router.currentRoute.value.params.generation
      "
    />
    <div v-else class="container supported-cars-content">
      <SupportedCarsHeaders />
      <div class="search-box-buttons" :class="{ reset: selector?.make }">
        <button v-if="selector?.make" class="search-box__reset-btn" @click="reset">
          <ResetIcon /> {{ t("page-supported-cars_reset") }}
        </button>
        <router-link
          class="show-on-mobile"
          :to="pathToPricing(langCode, selector?.make?.id)"
        >
          {{ t("page-supported-cars_skip") }}
        </router-link>
      </div>
      <SupportedCarsSearchBox :loading="loading" />
      <p>
        {{
          t(
            "page-supported-cars_exact-features-availability-depends-on-your-vehicle"
          )
        }}
      </p>
      <WizardProgressBar :showBtns="true" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ResetIcon from "@/assets/icons/ResetIcon";
import BreadCrumb from "@/components/breadcrumb/BreadCrumb";
import WizardProgressBar from "@/components/supported-cars/WizardProgressBar";
import SupportedCarsHeaders from "@/components/supported-cars/SupportedCarsHeaders";
import SupportedCarsSearchBox from "@/components/supported-cars/SupportedCarsSearchBox";
import SupportedCarsResultPage from "./SupportedCarsResultPage.vue";
import { createMetaInfo, addParamsToStringFromCMS, pathToPricing } from "@/utils/helpers";
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';

const store = useStore();
const router = useRouter();
const { t } = useTranslation(['page-supported-cars']);
const loading = ref(false);

const selector = computed(() => store.state.vehiclebrands.wizard.selector);
const langCode = computed(() => store.state.language.lang?.code);
const supported_cars = computed(() => store.state.pages.supported_cars);

const reset = () => {
  router.push({ name: "supported-cars", query: router.currentRoute.value.query });
};

onMounted(async () => {
  document.body.classList.remove("overflow-hidden");
  loading.value = true;
  await store.dispatch("vehiclebrands/getDynamicVehiclesData", {
    make: router.currentRoute.value.params.make,
    model: router.currentRoute.value.params.model,
    generation: router.currentRoute.value.params.generation,
  });
  loading.value = false;
  await store.dispatch("vehiclebrands/getSummary");
});

watch(router.currentRoute, async (route) => {
  loading.value = true;
  if (!route.params.make || !route.params.model) {
    store.commit("vehiclebrands/resetWizard");
  }
  await store.dispatch("vehiclebrands/getDynamicVehiclesData", {
    make: route.params.make,
    model: route.params.model,
    generation: route.params.generation,
  });
  loading.value = false;
}, { deep: true });

useHead(() => {
  let canonical = "";
  let vehicleName = "";
  Object.values(selector.value).forEach((tag) => {
    canonical += `/${tag.id}`;
    vehicleName += ` ${tag.title}`;
  });

  const metaTitle = addParamsToStringFromCMS(supported_cars.value?.metaTitle, [vehicleName]);
  const metaDescription =
    addParamsToStringFromCMS(supported_cars.value?.metaDescription, [vehicleName]);

  return createMetaInfo({
    title: metaTitle,
    pageMeta: {
      ...supported_cars.value,
      metaDescription: metaDescription,
      canonicalHref: `${supported_cars.value?.canonicalHref}${canonical}`,
    },
  });
});
</script>
