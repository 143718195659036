<template>
  <button
    class="button-on-mobile"
    @click="toggle()"
  >
    <span v-if="(
      (max && numberOfItemsShown >= step && numberOfItemsShown < max) || (!max && !isOpenState)
    )">
      <div>{{ t("page-home_see-more") }}</div><div><ArrowDownIcon /></div>
    </span>
    <span v-else>
      <div>{{ t("page-home_see-less") }}</div><div><ArrowDownIcon class="rotate180" /></div>
    </span>
  </button>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import ArrowDownIcon from "@/assets/icons/supportedCars/ArrowDownIcon.vue";

const { t } = useTranslation(['page-home']);

const props = defineProps({
  isOpen: Boolean,
  max: Number,
  step: Number
});

const emit = defineEmits(['toggle']);

const store = useStore();

const numberOfItemsShown = ref(props.step);
let isOpenDefault = false;
if (props.isOpen === true) isOpenDefault = true;
const isOpenState = ref(isOpenDefault);

function toggle() {
  if (props.max && props.step) {
    if (numberOfItemsShown.value >= props.max) {
      numberOfItemsShown.value = props.step;
    } else {
      numberOfItemsShown.value += props.step;
    }
    emit('toggle', numberOfItemsShown.value);
  } else {
    isOpenState.value = !isOpenState.value;
    emit('toggle', isOpenState.value);
  }
}
</script>
