<template>
  <svg
    width="123"
    height="31"
    viewBox="0 0 123 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M88.8182 12.9067V15.6473H87.0873V17.4445H88.8182V23.5513C88.8182 25.6366 89.7687 26.4707 92.1599 26.4707C92.5802 26.4707 92.9804 26.421 93.3306 26.3614V24.584C93.0304 24.6138 92.8403 24.6337 92.5101 24.6337C91.4396 24.6337 90.9693 24.1372 90.9693 23.0052V17.4445H93.3306V15.6473H90.9693V12.9067H88.8182Z"
      fill="white"
    />
    <path
      d="M99.4222 26.6196C102.604 26.6196 104.555 24.5046 104.555 20.9994C104.555 17.5141 102.594 15.3891 99.4222 15.3891C96.2405 15.3891 94.2795 17.5141 94.2795 20.9994C94.2795 24.5046 96.2305 26.6196 99.4222 26.6196ZM99.4222 24.723C97.5512 24.723 96.5006 23.3627 96.5006 20.9994C96.5006 18.656 97.5512 17.2857 99.4222 17.2857C101.283 17.2857 102.344 18.656 102.344 20.9994C102.344 23.3527 101.283 24.723 99.4222 24.723Z"
      fill="white"
    />
    <path
      d="M106.224 26.4111H108.375V20.0263C108.375 18.507 109.526 17.4843 111.157 17.4843C111.537 17.4843 112.177 17.5538 112.357 17.6133V15.5082C112.127 15.4487 111.717 15.4189 111.397 15.4189C109.976 15.4189 108.765 16.1934 108.455 17.2559H108.295V15.5976H106.224V26.4111Z"
      fill="white"
    />
    <path
      d="M117.699 17.2062C119.289 17.2062 120.33 18.3084 120.38 20.0064H114.877C114.997 18.3184 116.108 17.2062 117.699 17.2062ZM120.37 23.4917C119.97 24.3358 119.079 24.8025 117.779 24.8025C116.058 24.8025 114.947 23.601 114.877 21.7044V21.5852H122.571V20.8405C122.571 17.4445 120.74 15.3891 117.709 15.3891C114.637 15.3891 112.686 17.5836 112.686 21.0391C112.686 24.4946 114.597 26.6196 117.719 26.6196C120.21 26.6196 121.951 25.428 122.441 23.4917H120.37Z"
      fill="white"
    />
    <path
      d="M74.7114 22.4178C74.8773 25.0731 77.1069 26.7713 80.4254 26.7713C83.9719 26.7713 86.1912 24.9908 86.1912 22.1502C86.1912 19.9169 84.926 18.6819 81.8564 17.9718L80.2076 17.5704C78.258 17.1175 77.4699 16.5103 77.4699 15.4502C77.4699 14.1123 78.6935 13.2375 80.5291 13.2375C82.2712 13.2375 83.4742 14.0917 83.6919 15.4605H85.9526C85.8178 12.9596 83.5986 11.1997 80.5602 11.1997C77.2936 11.1997 75.1158 12.9596 75.1158 15.6046C75.1158 17.7865 76.3499 19.0833 79.0565 19.7111L80.9853 20.1742C82.966 20.6373 83.8371 21.3166 83.8371 22.4487C83.8371 23.7661 82.4683 24.7232 80.6016 24.7232C78.6002 24.7232 77.2106 23.8278 77.0136 22.4178H74.7114Z"
      fill="white"
    />
    <path
      d="M52.4279 15.4189C50.9471 15.4189 49.6664 16.1537 49.0061 17.385H48.846V15.5976H46.7749V30.0056H48.9261V24.7727H49.0961C49.6664 25.9146 50.8971 26.5898 52.4479 26.5898C55.1993 26.5898 56.9503 24.4351 56.9503 20.9994C56.9503 17.5637 55.1993 15.4189 52.4279 15.4189ZM51.8176 24.6734C50.0166 24.6734 48.886 23.2634 48.886 21.0093C48.886 18.7453 50.0166 17.3353 51.8276 17.3353C53.6485 17.3353 54.7391 18.7155 54.7391 20.9994C54.7391 23.2931 53.6485 24.6734 51.8176 24.6734Z"
      fill="white"
    />
    <path
      d="M64.4774 15.4189C62.9966 15.4189 61.716 16.1537 61.0556 17.385H60.8955V15.5976H58.8245V30.0056H60.9756V24.7727H61.1457C61.716 25.9146 62.9466 26.5898 64.4974 26.5898C67.2489 26.5898 68.9998 24.4351 68.9998 20.9994C68.9998 17.5637 67.2489 15.4189 64.4774 15.4189ZM63.8671 24.6734C62.0662 24.6734 60.9356 23.2634 60.9356 21.0093C60.9356 18.7453 62.0662 17.3353 63.8771 17.3353C65.6981 17.3353 66.7886 18.7155 66.7886 20.9994C66.7886 23.2931 65.6981 24.6734 63.8671 24.6734Z"
      fill="white"
    />
    <path
      d="M42.9154 26.4111H45.3835L39.9807 11.5599H37.4815L32.0786 26.4111H34.4637L35.843 22.4693H41.5466L42.9154 26.4111ZM38.6118 14.2564H38.7881L40.9555 20.5344H36.4341L38.6118 14.2564Z"
      fill="white"
    />
    <path
      d="M33.5239 0.358364V7.52265H36.1303C38.2814 7.52265 39.5271 6.20697 39.5271 3.91817C39.5271 1.66412 38.2714 0.358364 36.1303 0.358364H33.5239ZM34.6445 1.3712H36.0052C37.501 1.3712 38.3865 2.31452 38.3865 3.93306C38.3865 5.57643 37.516 6.50982 36.0052 6.50982H34.6445V1.3712Z"
      fill="white"
    />
    <path
      d="M43.3421 7.62692C44.9329 7.62692 45.9084 6.5694 45.9084 4.81681C45.9084 3.07414 44.9279 2.01166 43.3421 2.01166C41.7512 2.01166 40.7707 3.07414 40.7707 4.81681C40.7707 6.5694 41.7462 7.62692 43.3421 7.62692ZM43.3421 6.67863C42.4066 6.67863 41.8813 5.99844 41.8813 4.81681C41.8813 3.6451 42.4066 2.95995 43.3421 2.95995C44.2726 2.95995 44.8028 3.6451 44.8028 4.81681C44.8028 5.99348 44.2726 6.67863 43.3421 6.67863Z"
      fill="white"
    />
    <path
      d="M54.2158 2.11592H53.1402L52.1697 6.24172H52.0847L50.9641 2.11592H49.9335L48.813 6.24172H48.7329L47.7574 2.11592H46.6668L48.1676 7.52265H49.2732L50.3938 3.54084H50.4788L51.6044 7.52265H52.72L54.2158 2.11592Z"
      fill="white"
    />
    <path
      d="M55.4544 7.52265H56.53V4.36004C56.53 3.51601 57.0353 2.98974 57.8307 2.98974C58.6261 2.98974 59.0063 3.42168 59.0063 4.29053V7.52265H60.0819V4.02243C60.0819 2.73653 59.4115 2.01166 58.1959 2.01166C57.3755 2.01166 56.8352 2.3741 56.57 2.97484H56.49V2.11592H55.4544V7.52265Z"
      fill="white"
    />
    <path
      d="M61.7758 7.52265H62.8513V0.00585938H61.7758V7.52265Z"
      fill="white"
    />
    <path
      d="M66.8965 7.62692C68.4873 7.62692 69.4628 6.5694 69.4628 4.81681C69.4628 3.07414 68.4823 2.01166 66.8965 2.01166C65.3056 2.01166 64.3251 3.07414 64.3251 4.81681C64.3251 6.5694 65.3006 7.62692 66.8965 7.62692ZM66.8965 6.67863C65.961 6.67863 65.4357 5.99844 65.4357 4.81681C65.4357 3.6451 65.961 2.95995 66.8965 2.95995C67.8269 2.95995 68.3572 3.6451 68.3572 4.81681C68.3572 5.99348 67.8269 6.67863 66.8965 6.67863Z"
      fill="white"
    />
    <path
      d="M72.6675 6.71338C72.0822 6.71338 71.657 6.43039 71.657 5.94383C71.657 5.4672 71.9971 5.214 72.7475 5.16435L74.0782 5.07994V5.53175C74.0782 6.202 73.4779 6.71338 72.6675 6.71338ZM72.3923 7.61202C73.1077 7.61202 73.703 7.3042 74.0282 6.76303H74.1132V7.52265H75.1488V3.8288C75.1488 2.68688 74.3784 2.01166 73.0127 2.01166C71.777 2.01166 70.8966 2.60745 70.7865 3.53587H71.827C71.9471 3.15358 72.3623 2.93513 72.9626 2.93513C73.698 2.93513 74.0782 3.25784 74.0782 3.8288V4.2955L72.6024 4.3799C71.3068 4.45934 70.5764 5.02037 70.5764 5.99348C70.5764 6.98148 71.3418 7.61202 72.3923 7.61202Z"
      fill="white"
    />
    <path
      d="M78.7987 7.61202C79.5491 7.61202 80.1844 7.25952 80.5096 6.6687H80.5946V7.52265H81.6252V0.00585938H80.5496V2.97484H80.4696C80.1744 2.37906 79.5441 2.02656 78.7987 2.02656C77.423 2.02656 76.5375 3.1089 76.5375 4.81681C76.5375 6.52968 77.413 7.61202 78.7987 7.61202ZM79.1039 2.98477C80.0043 2.98477 80.5696 3.69475 80.5696 4.82177C80.5696 5.95376 80.0093 6.6538 79.1039 6.6538C78.1934 6.6538 77.6481 5.96369 77.6481 4.81681C77.6481 3.67985 78.1984 2.98477 79.1039 2.98477Z"
      fill="white"
    />
    <path
      d="M88.5999 7.62692C90.1907 7.62692 91.1662 6.5694 91.1662 4.81681C91.1662 3.07414 90.1857 2.01166 88.5999 2.01166C87.009 2.01166 86.0285 3.07414 86.0285 4.81681C86.0285 6.5694 87.004 7.62692 88.5999 7.62692ZM88.5999 6.67863C87.6644 6.67863 87.1391 5.99844 87.1391 4.81681C87.1391 3.6451 87.6644 2.95995 88.5999 2.95995C89.5303 2.95995 90.0606 3.6451 90.0606 4.81681C90.0606 5.99348 89.5303 6.67863 88.5999 6.67863Z"
      fill="white"
    />
    <path
      d="M92.59 7.52265H93.6655V4.36004C93.6655 3.51601 94.1708 2.98974 94.9662 2.98974C95.7616 2.98974 96.1418 3.42168 96.1418 4.29053V7.52265H97.2174V4.02243C97.2174 2.73653 96.547 2.01166 95.3314 2.01166C94.511 2.01166 93.9707 2.3741 93.7055 2.97484H93.6255V2.11592H92.59V7.52265Z"
      fill="white"
    />
    <path
      d="M102.171 0.770448V2.14075H101.306V3.03939H102.171V6.09278C102.171 7.1354 102.646 7.55244 103.842 7.55244C104.052 7.55244 104.252 7.52762 104.427 7.49783V6.60912C104.277 6.62402 104.182 6.63394 104.017 6.63394C103.482 6.63394 103.247 6.3857 103.247 5.81971V3.03939H104.427V2.14075H103.247V0.770448H102.171Z"
      fill="white"
    />
    <path
      d="M105.871 7.52265H106.946V4.36501C106.946 3.5458 107.437 2.9947 108.317 2.9947C109.078 2.9947 109.483 3.43161 109.483 4.2955V7.52265H110.558V4.03236C110.558 2.74646 109.843 2.01663 108.682 2.01663C107.862 2.01663 107.287 2.37906 107.022 2.98477H106.936V0.00585938H105.871V7.52265Z"
      fill="white"
    />
    <path
      d="M114.438 2.92023C115.234 2.92023 115.754 3.47133 115.779 4.32032H113.028C113.088 3.4763 113.643 2.92023 114.438 2.92023ZM115.774 6.06299C115.574 6.485 115.129 6.71835 114.478 6.71835C113.618 6.71835 113.063 6.1176 113.028 5.16931V5.10973H116.875V4.73737C116.875 3.03939 115.959 2.01166 114.443 2.01166C112.908 2.01166 111.932 3.1089 111.932 4.83667C111.932 6.56444 112.888 7.62692 114.448 7.62692C115.694 7.62692 116.565 7.03113 116.81 6.06299H115.774Z"
      fill="white"
    />
    <path
      d="M17.8358 14.2235C17.8619 12.2126 18.95 10.3099 20.6762 9.25662C19.5872 7.71309 17.7632 6.73445 15.8655 6.67552C13.8415 6.46467 11.8792 7.87754 10.8478 7.87754C9.79644 7.87754 8.20841 6.69646 6.49836 6.73138C4.26938 6.80285 2.19142 8.06055 1.10697 9.99456C-1.22415 14.0001 0.514658 19.8868 2.74768 23.1246C3.86491 24.71 5.17063 26.481 6.87914 26.4182C8.55102 26.3494 9.17543 25.3601 11.1935 25.3601C13.1929 25.3601 13.7787 26.4182 15.5219 26.3782C17.3159 26.3493 18.4462 24.7858 19.5242 23.1853C20.327 22.0556 20.9447 20.8071 21.3545 19.4859C19.2462 18.601 17.8382 16.4953 17.8358 14.2235Z"
      fill="white"
    />
    <path
      d="M14.5432 4.54622C15.5214 3.38083 16.0033 1.88293 15.8866 0.370605C14.3922 0.52638 13.0117 1.23522 12.0203 2.35589C11.0509 3.45085 10.5464 4.9224 10.6418 6.37692C12.1367 6.3922 13.6064 5.70257 14.5432 4.54622Z"
      fill="white"
    />
  </svg>
</template>
