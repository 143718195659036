export default {
    namespaced: true,
    state:{
        isLangModalOpen: null,
        isCustomizeModalOpen: false,
        isLiveDataModalOpen: false,
        isServiceModalOpen: false,
        isVendorModalOpen: false,
        isNotificationModalOpen: false,
        isSaleModalOpen: false,
    },
    mutations:{
        isLangModalOpen(state, isOpen) {
            state.isLangModalOpen = isOpen;
        },
        isCustomizeModalOpen(state, payload) {
            state.isCustomizeModalOpen = payload;
        },
        isServiceModalOpen(state, payload) {
            state.isServiceModalOpen = payload;
        },
        isLiveDataModalOpen(state, payload) {
            state.isLiveDataModalOpen = payload;
        },
        isVendorModalOpen(state, payload) {
            state.isVendorModalOpen = payload;
        },
        isNotificationModalOpen(state, payload) {
            state.isNotificationModalOpen = payload;
        },
        isSaleModalOpen(state, payload) {
            state.isSaleModalOpen = payload;
        },
    },
    actions: {
        resetAll({ commit }) {
            // Close all modals
            commit('isCustomizeModalOpen', false);
            commit('isLiveDataModalOpen', false);
            commit('isServiceModalOpen', false);
            commit('isVendorModalOpen', false);
            commit("isLangModalOpen", false);
            commit('isNotificationModalOpen', false);
            commit('isSaleModalOpen', false);

            // Allow page scrolling after the open modal is closed
            document.body.classList.remove('overflow-hidden');
        },
        openModal({ commit }, { currentModal, data }) {
            // Open current modal
            commit(currentModal, false);

            let payloadData = { ...data };

            if (currentModal === "isVendorModalOpen" || currentModal === "isSaleModalOpen") {
                payloadData = data;
            }

            setTimeout(()=> commit(currentModal, payloadData), 50);

            if(currentModal === "isLangModalOpen") return;
            // On open modal prevent page scroll
            document.body.classList.add('overflow-hidden');
        },

        nextModal({state,rootState,dispatch}){
            const stateEntries = Object.entries({...state});

            for(let i = 0;i<stateEntries.length;i++){
                const objectValue = stateEntries[i][1];
                const objectKey = stateEntries[i][0];
                let dataKey = "";

                if(objectKey === 'isCustomizeModalOpen'){
                    dataKey='customize';
                }else if(objectKey === 'isLiveDataModalOpen'){
                    dataKey='liveData';
                }else if(objectKey === 'isServiceModalOpen'){
                    dataKey='service';
                }

                if(objectValue && objectValue.current !== undefined){
                    const max = rootState.vehiclebrands.dynamicVehiclesData.features[dataKey]?.length;
                    if(max === objectValue.next) break;
                        const positionY = objectValue.positionY;
                        dispatch('openModal',{currentModal:objectKey,
                                        data:{
                                            prev:objectValue.prev+1,
                                            current:objectValue.current+1,
                                            next:objectValue.next+1,
                                            ...(positionY && {positionY: positionY})}
                        });
                        break;
                }


            }
        },

        prevModal({state,dispatch}){
            const stateEntries = Object.entries(state);
            for(let i = 0;i<stateEntries.length;i++){
                const objectValue = stateEntries[i][1];
                const objectKey = stateEntries[i][0];

                if(objectValue){
                    if(objectValue?.current === 0 || isNaN(objectValue?.current)) break;
                    const positionY = objectValue.positionY;

                    dispatch('openModal',
                        {currentModal: objectKey,
                            data:{
                                prev: objectValue.prev-1,
                                current: objectValue.current-1,
                                next: objectValue.next-1,
                                ...(positionY && {positionY: positionY}) }
                    });
                    break;
                }
            }
        }
    }
}
