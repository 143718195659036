<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.08019 0.722965C0.881362 0.937155 0.780807 1.21129 0.798968 1.48965V13.5822C0.781835 13.8605 0.882267 14.1343 1.08019 14.3489L1.12622 14.3902L8.66827 7.61398V7.45329L1.12622 0.681641L1.08019 0.722965Z" fill="url(#paint0_linear_10312_8733)"/>
    <path d="M11.1837 9.87086L8.66797 7.61213V7.45144L11.1837 5.19727L11.2399 5.22481L14.2209 6.74441C15.0749 7.17594 15.0749 7.88754 14.2209 8.32372L11.2399 9.84326L11.1837 9.87086Z" fill="url(#paint1_linear_10312_8733)"/>
    <path d="M11.2381 9.84332L8.68149 7.54785L1.07812 14.3471C1.25338 14.4764 1.47158 14.5498 1.69838 14.5556C1.92516 14.5614 2.14768 14.4993 2.33087 14.3792L11.2228 9.84332" fill="url(#paint2_linear_10312_8733)"/>
    <path d="M11.2381 5.22309L2.33087 0.682629C2.14964 0.557531 1.92566 0.492797 1.69717 0.499496C1.46869 0.506194 1.24988 0.583911 1.07812 0.719356L8.66619 7.53231L11.2381 5.22309Z" fill="url(#paint3_linear_10312_8733)"/>
    <path opacity="0.1" d="M11.1855 9.79199L2.33449 14.3004C2.15794 14.4184 1.94364 14.4823 1.72345 14.4823C1.50328 14.4823 1.28898 14.4184 1.11242 14.3004L1.06641 14.3417L1.11242 14.383C1.28898 14.5011 1.50328 14.5649 1.72345 14.5649C1.94364 14.5649 2.15794 14.5011 2.33449 14.383L11.2264 9.84711L11.1855 9.79199Z" fill="#1D1D1B"/>
    <path opacity="0.07" d="M1.07938 14.2723C0.881582 14.056 0.781432 13.7807 0.79881 13.501V13.579C0.781713 13.8573 0.881916 14.1311 1.07938 14.3457L1.12529 14.3044L1.07938 14.2723Z" fill="#1D1D1B"/>
    <path opacity="0.07" d="M14.2209 8.23809L11.1836 9.7898L11.2399 9.84034L14.2209 8.30235C14.3957 8.23326 14.547 8.12381 14.6593 7.9851C14.7716 7.84638 14.8409 7.68332 14.8601 7.5127C14.8252 7.6704 14.7489 7.81843 14.6378 7.94457C14.5267 8.07072 14.3838 8.17128 14.2209 8.23809Z" fill="#1D1D1B"/>
    <path opacity="0.3" d="M2.33475 0.764502L14.223 6.81998C14.3844 6.88512 14.5264 6.9835 14.6374 7.10711C14.7484 7.23079 14.8255 7.37619 14.8622 7.5316C14.8441 7.36063 14.7753 7.19721 14.6628 7.05832C14.5504 6.91944 14.3985 6.81017 14.223 6.74194L2.33475 0.681862C1.49618 0.250317 0.800781 0.608409 0.800781 1.48528V1.56333C0.800781 0.691049 1.49618 0.32836 2.33475 0.764502Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_10312_8733" x1="8.00353" y1="1.3611" x2="-1.12077" y2="11.5235" gradientUnits="userSpaceOnUse">
    <stop stop-color="#008EFF"/>
    <stop offset="0.01" stop-color="#008FFF"/>
    <stop offset="0.26" stop-color="#00ACFF"/>
    <stop offset="0.51" stop-color="#00C0FF"/>
    <stop offset="0.76" stop-color="#00CDFF"/>
    <stop offset="1" stop-color="#00D1FF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_10312_8733" x1="14.3232" y1="7.53407" x2="-0.413104" y2="7.53407" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFD800"/>
    <stop offset="1" stop-color="#FF8A00"/>
    </linearGradient>
    <linearGradient id="paint2_linear_10312_8733" x1="9.84228" y1="8.7874" x2="-2.52743" y2="22.5643" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF3A44"/>
    <stop offset="1" stop-color="#B11162"/>
    </linearGradient>
    <linearGradient id="paint3_linear_10312_8733" x1="-0.834229" y1="-3.30691" x2="4.6938" y2="2.84492" gradientUnits="userSpaceOnUse">
    <stop stop-color="#328E71"/>
    <stop offset="0.07" stop-color="#2D9571"/>
    <stop offset="0.48" stop-color="#15BD74"/>
    <stop offset="0.8" stop-color="#06D575"/>
    <stop offset="1" stop-color="#00DE76"/>
    </linearGradient>
    </defs>
  </svg>
</template>