class Subscribe {
  async subscribe(formData) {
    return await fetch(process.env.VUE_APP_MAILCHIMP_API, {
      headers: {
        accept: "application/json, text/plain, /",
      },
      body: formData,
      method: "POST",
      mode: "no-cors",
      credentials: "omit",
    });
  }
}

export default new Subscribe();
