import Faq from "@/services/faq.service";

export default {
  namespaced: true,
  state: {
    faq: [],
  },
  mutations: {
    faq(state, payload) {
      state.faq = payload;
    },
    reset(state){
      state.faq = [];
    }
  },
  actions: {
    getFaq({ state, commit }, { lang = 'en', categoryId = 1 }) {
      return Faq.getFaq(lang, categoryId).then(({data}) => {
        const faqEntries = data.faqsByCategory.faqs;

        const updatedData = [...state.faq];
        updatedData[categoryId - 1] = faqEntries;

        commit("faq", updatedData);

        return state.faq;
      });
    },
  },
};
