<template>
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 7.99997L13.0329 16L12.2394 15.2071L18.8589 8.56276L0.739124 8.56276L0.739124 7.43724L18.8589 7.43724L12.2394 0.792903L13.0329 6.96509e-07L21 7.99997Z"
      fill="#787F8C"
    />
  </svg>
</template>
