import Testimonials from "@/services/testimonials.service";

export default {
  namespaced: true,
  state: {
    testimonials: [],
  },
  mutations: {
    testimonials(state, payload) {
      state.testimonials = payload;
    },
    reset(state){
      state.testimonials = []
    }
  },
  actions: {
    getTestimonials({ state, commit }, { lang = 'en', categoryId = 1 }) {
      return Testimonials.getTestimonials(lang, categoryId).then(({data}) => {
        const testimonialsEntries = data.testimonialsByCategory.testimonials;

        const updatedData = [...state.testimonials];
        updatedData[categoryId - 1] = testimonialsEntries;

        commit("testimonials", updatedData);

        return state.testimonials;
      });
    },
  },
};
