<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.75 5.42188C10.6953 5.44531 9.71094 5.11719 8.86719 4.50781V8.70312C8.86719 11.6328 5.67969 13.4609 3.14844 12.0078C0.617188 10.5312 0.617188 6.875 3.14844 5.39844C3.875 4.97656 4.74219 4.8125 5.58594 4.92969V7.03906C4.29688 6.61719 3.05469 7.76562 3.33594 9.07812C3.64062 10.3906 5.23438 10.9062 6.24219 9.99219C6.59375 9.66406 6.80469 9.19531 6.80469 8.70312V0.5H8.86719C8.86719 0.6875 8.86719 0.851562 8.91406 1.03906C9.05469 1.8125 9.5 2.49219 10.1797 2.91406C10.625 3.21875 11.1875 3.38281 11.75 3.38281V5.42188Z"
      fill="white"
    />
  </svg>
</template>
