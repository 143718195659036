<template>
  <Transition name="fade-slide">
    <div v-if="isSignupModalOpen" class="modal-wrapper">
      <div class="login-signup-modal">
        <p class="login-signup-modal__title">
          {{ t('shared_create-an-account') }}
          <button @click="closeSignupModal"><XCloseIcon /></button>
        </p>
        <p class="login-signup-modal__description">{{ t('shared_sign-up-with-your-account') }}</p>
        <MaterialInput type="text" :label="t('shared_name')" />
        <MaterialInput type="text" :label="t('shared_email')" />
        <MaterialInput type="text" :label="t('shared_password')" />
        <div class="login-signup-modal__checkbox-wrapper">
          <Checkbox :label="t('shared_i-agree-with')" />
          <button class="btn-link">{{ t('shared_terms-of-use-and-conditions') }}</button>
        </div>
        <button class="blue-btn-primary">{{ t('shared_sign-up') }} <ArrowRightIcon /></button>
        <p class="login-signup-modal__text">{{ t('shared_or-sign-in-with') }}</p>
        <button class="btn-outlined">
          <GoogleColoredIcon /> {{ t('shared_sign-in-with') }} Google
        </button>
        <button class="btn-outlined">
          <MetaIcon />{{ t('shared_sign-in-with') }} Facebook
        </button>
        <p class="login-signup-modal__text-secondary">
          {{ t('shared_alredy-have-an-account') }}
          <button class="btn-link" @click.prevent="openLoginModal">
            {{ t('shared_log-in-now') }}
          </button>
        </p>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon.vue";
import XCloseIcon from "@/assets/icons/XCloseIcon.vue";
import MaterialInput from "@/components/forms/MaterialInput.vue";
import Checkbox from "@/components/forms/Checkbox.vue";
import GoogleColoredIcon from "@/assets/icons/GoogleColoredIcon.vue";
import MetaIcon from "@/assets/icons/MetaIcon.vue";

const store = useStore();
const { t } = useTranslation(['shared']);

const isSignupModalOpen = computed(() => store.state.auth.isSignupModalOpen);

function closeSignupModal() {
  store.commit("auth/isSignupModalOpen", false);
}

function openLoginModal() {
  store.commit("auth/isSignupModalOpen", false);
  store.commit("auth/isLoginModalOpen", true);
}
</script>
