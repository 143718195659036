<template>
    <div class="blog-page-inner">
        <div class="container">
            <div class="page">
                <h1>{{ openedArticle?.title }}</h1>
                <div class="img-container">
                    <img
                        :src="openedArticle?.picture?.url"
                        :alt="openedArticle?.picture?.altText"
                        :title="openedArticle?.picture?.title"
                        loading="lazy"
                    />
                    <span class="tag">{{ openedArticle?.tags[0]?.name }}</span>
                </div>
                <div class="article-text-container">
                    <div class="date-container">
                        <div class="date">
                            <img
                                :src="tools?.images[0].url"
                                loading="lazy"
                            /> Carista team / {{ blogCreatedAtFormatted(openedArticle?.createdAt) }}
                        </div>
                        <ShareButton :article="openedArticle" />
                    </div>
                    <div class="article-text" v-html="openedArticle?.description"></div>
                </div>
            </div>
            <div class="related-articles">
                <div class="title-container">
                    <span>{{ t("page-blog_related-articles") }}</span>
                    <router-link
                        :to="`/blog`"
                        class="btn-feedback-outlined lighter"
                        v-if="!isMobile"
                    >
                        {{ t("page-blog_view-all") }} <ArrowRightIcon />
                    </router-link>
                </div>
                <div class="articles-container">
                    <div class="article-card" v-for="article in relatedArticles">
                        <div
                            class="img-container"
                            @click="openArticle({
                                name: 'slug',
                                title: article.title,
                                slug: article.slug,
                                id: article.id
                            })"
                        >
                            <img
                                :src="article?.picture?.url"
                                :alt="article?.picture?.altText"
                                :title="article?.picture?.title"
                                loading="lazy"
                            />
                            <span class="tag">{{ article?.tags[0]?.name }}</span>
                        </div>
                        <div class="article-card__text">
                            <div class="date-container">
                            <div class="date">{{ blogCreatedAtFormatted(article?.createdAt) }} / Carista team</div>
                            <ShareButton :article="article" />
                            </div>
                            <div
                                class="article-title"
                                @click="openArticle({
                                    name: 'slug',
                                    title: article.title,
                                    slug: article.slug,
                                    id: article.id
                                })"
                            >{{ article?.title }}</div>
                            <button
                                @click="openArticle({
                                    name: 'slug',
                                    title: article.title,
                                    slug: article.slug,
                                    id: article.id
                                })"
                                class="blog-btn-primary"
                            >
                            {{ t("page-blog_read-more") }} <ArrowRightIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <router-link
                    :to="`/blog`"
                    class="btn-feedback-outlined lighter view-all-btn"
                    v-if="isMobile"
                >
                    {{ t("page-blog_view-all") }} <ArrowRightIcon />
                </router-link>
            </div>
        </div>
        <Embark
            :title="[
                adapter?.headings?.h4[2],
                adapter?.headings?.h4[3],
                adapter?.headings?.h4[4],
            ]"
            :btnSubtitle="t('shared_check-what-we-support-for-your-car')"
            fbEventParam="blog inner page"
        />
    </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import BreadCrumb from "../components/breadcrumb/BreadCrumb.vue";
import Embark from "../components/Embark.vue";
import ArrowRightIcon from "../assets/icons/supportedCars/ArrowRightIcon.vue";
import ShareButton from "../components/blog/ShareButton.vue";
import { blogCreatedAtFormatted } from "../utils/helpers";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation(['page-blog', 'shared', 'translation']);

const router = useRouter();
const route = useRoute();
const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);

const adapter = computed(() => store.state.pages?.adapter);
const tools = computed(() => store.state.pages.tools);
const openedArticle = computed(() => store.state.blog?.openedArticle);
const articlesByTag = computed(() => store.state.blog?.articlesByTag?.articlesByTag?.articles);

const relatedArticles = computed(() => {
    if (openedArticle.value?.relatedArticles.length > 0) {
        return openedArticle.value?.relatedArticles;
    }

    return articlesByTag.value;
});

async function getArticleData(routeSlug) {
    if (routeSlug) {
        await store.dispatch("blog/getArticleBySlug", routeSlug);

        if (!openedArticle.value) {
            router.push({ name: 'blog' });

            openNotificationModal({
                showSuccess: false,
                titleTxt: t("shared_page-not-found"),
                descriptionTxt: t("shared_article-doesnt-exist"),
                btnTxt: t("translation_ok")
            });
        }

        if (openedArticle.value?.relatedArticles.length === 0) {
            const tagData = {
                relatedArticles: true,
                tagId: openedArticle.value.tags[0].id,
                page: 1,
                itemsPerPage: 4,
            };
            await store.dispatch("blog/getArticlesByTag", tagData);
        }
    } else {
        router.push("/blog");
    }
}

function openArticle(event) {
    if (route.params.slug !== event.slug) {
        router.push({
            name: event.name,
            params: {
                ...route.params,
                [event.name]: event.title,
                slug: event.slug,
                id: event.id,
            },
        });
    }
}

function openNotificationModal(option) {
  store.dispatch("modals/openModal", {
    currentModal: "isNotificationModalOpen",
    data: option,
  });
}

onMounted(() => {
    document.body.classList.remove("overflow-hidden");
    getArticleData(route.params.slug);

    // remove the scrollbar of the video iframes
    setTimeout(() => {
        const iframe = document.querySelectorAll('.article-text iframe');
        iframe.forEach(item => item.scrolling = "no");
    }, 1000);
});

watch(route, (to, from) => {
    getArticleData(to.params.slug);
});
</script>
