<template>
  <svg
    width="37"
    height="33"
    viewBox="0 0 37 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.815 8.38795L28.815 10.3349V10.9815L29.3162 11.3254L31.46 12.7962L31.7211 12.9753H32.0295H33.2002L31.4904 14.2564L25.5458 14.2564L20.4435 10.151L20.1602 9.92316H19.8085L1.38786 9.92316L1.38786 12.2625L19.4567 12.2625L24.559 16.3678L24.8423 16.5957H25.194L31.8223 16.5957H32.1543L32.428 16.3906L36.5349 13.3133L37 12.9648V12.3487V11.8057V10.636L35.9293 10.636H32.3378L30.9564 9.68824V8.38795L30.9564 8.20778L30.9564 6.90749L32.3378 5.95973L35.9293 5.95973L37 5.95973V4.79008V4.24702V3.63094L36.5349 3.28246L32.428 0.205099L32.1543 4.5075e-07L31.8223 4.82448e-07L25.194 0L24.8422 3.35924e-08L24.559 0.227885L19.4567 4.33327L7.05664 4.33327V6.67257L19.8085 6.67257H20.1602L20.4435 6.44469L25.5458 2.3393L31.4904 2.3393L33.2002 3.62043H32.0295L31.7211 3.62043L31.46 3.79957L29.3162 5.27037L28.815 5.61427V6.26088V8.38795ZM8.18502 24.612L8.18502 22.6651V22.0185L7.68377 21.6746L5.53998 20.2038L5.27886 20.0247H4.97052H3.79983L5.50958 18.7436H11.4542L16.5565 22.849L16.8398 23.0768H17.1915L35.6121 23.0768V20.7375L17.5433 20.7375L12.441 16.6322L12.1577 16.4043H11.806H5.17766H4.84574L4.57202 16.6094L0.465068 19.6867L0 20.0352V20.6513V21.1943V22.364H1.07071H4.66219L6.04361 23.3118L6.04361 24.612L6.04361 24.7922L6.04361 26.0925L4.66219 27.0403H1.07071H0V28.2099V28.753V29.3691L0.465068 29.7175L4.57202 32.7949L4.84574 33H5.17766L11.806 33H12.1577L12.441 32.7721L17.5433 28.6667L29.9434 28.6667V26.3274L17.1915 26.3274H16.8398L16.5565 26.5553L11.4542 30.6607L5.50958 30.6607L3.79983 29.3796H4.97053H5.27886L5.53998 29.2004L7.68377 27.7296L8.18502 27.3857V26.7391V24.612Z"
      :fill="`url(#${id})`"
    />
    <defs>
      <linearGradient
        :id="id"
        x1="-1.88638"
        y1="16.4135"
        x2="77.9556"
        y2="16.4135"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>

export default{
  data(){
    return {
      id:Math.random(1000) + 'gradient-id'
    }
  }
}

</script>
