<template>
  <div class="progres-bar-wrapper">
    <router-link
      class="all-supported-cars-link"
      :to="`/${langCode}/supported-cars/all`"
      v-if="showBtns"
    >
      {{ t("page-supported-cars_all-supported-cars") }}
    </router-link>
    <div
      :class="`step-progress-bar ${
        selector.generation ? 'done' : expected.param
      }`"
    >
      <div
        :class="
          isClassActive('item brand-item', selector.make || !selector.model)
        "
      >
        <div class="item-content-wrapper">
          <Transition name="fade-slide" mode="out-in">
            <span v-if="selector.make" class="item__selected">{{
              isMobile
                ? selector.make?.title.substr(0, 8)
                : selector.make?.title
            }}</span>
          </Transition>
          <span :class="isClassActive('item__title', !selector.make)">{{
            t("page-supported-cars_car-brand")
          }}</span>
          <span :class="isClassActive('item__vertical-line', true)"></span>
        </div>
      </div>
      <div
        :class="
          isClassActive(
            'item model-item',
            (selector.make && !selector.model) || selector.model
          )
        "
      >
        <div class="item-content-wrapper">
          <Transition name="fade-slide" mode="out-in">
            <span v-if="selector.model" class="item__selected">{{
              isMobile
                ? selector.model?.title.substr(0, 8)
                : selector.model?.title
            }}</span>
          </Transition>
          <span
            :class="
              isClassActive('item__title', selector.make && !selector.model)
            "
            >{{ t("page-supported-cars_car-model") }}</span
          >
          <span
            :class="isClassActive('item__vertical-line', selector.make)"
          ></span>
        </div>
      </div>
      <div
        :class="
          isClassActive(
            'item generation-item',
            selector.model || selector.generation
          )
        "
      >
        <div class="item-content-wrapper">
          <Transition name="fade-slide" mode="out-in">
            <span v-if="selector.generation" class="item__selected">{{
              isMobile
                ? selector.generation?.title.substr(0, 8)
                : selector.generation?.title
            }}</span>
          </Transition>
          <span
            :class="
              isClassActive(
                'item__title',
                selector.model || selector.generation
              )
            "
          >
            {{ t("page-supported-cars_car-gen") }}
          </span>
          <span
            :class="
              isClassActive(
                'item__vertical-line',
                selector.model || selector.generation
              )
            "
          ></span>
        </div>
      </div>
    </div>
    <router-link
      v-if="lang && showBtns"
      class="hide-on-mobile"
      :to="pathToPricing(langCode, selector?.make?.id)"
    >
      {{ t("page-supported-cars_skip") }}
    </router-link>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import { isClassActive, pathToPricing } from "@/utils/helpers";

const store = useStore();
const { t } = useTranslation(['page-supported-cars']);

const props = defineProps({
  showBtns: Boolean,
});

const expected = computed(() => store.state.vehiclebrands.wizard.expected);
const selector = computed(() => store.state.vehiclebrands.wizard.selector);
const lang = computed(() => store.state.language?.lang);
const langCode = computed(() => store.state.language.lang?.code);
const isMobile = computed(() => store.state.device.isMobile);
</script>
