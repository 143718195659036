<template>
  <svg
    alt="arrow icon"
    width="9"
    :class="_class"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15234 0.145508V2.68295L4.15234 5.85476L0.152344 2.68295V0.145508L4.15234 3.33494L8.15234 0.145508Z"
      fill="#9197A0"
    />
  </svg>
</template>
<script>
export default {
  props: ["_class"],
};
</script>
