<template>
  <div>
    <transition name="fade" mode="out-in">
      <div class="default-page">
        <div v-if="!loaded" class="loading-wrapper">
          <AppLoading />
        </div>
        <div v-else key="policies">
          <BreadCrumb />
          <div class="page">
            <div class="container">
              <h1 class="title">{{ t("impressum_title") }}</h1>
              <div class="content" v-html="t('impressum_content')"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import AppLoading from "../components/AppLoading";
import { createMetaInfo } from "@/utils/helpers";
import { useHead } from '@unhead/vue';

export default {
  components: {
    AppLoading,
    BreadCrumb,
  },
  setup() {
    const store = useStore();
    const { t } = useTranslation(['impressum']);
    const loaded = ref(false);
    const impressum = computed(() => store.state.pages.impressum);

    onMounted(() => {
      document.body.classList.remove("overflow-hidden");
      loaded.value = true;
    });

    useHead(() => {
      return createMetaInfo({
        title: impressum.value?.metaTitle,
        pageMeta: impressum.value,
      });
    });

    return {
      t,
      impressum,
      loaded,
    };
  },
};
</script>
