<template>
  <div v-if="adapterDocs" class="adapter-documents">
    <BreadCrumb></BreadCrumb>
    <div class="container-wrapper page">
      <div class="headings-container container">
        <h1>{{ adapterDocs?.headings.h1[0] }}</h1>
        <h3>{{ adapterDocs?.headings.h3[0] }}</h3>
      </div>
      <div class="docs-wrapper">
        <div class="container">
          <div class="docs-container">
            <h2>{{ adapterDocs?.headings.h2[0] }}</h2>
            <div class="docs-buttons">
              <div
                class="doc-btn"
                v-for="(manual, index) in showContainer('manuals', manuals)"
                :key="`manual-${index}`"
              >
                <img
                  :src="manual?.images[0].url"
                  :alt="manual?.images[0].imageMeta.metaAltDescription"
                  :title="manual?.images[0].imageMeta.metaTitle"
                  loading="lazy"
                />
                <a :href="manual?.documents[0]?.url" class="doc-title" target="_blank">
                  {{ manual?.title }}
                </a>
              </div>
            </div>
            <ExpandContainerBtn
              class="center"
              v-if="isMobile && manuals?.length > 3"
              @toggle="toggleOpenContainer('manuals')"
              :max="manuals.length"
              :step="3"
            />
          </div>
          <div class="docs-container certificates">
            <h2>{{ adapterDocs?.headings.h2[1] }}</h2>
            <div class="docs-buttons">
              <div
                class="doc-btn"
                v-for="(certificate, index) in showContainer('certificates', certificates)"
                :key="`certificate-${index}`"
              >
                <DocumentIcon />
                <a
                  :href="certificate?.documents.length
                  ? certificate?.documents[0]?.url
                  : certificate?.images[0]?.url"
                  class="doc-title"
                  target="_blank"
                >
                  {{ certificate?.title }}
                </a>
              </div>
            </div>
            <ExpandContainerBtn
              class="center"
              v-if="isMobile && certificates?.length > 3"
              @toggle="toggleOpenContainer('certificates')"
              :max="certificates.length"
              :step="3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import { createMetaInfo } from "@/utils/helpers";
import DocumentIcon from "../assets/icons/DocumentIcon";
import ArrowDownIcon from "@/assets/icons/supportedCars/ArrowDownIcon";
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';
import ExpandContainerBtn from '../components/UI-elements/ExpandContainerBtn.vue';

const store = useStore();
const { t } = useTranslation(['shared', 'page-adapter-documents']);
const adapterDocs = computed(() => store.state.pages?.adapter_documents);
const manuals = computed(() => store.state.pages?.adapter_documents?.children[0]?.items);
const certificates = computed(() => store.state.pages?.adapter_documents?.children[1]?.items);

const isOpenContainer = ref({
  manuals: false,
  certificates: false,
});

const isMobile = computed(() => store.state.device.isMobile);

const showContainer = (docsType, collection) => {
  if (isMobile.value && !isOpenContainer.value[docsType]) {
    return collection.slice(0, 3);
  }
  return collection;
};

const toggleOpenContainer = (target) => {
  isOpenContainer.value[target] = !isOpenContainer.value[target];
};

useHead(() => {
  return createMetaInfo({
    title: adapterDocs.value?.metaTitle,
    pageMeta: adapterDocs.value,
  });
});
</script>
