import i18next from "i18next";
import I18NextVue from 'i18next-vue';
// import LocizeBackend from 'i18next-locize-backend';
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import app from "../main";
import { store } from "../store/store";

i18next
  .use(LanguageDetector)
  .use(Backend);

export const storedLang = localStorage.i18nextLng;

export const i18nextPromise = i18next.init({
  debug: false,
  compatibilityJSON: "v3",
  fallbackLng: process.env.VUE_APP_LNG,
  load: "current",
  supportedLngs: ["en", "de", "es", "ja", "ru", "it", "fr"],
  backend: {
    loadPath: '/translations/{{lng}}/{{ns}}.json',
  },
  detection: {
    order: ["querystring", "path", "localStorage", "navigator"],
    lookupQuerystring: "lang",
    caches: ["localStorage"],
    checkWhitelist: true,
  },
}).then(() =>
  store.dispatch("language/initLanguage", i18next.language)
);

export default function (app) {
  app.use(I18NextVue, { i18next });
}
