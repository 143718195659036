<template>
  <svg
    width="52"
    height="30"
    viewBox="0 0 69 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3134 24.0375C21.1261 24.5965 20.5642 25.3419 19.815 25.9009C19.0659 26.4599 18.3167 27.0189 17.3802 27.0189C16.4438 27.0189 15.6946 26.4599 14.9454 25.9009C14.1963 25.3419 13.6344 24.4102 13.4471 24.0375L13.0725 23.1059H4.08249C3.70791 22.1742 3.14603 20.8698 2.95874 19.7518C2.77144 17.8885 3.14603 16.9568 3.8952 16.5841C5.58083 15.6524 7.64104 14.9071 10.0758 14.5344C11.7615 14.1618 13.4471 13.9754 14.9454 13.6028C15.5073 13.4164 16.2565 13.4164 16.8184 13.2301L16.2565 10.4351C15.6946 10.6214 15.1327 10.6214 14.5709 10.8077C12.8852 10.9941 11.1996 11.3667 9.32667 11.7394C6.89187 12.2984 4.45707 12.8574 2.39686 13.9754C-0.0379389 15.2798 -0.225231 18.0748 0.149353 20.1245C0.523938 22.1742 1.27311 24.2239 2.02228 25.3419L2.39686 26.0872H11.1996C11.5742 26.6462 12.1361 27.3916 12.8852 28.1369C14.009 29.0686 15.5073 30.0003 17.3802 30.0003C19.2532 30.0003 20.7515 29.0686 21.8752 28.1369C22.999 27.2052 23.7482 26.0872 24.1228 25.3419L21.3134 24.0375Z"
      fill="#D1D4D7"
    />
    <path
      d="M68.5108 8.75762C68.1362 8.19861 67.387 7.82594 67.0125 7.82594C66.4506 7.6396 65.3268 7.45327 64.3904 7.45327C62.3301 7.45327 60.0826 7.26693 58.397 6.33526C54.0893 3.9129 48.8451 1.3042 39.8551 1.3042C37.233 1.3042 34.4236 1.86321 31.9888 2.79488L33.4871 5.21724C35.5474 4.4719 37.7949 3.91289 39.8551 3.91289C48.0959 3.91289 52.7782 6.14892 56.8987 8.57128C59.1462 9.87563 62.1429 9.87563 64.2031 10.062H64.3904C64.9522 10.062 65.3268 10.062 65.7014 10.062C65.5141 10.8073 65.3268 11.5526 65.3268 12.4843C65.1395 13.7887 65.1395 15.2794 65.1395 16.5837C65.1395 17.329 65.1395 18.2607 65.1395 19.0061C65.1395 19.5651 65.1395 20.1241 65.1395 20.4967C65.1395 21.6148 65.1395 22.3601 65.1395 22.7328V22.9191C64.9522 22.9191 64.9522 22.9191 64.5776 22.9191C64.2031 22.9191 63.8285 22.9191 63.4539 22.9191H58.0224L57.6478 23.6644C56.7114 24.9688 55.5876 26.8322 53.1528 26.8322C50.9053 26.8322 49.407 24.9688 48.2832 23.6644L47.9086 23.1054H26.7446V25.9005H46.223C47.3468 27.3912 49.5943 29.6272 52.9655 29.6272C56.5241 29.6272 58.397 27.2048 59.5208 25.9005H63.4539C64.2031 25.9005 65.1395 25.9005 65.8887 25.7141C66.2633 25.5278 66.8252 25.3415 67.1997 24.7825C67.5743 24.4098 67.7616 23.8508 67.9489 23.2918C68.1362 22.7328 68.1362 21.6148 68.1362 20.4967C68.1362 19.9377 68.1362 19.5651 68.1362 19.0061C68.1362 18.2607 68.1362 17.5154 68.1362 16.77C68.1362 15.4657 68.1362 13.975 68.3235 12.857C68.5108 11.739 68.5108 10.8073 68.6981 10.621C68.6981 10.2483 69.0727 9.50296 68.5108 8.75762Z"
      fill="#D1D4D7"
    />
    <path
      d="M33.8621 8.19876C33.8621 3.72671 30.1162 0 25.6212 0C21.1262 0 17.3804 3.72671 17.3804 8.19876C17.3804 12.6708 21.1262 16.3975 25.6212 16.3975C27.3069 16.3975 28.9925 15.8385 30.3035 14.9068L35.735 20.3106L37.7952 18.2609L32.3637 12.8571C33.3002 11.5528 33.8621 9.87578 33.8621 8.19876ZM20.377 8.19876C20.377 5.21739 22.8118 2.98137 25.6212 2.98137C28.4306 2.98137 30.8654 5.40373 30.8654 8.19876C30.8654 10.9938 28.4306 13.4161 25.6212 13.4161C22.8118 13.4161 20.377 11.1801 20.377 8.19876Z"
      fill="url(#paint0_linear_23709_37044)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23709_37044"
        x1="14.819"
        y1="5.0359"
        x2="65.6403"
        y2="21.1176"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
    </defs>
  </svg>
</template>
