<template>
  <div
    id="supported-cars-service"
    class="supported-cars-service supported-cars-section"
  >
    <div class="container section-container">
      <div class="section-container__text">
        <span class="svg-container">
          <ServiceIcon />
        </span>
        <h2 class="title">{{ t('page-home_service-title') }}</h2>
        <h3 class="sub-title">{{ t('page-home_service-content') }}</h3>
      </div>
      <div class="supported-options-grid">
        <a
          v-for="(item, index) in [...data].slice(0, isMobile ? numberOfItems : data.length)"
          :key="item.userString + index"
          @click="
            openServiceModal({
              current: index,
              prev: index - 1,
              next: index + 1,
            })
          "
        >
          <span class="icon">
            <component :is="defineAsyncComponent(Icons.getIcon(item.userString))"></component
          ></span>
          <div class="title">{{ item.userString }}</div>
          <span class="arr">
            <ArrowRightIcon />
          </span>
        </a>
        <ExpandContainerBtn
          class="center"
          v-if="isMobile && data.length > 6"
          @toggle="(num) => numberOfItems = num"
          :max="data.length"
          :step="6"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import ServiceIcon from "@/assets/icons/supportedCars/ServiceIcon.vue";
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon.vue";
import Icons from "@/utils/icons";
import ExpandContainerBtn from "../UI-elements/ExpandContainerBtn";

const { t } = useTranslation(['page-home']);

const props = defineProps({
  data: Array,
  iFrameModeEnabled: {
    type: Boolean,
    default: false,
  },
  iframeParentProps: {
    type: Object
  },
});

const store = useStore();

const numberOfItems = ref(6);
const isMobile = computed(() => store.state.device.isMobile);

function openServiceModal(option) {
  const modalData = { ...option };

  const iframeTop = props.iframeParentProps?.iframe.top * -1;
  const viewportHeight = props.iframeParentProps?.viewport.height;
  const calculatedPosY = iframeTop + viewportHeight / 2;

  if (props.iFrameModeEnabled) {
    modalData.positionY = calculatedPosY;
  }

  store.dispatch('modals/openModal', { currentModal: "isServiceModalOpen", data: modalData });
}
</script>
