import Prices from "@/services/prices.service";

export default {
  namespaced: true,
  state: {
    prices: null,
  },
  mutations: {
    prices(state, payload) {
      state.prices = payload;
    },
  },
  actions: {
    getPrices({ commit }, countryCode) {
      return Prices.getPrices(countryCode).then(({ data }) => {
        commit("prices", data.countryByCountryCode.country);
      });
    },
  },
};
