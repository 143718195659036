<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onUnmounted, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from "vuex";
import Smartlook from 'smartlook-client';
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const route = useRoute();
const router = useRouter();

const modalsController = (keyCode) => {
  if (keyCode === 27) store.dispatch('resetModalStates');
  if (keyCode === 37) store.dispatch('modals/prevModal');
  if (keyCode === 39) store.dispatch('modals/nextModal');
};

onBeforeMount(async () => {
  await router.isReady();

  setTimeout(() => {
    if (route.name !== 'custom-vehicle-selector') {
      store.dispatch('init');
    }

    document.addEventListener('keyup', (e) => modalsController(e.keyCode));
  }, 100);
});

onMounted(async () => {
  await router.isReady();

  if (route.name == 'custom-vehicle-selector') {
    return;
  }

  Smartlook.init('8c903982d0581e97c22a897eab351e5e7391c43f', { region: 'eu' });
})

onUnmounted(() => {
  document.removeEventListener('keyup', modalsController);
});
</script>
