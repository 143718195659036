<template>
    <component
        :is="tagType"
        :class="buttonClass"
        :style="{ width: props.width }"
        v-bind="linkAttrs"
    >
        <span v-if="showText">{{ buttonText }}</span>
        <ArrowDownIcon
            v-if="showArrow"
            :class="{ rotate180: isArrowRotated }"
        />
    </component>
</template>

<script setup>
import { computed } from 'vue';
import { useTranslation } from 'i18next-vue';
import ArrowDownIcon from '@/assets/icons/supportedCars/ArrowDownIcon.vue';

const { t } = useTranslation(['shared']);

const props = defineProps({
    customText: {
        type: String,
        default: '',
    },
    customClass: {
        type: String,
        default: '',
    },
    isHighlighted: {
        type: Boolean,
        default: false,
    },
    isArrowRotated: {
        type: Boolean,
        default: false,
    },
    showText: {
        type: Boolean,
        default: true,
    },
    showArrow: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: 'min(318px, 100%)',
    },
    isLink: {
        default: false,
    },
    linkUrl: {
        type: String,
        default: '',
    },
    linkTarget: {
        type: String,
        default: '',
    },
});

const buttonText = computed(() => {
    return props.customText || t('shared_buy-now');
});

const buttonClass = computed(() => {
    return props.isHighlighted
        ? `btn-pricing-primary highlight ${props.customClass}`
        : `btn-pricing-primary ${props.customClass}`;
});

const tagType = computed(() => (props.isLink ? 'a' : 'button'));

const linkAttrs = computed(() => {
    if (props.isLink) {
        return {
            href: props.linkUrl,
            target: props.linkTarget,
            rel: props.linkTarget === '_blank' ? 'noopener noreferrer' : null,
        };
    }

    return {}
});
</script>

<style lang="scss" scoped>
button {
    border: 1px solid $color-neutral-deep;
    border-radius: 10px;
    padding: 18px 20px;
    background-color: transparent;
    color: $color-neutral-deep;
    font-size: 17px;
    font-weight: 500;

    svg {
        margin-left: 0px;
        path {
            fill: $color-neutral-deep;
        }
    }

    span + svg {
        margin-left: 10px;
    }

    &.highlight {
        border: 1px solid $color-primary-default;
        background-color: $color-primary-default;
        color: $color-white;

        svg {
            path {
                fill: $color-white;
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            border: 1px solid $color-blue-dark;
            background-color: $color-blue-dark;
            color: $color-white;

            svg {
                path {
                    fill: $color-white;
                }
            }
        }
    }

    &:active {
        border: 1px solid $color-blue-darker;
        background-color: $color-blue-darker;
        color: $color-white;

        svg {
            path {
                fill: $color-white;
            }
        }
    }
}
</style>
