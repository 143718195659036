<template>
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.25 18C11.25 11.637 11.25 8.45325 13.2278 6.47775C15.2033 4.5 18.387 4.5 24.75 4.5H29.25C35.613 4.5 38.7968 4.5 40.7723 6.47775C42.75 8.45325 42.75 11.637 42.75 18V36C42.75 42.363 42.75 45.5468 40.7723 47.5223C38.7968 49.5 35.613 49.5 29.25 49.5H24.75C18.387 49.5 15.2033 49.5 13.2278 47.5223C11.25 45.5468 11.25 42.363 11.25 36V18Z" stroke="url(#paint0_linear_2220_2715)" stroke-width="2.5"/>
    <path opacity="0.5" d="M11.25 9.17102C9.05625 9.38702 7.58025 9.87527 6.47775 10.9778C4.5 12.9533 4.5 16.137 4.5 22.5V31.5C4.5 37.863 4.5 41.0468 6.47775 43.0223C7.58025 44.1248 9.05625 44.613 11.25 44.829M42.75 9.17102C44.9438 9.38702 46.4198 9.87527 47.5223 10.9778C49.5 12.9533 49.5 16.137 49.5 22.5V31.5C49.5 37.863 49.5 41.0468 47.5223 43.0223C46.4198 44.1248 44.9438 44.613 42.75 44.829" stroke="url(#paint1_linear_2220_2715)" stroke-width="2.5"/>
    <path opacity="0.7" d="M20.25 29.25H33.75" stroke="url(#paint2_linear_2220_2715)" stroke-width="2.5" stroke-linecap="round"/>
    <path d="M20.25 20.25H33.75" stroke="url(#paint3_linear_2220_2715)" stroke-width="2.5" stroke-linecap="round"/>
    <path opacity="0.4" d="M20.25 38.25H27" stroke="url(#paint4_linear_2220_2715)" stroke-width="2.5" stroke-linecap="round"/>
    <defs>
    <linearGradient id="paint0_linear_2220_2715" x1="11.25" y1="22.0732" x2="50.6658" y2="23.8873" gradientUnits="userSpaceOnUse">
    <stop stop-color="#3CAE7C"/>
    <stop offset="0.182165" stop-color="#349E96"/>
    <stop offset="0.377875" stop-color="#2581C4"/>
    <stop offset="0.515933" stop-color="#2581C4"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2220_2715" x1="4.5" y1="23.0961" x2="60.5418" y2="27.746" gradientUnits="userSpaceOnUse">
    <stop stop-color="#3CAE7C"/>
    <stop offset="0.182165" stop-color="#349E96"/>
    <stop offset="0.377875" stop-color="#2581C4"/>
    <stop offset="0.515933" stop-color="#2581C4"/>
    </linearGradient>
    <linearGradient id="paint2_linear_2220_2715" x1="20.25" y1="29.6405" x2="29.7187" y2="38.0448" gradientUnits="userSpaceOnUse">
    <stop stop-color="#3CAE7C"/>
    <stop offset="0.182165" stop-color="#349E96"/>
    <stop offset="0.377875" stop-color="#2581C4"/>
    <stop offset="0.515933" stop-color="#2581C4"/>
    </linearGradient>
    <linearGradient id="paint3_linear_2220_2715" x1="20.25" y1="20.6405" x2="29.7187" y2="29.0448" gradientUnits="userSpaceOnUse">
    <stop stop-color="#3CAE7C"/>
    <stop offset="0.182165" stop-color="#349E96"/>
    <stop offset="0.377875" stop-color="#2581C4"/>
    <stop offset="0.515933" stop-color="#2581C4"/>
    </linearGradient>
    <linearGradient id="paint4_linear_2220_2715" x1="20.25" y1="38.6405" x2="27.3214" y2="41.7788" gradientUnits="userSpaceOnUse">
    <stop stop-color="#3CAE7C"/>
    <stop offset="0.182165" stop-color="#349E96"/>
    <stop offset="0.377875" stop-color="#2581C4"/>
    <stop offset="0.515933" stop-color="#2581C4"/>
    </linearGradient>
    </defs>
    </svg>
</template>