import Pages from '@/services/pages.service';
import { PAGE_TAGS } from '@/utils/constants';
import { storedLang } from "@/config/i18n.config";

export default {
    namespaced: true,
    state: {
        homepage: null,
        pricing: null,
        how_it_works: null,
        adapter: null,
        app: null,
        features: null,
        web_privacy: null,
        impressum: null,
        web_terms: null,
        supported_cars: null,
        app_privacy: null,
        app_terms: null,
        contact_us: null,
        tools: null,
        adapter_documents: null,
    },
    mutations: {
        homepage(state, payload) {
            state.homepage = payload;
        },
        pricing(state, payload) {
            state.pricing = payload;
        },
        how_it_works(state, payload) {
            state.how_it_works = payload;
        },
        adapter(state, payload) {
            state.adapter = payload;
        },
        app(state, payload) {
            state.app = payload;
        },
        features(state, payload) {
            state.features = payload;
        },
        impressum(state, payload) {
            state.impressum = payload;
        },
        web_privacy(state, payload) {
            state.web_privacy = payload;
        },
        web_terms(state, payload) {
            state.web_terms = payload;
        },
        supported_cars(state, payload) {
            state.supported_cars = payload;
        },
        app_privacy(state, payload) {
            state.app_privacy = payload;
        },
        app_terms(state, payload) {
            state.app_terms = payload;
        },
        contact_us(state, payload) {
            state.contact_us = payload;
        },
        tools(state, payload) {
            state.tools = payload;
        },
        adapter_documents(state, payload) {
            state.adapter_documents = payload;
        },
        reset(state) {
            state.homepage = null;
            state.pricing = null;
            state.how_it_works = null;
            state.adapter = null;
            state.app = null;
            state.features = null;
            state.supported_cars = null;
            state.contact_us = null;
            state.tools = null;
            state.adapter_documents = null;
        },
    },
    actions: {
        async getPages({ commit, rootState: { language } }) {
            try {
                const languageCode = language?.lang?.code || storedLang;
                const { data } = await Pages.getPages(languageCode);
                const pages = data.pages.pages;

                if (pages && pages.length) {
                    const pageMap = pages.reduce((map, page) => {
                        map[page.tag] = page;
                        return map;
                    }, {});

                    for (const tag of PAGE_TAGS) {
                        const page = pageMap[tag];
                        if (!page) {
                            return;
                        }
                        if (page.hasChildren) {
                            const childData =
                                await Pages.getChildrenPagesByPageId(
                                    page.id,
                                    language.lang.code
                                );
                            if (
                                childData.data.childrenPagesByPageId
                                    ?.childrenPages
                            ) {
                                page.children =
                                    childData.data.childrenPagesByPageId.childrenPages;
                            }
                        }

                        commit(tag, page);
                    }
                }
            } catch (error) {
                console.error('Error in getPages action:', error);
            }
        },
    },
};
