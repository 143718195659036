export default {
  currency: "$",
  pricesToken: "6lvZ3DHybTOvsdrJcO2GDEnXTolm2ubk",
  googleAppUrl:
    "https://play.google.com/store/apps/details?id=com.prizmos.carista&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
  appleAppUrl: "https://itunes.apple.com/app/id954363569",
  caristaFeedBackUrl: "https://help.caristaapp.com/hc/en-us/requests/new",
  caristaFaqUrl: "https://help.caristaapp.com/hc/",
  caristaBlogUrl: "https://blog.caristaapp.com/",
  saleEnabled: false,
  usSaleEnabled: true,
};
