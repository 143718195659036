<template>
  <section class="container">
    <div class="homepage__section homepage__app">
      <div class="content" :class="{ active: isActive }">
        <div class="left">
          <h2>
            {{ t("page-home_about-app-title-2") }}
            <span
              class="text-gradient"
              :class="{ 'samsung-bg-gradient': isSamsungBrowser }"
              >{{ t("page-home_about-app-title-3") }}</span
            >
          </h2>
          <div class="vw-partner">{{ t("shared_vw-group-partner") }}</div>
        </div>
        <div class="placeholder">
          <img
            class="show-on-mobile"
            :src="`/images/homePage/phone-carista-app-menu-customize-${langCode}-version.webp`"
            :alt="home?.children[2]?.images[0]?.imageMeta?.metaAltDescription"
            :title="home?.children[2]?.images[0]?.imageMeta?.metaTitle"
            loading="lazy"
          />
        </div>
        <div class="right">
          <p class="plain-text" v-html="t('page-home_about-app-content')"></p>
          <div class="content-bottom">
            <router-link
              :to="`/${langCode}/app`"
              class="btn-feedback-outlined lighter"
            >
              {{ t("page-home_more-about-the-app") }} <ArrowRightIcon />
            </router-link>
            <AvailableOn :text="t('page-home_available-on')"></AvailableOn>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import AvailableOn from '../../components/AvailableOn.vue';
import ArrowRightIcon from '../../assets/icons/supportedCars/ArrowRightIcon.vue';
import { detectSamsungBrowser } from "@/utils/helpers";

const { t } = useTranslation(['page-home', 'shared']);

const props = defineProps({
  active: Number,
});

const isSamsungBrowser = ref(detectSamsungBrowser());

const store = useStore();

const isActive = computed(() => props.active === 3);
const home = computed(() => store.state.pages?.homepage);
const langCode = computed(() => store.state.language.lang?.code);
</script>
