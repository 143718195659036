<template>
  <transition name="fade" mode="out-in">
    <div>
      <div class="page__loading" key="loading">
        <!-- <AppLoading/> -->
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const pingInterval = ref(null);

    return {
      pingInterval,
    };
  },
};
</script>
