import axios from "./http.service";
import { mapLangCodeToFullName } from '@/utils/helpers';

class Pages {
  getPages(lang) {
    const query = {
      query: `
      {
        pages(
          input: {
            page: 1,
            perPage: 999,
            language: ${mapLangCodeToFullName(lang)},
            environment: ${process.env.VUE_APP_WEB_CMS_ENV}
          }
        ) {
          pages {
            tag
            id
            canonicalHref
            environment
            hasChildren
            headings {
              h1
              h2
              h3
              h4
            }
            hreflangTag
            instructions
            legalContent
            metaDescription
            metaKeywords
            metaTitle
            images {
              id
              imageMeta {
                id
                language
                metaAltDescription
                metaTitle
              }
              locale
              originalName
              tag
              url
            }
            items {
              content {
                content
              }
              id
              title
              documents {
                id
                locale
                originalName
                tag
                url
              }
              images {
                id
                imageMeta {
                  id
                  language
                  metaAltDescription
                  metaTitle
                }
                locale
                originalName
                tag
                url
              }
            }
          }
          result {
            message
            status
          }
        }
      }`,
    };

    return axios
      .post(`${process.env.VUE_APP_WEB_CMS}`, query)
      .then((res) => res.data);
  }
  getChildrenPagesByPageId(pageId, lang) {
    const query = {
      query: `
      {
        childrenPagesByPageId(
          input: {
            pageId: ${pageId},
            language: ${mapLangCodeToFullName(lang)},
            environment: ${process.env.VUE_APP_WEB_CMS_ENV}
          }
        ) {
          childrenPages {
            tag
            id
            canonicalHref
            environment
            hreflangTag
            instructions
            legalContent
            metaDescription
            metaKeywords
            metaTitle
            headings {
              h1
              h2
              h3
              h4
            }
            images {
              tag
              id
              locale
              originalName
              url
              imageMeta {
                id
                language
                metaAltDescription
                metaTitle
              }
            }
            items {
              title
              id
              content {
                content
              }
              documents {
                id
                locale
                originalName
                tag
                url
              }
              images {
                tag
                id
                originalName
                locale
                url
                imageMeta {
                  id
                  language
                  metaAltDescription
                  metaTitle
                }
              }
            }
          }
          result {
            message
            status
          }
        }
      }`,
    };

    return axios
      .post(`${process.env.VUE_APP_WEB_CMS}`, query)
      .then((res) => res.data);
  }
}

export default new Pages();
