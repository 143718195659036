<template>
  <div
    id="supported-cars-diagnose"
    v-if="data.length > 0"
    class="supported-cars-diagnose supported-cars-section"
  >
    <div class="container section-container">
      <div class="section-container__text">
        <span class="svg-container diagnose">
          <DiagnoseIcon />
        </span>
        <h2>{{ t("page-home_diagnose-title") }}</h2>
        <h3>{{ t("page-home_diagnose-content") }}</h3>
      </div>
      <div class="supported-options-grid">
        <a
          class="cursor-auto"
          v-for="(item, index) in [...data].slice(
            0,
            isMobile ? numberOfItems : data.length
          )"
          :key="item.userString + index"
        >
          <span class="icon"
            ><component :is="defineAsyncComponent(Icons.getIcon(item.userString))"></component
          ></span>
          <div class="title">{{ item.userString }}</div>
        </a>
        <ExpandContainerBtn
          class="center"
          v-if="isMobile && data.length > 6"
          @toggle="(num) => numberOfItems = num"
          :max="data.length"
          :step="6"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import DiagnoseIcon from "@/assets/icons/supportedCars/DiagnoseIcon.vue";
import Icons from "@/utils/icons";
import ExpandContainerBtn from "../UI-elements/ExpandContainerBtn";

const { t } = useTranslation(['page-home']);

const props = defineProps({
  data: Array,
});

const store = useStore();

const numberOfItems = ref(6);
const isMobile = computed(() => store.state.device.isMobile);
</script>
