<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M15.991 7C11.023 7 7 11.032 7 16C7 20.968 11.023 25 15.991 25C20.968 25 25 20.968 25 16C25 11.032 20.968 7 15.991 7ZM22.228 12.4H19.573C19.285 11.275 18.871 10.195 18.331 9.196C19.987 9.763 21.364 10.915 22.228 12.4ZM16 8.836C16.747 9.916 17.332 11.113 17.719 12.4H14.281C14.668 11.113 15.253 9.916 16 8.836ZM9.034 17.8C8.89 17.224 8.8 16.621 8.8 16C8.8 15.379 8.89 14.776 9.034 14.2H12.076C12.004 14.794 11.95 15.388 11.95 16C11.95 16.612 12.004 17.206 12.076 17.8H9.034ZM9.772 19.6H12.427C12.715 20.725 13.129 21.805 13.669 22.804C12.013 22.237 10.636 21.094 9.772 19.6ZM12.427 12.4H9.772C10.636 10.906 12.013 9.763 13.669 9.196C13.129 10.195 12.715 11.275 12.427 12.4ZM16 23.164C15.253 22.084 14.668 20.887 14.281 19.6H17.719C17.332 20.887 16.747 22.084 16 23.164ZM18.106 17.8H13.894C13.813 17.206 13.75 16.612 13.75 16C13.75 15.388 13.813 14.785 13.894 14.2H18.106C18.187 14.785 18.25 15.388 18.25 16C18.25 16.612 18.187 17.206 18.106 17.8ZM18.331 22.804C18.871 21.805 19.285 20.725 19.573 19.6H22.228C21.364 21.085 19.987 22.237 18.331 22.804ZM19.924 17.8C19.996 17.206 20.05 16.612 20.05 16C20.05 15.388 19.996 14.794 19.924 14.2H22.966C23.11 14.776 23.2 15.379 23.2 16C23.2 16.621 23.11 17.224 22.966 17.8H19.924Z"
        fill="#9197A0"
      />
    </g>
  </svg>
</template>
