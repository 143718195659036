<template>
  <svg
    width="41"
    height="35"
    viewBox="0 0 41 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9548 4.58398H7.69434V7.03963H20.9548V4.58398Z"
      fill="#D1D4D7"
    />
    <path d="M20.9546 10.314H1.47314V12.7696H20.9546V10.314Z" fill="#D1D4D7" />
    <path
      d="M20.9548 4.58398H7.69434V7.03963H20.9548V4.58398Z"
      fill="#D1D4D7"
    />
    <path
      d="M13.5879 17.3533L13.4242 17.0259H5.23871L5.075 17.3533L0.491129 20.4638L0 20.7912V23.2468H5.075L6.54839 24.2291V27.1759L5.075 28.1581H0V30.6138L0.491129 30.9412L5.075 34.2154L5.23871 34.3791H13.2605L13.5879 34.2154L19.3177 29.7952H32.9056V27.5033H18.4992L18.1718 27.667L12.6056 31.9235H6.05726L4.09274 30.6138H5.72984L6.05726 30.4501L8.5129 28.9767L9.00403 28.4856V25.7025V22.9194L8.34919 22.592L6.05726 21.1186L5.72984 20.7912H4.09274L6.05726 19.4815H12.6056L18.1718 23.738L18.4992 24.0654H39.1266V21.6098H19.3177L13.5879 17.3533Z"
      fill="#D1D4D7"
    />
    <path
      d="M40.109 13.9153L40.6001 13.4242V11.1323H35.5251L34.0517 9.98629V7.20323L35.5251 6.22097H40.6001V3.76532L40.109 3.4379L35.5251 0.16371L35.3614 0H27.3396L27.0122 0.16371L21.4461 4.58387H21.1187V6.87581H22.1009L22.4283 6.7121L27.9945 2.45565H34.5428L36.5074 3.76532H34.8703L34.5428 3.92903L32.0872 5.40242L31.5961 5.89355V8.67661V11.4597L32.2509 11.7871L34.5428 13.2605L34.8703 13.4242H36.5074L34.5428 14.8976H27.9945L22.4283 10.4774L22.1009 10.3137H21.1187V12.7694H21.2824L27.0122 17.0258L27.3396 17.3532H35.3614L35.5251 17.0258L40.109 13.9153Z"
      fill="url(#paint0_linear_23709_37063)"
    />
    <path d="M20.9546 10.314H1.47314V12.7696H20.9546V10.314Z" fill="#D1D4D7" />
    <defs>
      <linearGradient
        id="paint0_linear_23709_37063"
        x1="20.1254"
        y1="8.6311"
        x2="62.1643"
        y2="8.6311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
    </defs>
  </svg>
</template>
