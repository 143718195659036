<template>
    <div class="sale-banner">
        <a
            :href="url"
            class="marquee"
            target="_blank"
            @click="facebookGoogleAnalitycs('ribbon_click')"
        >
            <div class="marquee-content enable-animation">
                <ShippingFastTruckIcon />
                <div>{{ title }}</div>
            </div>
            <div class="marquee-content enable-animation">
                <ShippingFastTruckIcon />
                <div>{{ title }}</div>
            </div>
        </a>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import { facebookGoogleAnalitycs } from '@/utils/helpers';
import ShippingFastTruckIcon from '@/assets/icons/ShippingFastTruckIcon.vue';

const { t } = useTranslation(['shared', 'translation']);

const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);
const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');

const title = computed(() => {
    const baseTitle = t('shared_free-shipping').toUpperCase();
    const desktopTitle = t('shared_free-shipping-on-carista-shop');

    if (isMobile.value) {
        return baseTitle;
    }

    const regEx = new RegExp(t('shared_free-shipping'), 'ig');

    return desktopTitle.replace(regEx, baseTitle);
});

const url = computed(() =>
    isInUK.value ? 'https://shop.uk.carista.com/' : 'https://shop.us.carista.com/'
);
</script>

<style lang="scss" scoped>
a {
    padding-left: 0px;
    padding-right: 0px;
}

.marquee {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end !important;
    overflow: hidden;
    --gap: 1rem;

    background: $color-primary-default;
}

.marquee-content {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    min-width: 100%;
    align-items: center;
    gap: var(--gap);
}

.marquee-content.enable-animation {
    -webkit-animation: scroll 20s linear infinite;
    animation: scroll 20s linear infinite;

    @media (max-width: $screen-xxl) {
        -webkit-animation: scroll 16s linear infinite;
        animation: scroll 16s linear infinite;
    }

    @media (max-width: $screen-lg) {
        -webkit-animation: scroll 12s linear infinite;
        animation: scroll 12s linear infinite;
    }

    @media (max-width: $screen-md) {
        -webkit-animation: scroll 8s linear infinite;
        animation: scroll 8s linear infinite;
    }

    @media (max-width: $screen-xs) {
        -webkit-animation: scroll 5s linear infinite;
        animation: scroll 5s linear infinite;
    }
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(100% + var(--gap)));
    }
}
</style>
