import axios from "axios";
import axiosRetry from "axios-retry";
import Events from "./events.service";
import app from "../main";

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    if (
      error.response &&
      [409, 401, 403, 404, 422].includes(error.response.status)
    ) {
      return false;
    }
    return false;
  },
});

axios.interceptors.request.use(
  (request) => {
    if (typeof request.hideLoading === "undefined" || !request.hideLoading) {
      // app.$Progress.start();
    }

    return request;
  },
  (error) => {
    // app.$Progress.finish();

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // app.$Progress.finish();

    // Show Api errors
    if (typeof response.data.ok !== "undefined") {
      if (!response.data.ok) {
        return Promise.reject(response.data.desc);
      }
    }

    return response;
  },
  (error) => {
    // app.$Progress.finish();

    if (
      typeof error.response === "undefined" ||
      typeof error.response.status === "undefined"
    ) {
      return Promise.reject(error);
    }

    // Server down
    if (error.response.status >= 500) {
      Events.emit("ServerUnavailable");
      return Promise.reject(error);
    }

    // Form validation errors
    if (error.response.status === 422) {
      return Promise.reject(error);
    }

    // Api errors that are returned with error codes
    return Promise.reject(error);
  }
);

export default axios;
