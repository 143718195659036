<template>
    <svg
      width="40"
      height="12"
      viewBox="0 0 40 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.16016"
        y="4.88174"
        width="36.8406"
        height="2.23629"
        rx="1.11815"
        fill="#d1d4d7"
      />
      <rect
        width="11.1682"
        height="2.23541"
        rx="1.1177"
        transform="matrix(0.865683 0.500593 -0.499408 0.866367 1.11621 4.47354)"
        fill="#d1d4d7"
      />
      <rect
        width="11.1682"
        height="2.23541"
        rx="1.1177"
        transform="matrix(0.865683 -0.500593 0.499408 0.866367 0 5.59073)"
        fill="#d1d4d7"
      />
    </svg>
</template>