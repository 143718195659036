<template>
    <div class="supported-sections-wrapper page">
        <!--  <BreadCrumb class="hide-on-mobile" /> -->
        <div
            class="get-started-mobile-container show-on-mobile"
            :style="[iFrameModeEnabled ? 'top: 0px' : '']"
        >
            <GetStarted
                :to="
                    pathToPricing(
                        langCode,
                        dynamicVehiclesData.selector?.make?.id,
                        adapterMinimalRequirement
                    )
                "
                :iFrameModeEnabled
                :distributorLink
                :title="t('page-supported-cars_see-pricing')"
                fbEventParam="up"
            />
        </div>
        <div class="supported-sections">
            <div class="container">
                <div class="supported-sections__text-wrapper">
                    <h1>
                        {{ dynamicVehiclesData.selector?.make?.title }}
                        {{ dynamicVehiclesData.selector?.model?.title }}
                        {{ dynamicVehiclesData.selector?.generation?.title }}
                    </h1>
                    <div class="result_label">
                        <button @click="handleResetClick">
                            <ResetIcon />{{ t('page-supported-cars_change') }}
                        </button>
                        <p>
                            {{ subTitle }}
                        </p>
                        <button
                            class="hide-on-mobile"
                            @click="handlePricingClick"
                        >
                            {{ t('page-supported-cars_pricing') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="vw-banner" v-if="showVWBanner">
            {{ t('shared_vw-group-partner') }}
        </div>
        <div
            class="bmw-banner"
            v-if="dynamicVehiclesData.features?.adapter === 'carista'"
        >
            <div>
                {{ t('shared_required-for-bmw-f') }}
            </div>
        </div>
        <SupportedCarsCustomize
            :data="dynamicVehiclesData.features?.customize || []"
            :iFrameModeEnabled
            :iframeParentProps
        />
        <SupportedCarsDiagnose
            :data="dynamicVehiclesData.features?.diagnose || []"
        />
        <SupportedCarsService
            :data="dynamicVehiclesData.features?.service || []"
            :iFrameModeEnabled
            :iframeParentProps
        />
        <SupportedCarsLiveData
            :data="dynamicVehiclesData.features?.liveData || []"
            :iFrameModeEnabled
            :iframeParentProps
        />
        <Embark
            v-if="!iFrameModeEnabled"
            :title="[
                supported_cars?.headings?.h4[0],
                supported_cars?.headings?.h4[1],
                supported_cars?.headings?.h4[2],
            ]"
            :btnTitle="t('page-supported-cars_see-pricing')"
            :btnSubtitle="t('page-supported-cars_get-carista-app-and-adapter')"
            :to="
                pathToPricing(
                    langCode,
                    dynamicVehiclesData.selector?.make?.id,
                    adapterMinimalRequirement
                )
            "
            fbEventParam="down"
        />
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useTranslation } from 'i18next-vue';
import {
    facebookGoogleAnalitycs,
    pathToPricing,
    isValidUrl,
} from '@/utils/helpers';
import SupportedCarsCustomize from '@/components/we-support-your-car/SupportedCarsCustomize.vue';
import SupportedCarsDiagnose from '@/components/we-support-your-car/SupportedCarsDiagnose.vue';
import SupportedCarsLiveData from '@/components/we-support-your-car/SupportedCarsLiveData.vue';
import SupportedCarsService from '@/components/we-support-your-car/SupportedCarsService.vue';
import Embark from '@/components/Embark';
import ResetIcon from '@/assets/icons/ResetIcon';
import GetStarted from '@/components/GetStarted';
import { VAG_MAKES } from '../utils/constants';
import { debounce } from 'lodash';

const props = defineProps({
    iFrameModeEnabled: {
        type: Boolean,
        default: false,
    },
});

const iframeParentProps = ref(null);

const store = useStore();
const router = useRouter();
const { t } = useTranslation(['page-supported-cars', 'shared']);
const dynamicVehiclesData = computed(
    () => store.state.vehiclebrands.dynamicVehiclesData
);
const langCode = computed(() => store.state.language.lang?.code);
const showVWBanner = computed(() =>
    VAG_MAKES.includes(dynamicVehiclesData.value?.selector?.make?.title)
);
const supported_cars = computed(() => store.state.pages.supported_cars);
const adapterMinimalRequirement = computed(() =>
    dynamicVehiclesData.value?.features?.adapter === 'evo' ? true : false
);

const distributor = computed(() => store.state.distributorData);

const distributorLink = computed(() => {
    if (adapterMinimalRequirement.value) {
        return distributor.value?.linkEvo || distributor.value?.linkEvoObd;
    }

    return distributor.value?.linkEvoObd || distributor.value?.linkEvo;
});

const subTitle = computed(() => {
    if (props.iFrameModeEnabled && adapterMinimalRequirement.value) {
        return t('page-supported-cars_available-features-with-evo-are-below');
    }

    return t('page-supported-cars_available-features');
});

const handleResetClick = () => {
    if (props.iFrameModeEnabled) {
        store.dispatch('vehiclebrands/getDynamicVehiclesData', {});
        return;
    }

    router.push(`/${langCode.value}/supported-cars`);
};

const handlePricingClick = () => {
    if (props.iFrameModeEnabled) {
        if (isValidUrl(distributorLink.value)) {
            window.top.location.href = distributorLink.value;
        }
        return;
    }

    router.push(
        pathToPricing(
            langCode.value,
            dynamicVehiclesData.value?.selector?.make?.id,
            adapterMinimalRequirement.value
        )
    );
};

const getData = () => {
    const data = {
        make: router.currentRoute.value.params.make,
        model: router.currentRoute.value.params.model,
        generation: router.currentRoute.value.params.generation,
    };

    facebookGoogleAnalitycs('vehicle_check', {
        brand: data.make, // must be BRAND, not MAKE, because it is BRAND in GTM
        model: data.model,
        generation: data.generation,
    });

    store.dispatch('vehiclebrands/getDynamicVehiclesData', data);
};

const callbackFunc = debounce((parentProps) => {
  iframeParentProps.value = parentProps;
}, 100);

onMounted(() => {
    document.body.classList.remove('overflow-hidden');

    if (props.iFrameModeEnabled) {
        window.parentIframe?.getParentProps(callbackFunc);
    }

    if (
        router.currentRoute.value.params.make &&
        router.currentRoute.value.params.model &&
        router.currentRoute.value.params.generation
    ) {
        getData();
    } else {
        props.iFrameModeEnabled ?? router.push('/supported-cars');
    }
});
</script>
