import { getCurrentYear } from './helpers';

export const LANGUAGES = ['en', 'de', 'es', 'ja', 'ru', 'it', 'fr'];

export const COPYRIGHT = `© ${getCurrentYear()} Carista.`;

export const SOCIALS_LINK_FACEBOOK = 'https://www.facebook.com/caristaapp';
export const SOCIALS_LINK_LINKEDIN =
    'https://www.linkedin.com/company/carista/';
export const SOCIALS_LINK_INSTAGRAM = 'https://www.instagram.com/caristaapp/';
export const SOCIALS_LINK_TIKTOK = 'https://www.tiktok.com/@caristaapp';
export const SOCIALS_LINK_YOUTUBE =
    'https://www.youtube.com/channel/UCeiMZzmLpzhH1qcZ-tezXmA';

export const VAG_MAKES = ['Audi', 'Volkswagen', 'Seat', 'Skoda', 'Cupra'];
export const ALL_MAKES = [
    'audi',
    'bmw',
    'cupra',
    'infiniti',
    'lexus',
    'mini',
    'nissan',
    'scion',
    'seat',
    'skoda',
    'toyota',
    'vw',
    'ford',
    'lincoln',
    'land-rover',
];

export const OPEN_GRAPH_ADAPTER_IMG_URL = `${process.env.VUE_APP_API}/assets/images/Carista-EVO-OBD2-Bluetooth-Scanner-nobg.png`;

export const ADAPTER_TYPE = Object.freeze({
    OBD: 'obd',
    EVO: 'evo',
});

export const EVO_PRICING_PAGE_ROUTE = 'pricing-evo';

export const APP_PAGE_ROUTE = 'app';

export const FAQ_CATEGORY_ID = {
    PRICING_PAGE: 1,
    HOME_PAGE: 2,
    CONTACT_US_PAGE: 3,
    ADAPTERS_PAGE: 4,
    APP_PAGE: 5,
};

export const TESTIMONIALS_CATEGORY_ID = Object.freeze({
    APP_PAGE: 1,
    ADAPTERS_PAGE: 3,
    HOME_PAGE: 4,
});

export const STORE_LINKS = Object.freeze({
    APP_STORE: 'https://apps.apple.com/us/app/carista-obd2/id954363569',
    GOOGLE_PLAY:
        'https://play.google.com/store/apps/details?id=com.prizmos.carista&hl=en&gl=BG',
    AMAZON: 'https://www.amazon.co.uk/dp/B0CM9W4MCW',
});

export const PAGE_TAGS = Object.freeze([
    'homepage',
    'pricing',
    'how_it_works',
    'adapter',
    'app',
    'features',
    'impressum',
    'web_privacy',
    'web_terms',
    'supported_cars',
    'app_privacy',
    'app_terms',
    'contact_us',
    'tools',
    'adapter_documents',
]);
