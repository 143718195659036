import Vue from 'vue';
import throttle from 'lodash.throttle';
import Profile from '../services/profile.service';
import Errors from '../utils/errors';
import app from '../main';

export default {
  namespaced: true,
  state: {
    profile: null,
  },
  mutations: {
    profile(state, payload) {
      state.profile = payload;
    },
  },
  actions: {
    getProfile: throttle(
      ({ commit }) => Profile.get()
        .then((profile) => {
          commit('profile', profile.data);
          return profile;
        }),
      500, { trailing: false },
    ),
    updateProfile({ commit, state }) {
      if (state.profile.password || state.profile.password_confirmation) {
        commit('validateNewPassword');
      }
      commit('setStates');
      const payload = {
        ...state.profile,
        isProfileEdited: state.isProfileEdited,
      };
      return Profile.updateProfile(payload)
        .then((res) => {
          return res;
        });
    },
    setPreferredLanguage({ state }, lang) {
      if (!state.profile) return false;
      const payload = {
        preferred_language: lang,
      };
      return Profile.setPreferredLanguage(payload);
    },
    deactivateProfile({ state, dispatch }) {
      return Profile.deactivateProfile()
        .then((res) => {
          app.$store.dispatch('auth/logout', { root: true });
          return res;
        });
    },
    deleteProfile({ state, dispatch }) {
      return Profile.deleteProfile()
        .then((res) => {
          app.$store.dispatch('auth/logout', { root: true });
          return res;
        });
    },
    resetState({ commit }) {
      commit('profile', null);
    },
  },
  getters: {
    isRequiredToVerify(state) {
      return state.profile && state.profile.is_verify_required;
    },
    isProfileIncomplete(state) {
      return state.profile && state.profile.status === 'incomplete';
    },
    isProfilePending(state) {
      return state.profile && state.profile.status === 'pending';
    },
    isProfileComplete(state) {
      return state.profile && state.profile.status === 'complete' && !state.profile.is_verify_required;
    },
    isProfileNoService(state) {
      return state.profile && state.profile.status === 'no_service';
    },
  },
};
