<template>
  <svg
    width="30"
    height="44"
    viewBox="0 0 30 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 0H1.26308H24.5521H25.8152V1.27717V7.8708H23.289V2.55434H16.168V3.98959H10.5787V2.55434H2.52617V41.4457H23.289V33.3034H25.8152V42.7228V44H24.5521H1.26308H0V42.7228V1.27717V0ZM17.8731 25.2877V24.5216L20.3993 24.5216V28.6086H17.8731V27.8421H13.8939V25.2877H17.8731ZM30 27.8421H21.7154V25.2877H30V27.8421ZM21.6624 18.1358V18.9022H13.8939V21.4566H21.6624V22.2227H24.1886V18.1358H21.6624ZM25.5047 21.4566H30V18.9022H25.5047V21.4566ZM17.8731 12.5161V11.7499H20.3993V15.8369H17.8731V15.0704H13.8939V12.5161H17.8731ZM30 15.0704H21.7154V12.5161H30V15.0704Z"
      :fill="`url(#${id})`"
    />
    <defs>
      <linearGradient
        :id="id"
        x1="-1.5295"
        y1="21.8846"
        x2="63.2072"
        y2="21.8846"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>

export default{
  data(){
    return {
      id:Math.random(1000) + 'gradient-id'
    }
  }
}

</script>
