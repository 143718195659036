<template>
  <Transition name="fade-slide">
    <div
      v-if="isLiveDataModalOpen && liveData?.length > 0"
      class="modal-wrapper"
      @click.self="$store.dispatch('resetModalStates')"
    >
      <div class="customize-modal" :style="iframeModalStyle">
        <span class="customize-modal__type live-data">{{ t('shared_live-data') }}</span>
        <p class="customize-modal__title">
          <component :is="defineAsyncComponent(GetIcon.getIcon(isLiveDataModalOpen.userString))" />
          {{ getCurrent().userString}}
          <button
            class="customize-modal__title__close-btn"
            @click="closeLiveDataModal"
          >
            <XCloseIcon />
          </button>
        </p>
        <ul class="customize-modal__list">
          <li>
            <p class="big">
              {{ getCurrent().description }}
            </p>
          </li>
        </ul>
        <div class="customize-modal__section-section">
          <button
            v-if="getNext()"
            @click="pushNext"
            class="customize-modal__section-section__next-btn"
          >
            <span>{{ getNext().userString }}</span>
            <ArrowRightIcon />
          </button>
          <button
            v-if="getPrev()"
            @click="pushPrev"
            class="customize-modal__section-section__prev-btn"
          >
            <ArrowRightIcon />
            <span>{{ getPrev().userString }}</span>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon.vue";
import XCloseIcon from "@/assets/icons/XCloseIcon.vue";
import GetIcon from "@/utils/icons";

const store = useStore();
const { t } = useTranslation(['shared']);

const isLiveDataModalOpen = computed(() => store.state.modals.isLiveDataModalOpen);
const liveData = computed(() => store.state.vehiclebrands.dynamicVehiclesData?.features?.liveData || []);
const iframePositionY = computed(() => isLiveDataModalOpen.value?.positionY || null);

const iframeModalStyle = computed(() => {
  const positionCalculated = window.innerWidth < 1030 ? iframePositionY.value + 640 : iframePositionY.value + 240;

  if (iframePositionY.value) {
    return {
      position: 'absolute',
      top: positionCalculated + 'px',
    };
  }

  return {};
});

function closeLiveDataModal() {
  store.dispatch('resetModalStates');
}

function getCurrent() {
  return liveData.value[isLiveDataModalOpen.value.current];
}

function getPrev() {
  return liveData.value[isLiveDataModalOpen.value.prev];
}

function getNext() {
  return liveData.value[isLiveDataModalOpen.value.next];
}

function pushNext() {
  store.dispatch('modals/nextModal');
}

function pushPrev() {
  store.dispatch('modals/prevModal');
}
</script>
