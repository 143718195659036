<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 3.80469C4.5 3.80469 3.30469 5.02344 3.30469 6.5C3.30469 8 4.5 9.19531 6 9.19531C7.47656 9.19531 8.69531 8 8.69531 6.5C8.69531 5.02344 7.47656 3.80469 6 3.80469ZM6 8.25781C5.03906 8.25781 4.24219 7.48438 4.24219 6.5C4.24219 5.53906 5.01562 4.76562 6 4.76562C6.96094 4.76562 7.73438 5.53906 7.73438 6.5C7.73438 7.48438 6.96094 8.25781 6 8.25781ZM9.42188 3.71094C9.42188 4.0625 9.14062 4.34375 8.78906 4.34375C8.4375 4.34375 8.15625 4.0625 8.15625 3.71094C8.15625 3.35938 8.4375 3.07812 8.78906 3.07812C9.14062 3.07812 9.42188 3.35938 9.42188 3.71094ZM11.2031 4.34375C11.1562 3.5 10.9688 2.75 10.3594 2.14062C9.75 1.53125 9 1.34375 8.15625 1.29688C7.28906 1.25 4.6875 1.25 3.82031 1.29688C2.97656 1.34375 2.25 1.53125 1.61719 2.14062C1.00781 2.75 0.820312 3.5 0.773438 4.34375C0.726562 5.21094 0.726562 7.8125 0.773438 8.67969C0.820312 9.52344 1.00781 10.25 1.61719 10.8828C2.25 11.4922 2.97656 11.6797 3.82031 11.7266C4.6875 11.7734 7.28906 11.7734 8.15625 11.7266C9 11.6797 9.75 11.4922 10.3594 10.8828C10.9688 10.25 11.1562 9.52344 11.2031 8.67969C11.25 7.8125 11.25 5.21094 11.2031 4.34375ZM10.0781 9.59375C9.91406 10.0625 9.53906 10.4141 9.09375 10.6016C8.39062 10.8828 6.75 10.8125 6 10.8125C5.22656 10.8125 3.58594 10.8828 2.90625 10.6016C2.4375 10.4141 2.08594 10.0625 1.89844 9.59375C1.61719 8.91406 1.6875 7.27344 1.6875 6.5C1.6875 5.75 1.61719 4.10938 1.89844 3.40625C2.08594 2.96094 2.4375 2.60938 2.90625 2.42188C3.58594 2.14062 5.22656 2.21094 6 2.21094C6.75 2.21094 8.39062 2.14062 9.09375 2.42188C9.53906 2.58594 9.89062 2.96094 10.0781 3.40625C10.3594 4.10938 10.2891 5.75 10.2891 6.5C10.2891 7.27344 10.3594 8.91406 10.0781 9.59375Z"
      fill="white"
    />
  </svg>
</template>
