<template>
    <Tooltip>
        <template #base="{ toggle }">
            <button @click="toggle">
                {{ t("page-blog_share") }} <ShareArrowIcon />
            </button>
        </template>
        <template #tooltip="{ close }">
            <ul>
                <li @click="copyLink(article?.slug, close)" :class="{ selected: selectedCopy }">
                    <span>
                        <CopyIcon /> {{ t("page-blog_copy-link") }}
                    </span>
                </li>
                <li @click="share('facebook', article?.slug, close)" :class="{ selected: selectedFb }">
                    <span>
                        <FacebookIcon class="fill-dark"/> {{ t("page-blog_share-facebook") }}
                    </span>
                </li>
                <li @click="share('linkedin', article?.slug, close)" :class="{ selected: selectedLi }">
                    <span>
                        <LinkedInIcon class="fill-dark"/> {{ t("page-blog_share-linkedin") }}
                    </span>
                </li>
            </ul>
        </template>
    </Tooltip>
</template>

<script setup>
import { ref } from 'vue';
import ShareArrowIcon from "@/assets/icons/ShareArrowIcon.vue";
import CopyIcon from "@/assets/icons/CopyIcon.vue";
import FacebookIcon from "@/assets/icons/FacebookIcon.vue";
import LinkedInIcon from "@/assets/icons/LinkedInIcon.vue";
import { copyBlogArticleLink } from "../../utils/helpers";
import { useTranslation } from "i18next-vue";
import Tooltip from '../Tooltip.vue';

const { t } = useTranslation(['page-blog']);

const props = defineProps({
  article: Object
});

const selectedCopy = ref(false);
const selectedFb = ref(false);
const selectedLi = ref(false);

function copyLink(slug, close) {
    selectedCopy.value = true;
    copyBlogArticleLink(slug);
    setTimeout(() => {
        close();
        selectedCopy.value = false;
    }, 200);
}

function share(socialMedia, article, close) {
    if (socialMedia === "facebook") selectedFb.value = true;
    if (socialMedia === "linkedin") selectedLi.value = true;
    const baseUrl = process.env.VUE_APP_APP;

    setTimeout(() => {
        close();

        if (socialMedia === "facebook") {
            selectedFb.value = false;
            window.open(
                `https://www.facebook.com/share.php?u=${baseUrl}/blog/${article}`,
                '_blank',
                'noreferrer'
            );
        }

        if (socialMedia === "linkedin") {
            selectedLi.value = false;
            window.open(
                `https://www.linkedin.com/shareArticle?mini=true&url=${baseUrl}/blog/${article}`,
                '_blank',
                'noreferrer'
            );
        }
    }, 200);
}
</script>
