<template>
  <div
    v-if="faqList?.length > 0"
    class="accordion"
    :class="{ 'dark-background': isStatic }"
  >
    <div class="container">
      <div class="content">
        <div class="accordion__heading">
          <h4 class="title" :class="{ active: isActive }">
            <span >{{ title }}</span>
          </h4>
          <a
            v-if="showButtons"
            :href="$appConfig.caristaFaqUrl"
            target="_blank"
            class="btn-feedback-outlined active hide-on-mobile"
          >
            {{ t("shared_view-all-faq") }} <ArrowRightIcon />
          </a>
        </div>
        <ul class="accordion__list" v-for="(item, i) in faqList" :key="i">
          <li class="item" :class="{ activeItem: item.active }">
            <span class="order">0{{ i + 1 }}</span>
            <span
              @click="showItem(item)"
              class="title-item"
              :class="{ active: isActive }"
            >
              {{ item.question }}
            </span>
            <span :class="`btn btn-` + item.id">
              <svg
                @click="showItem(item)"
                width="30"
                height="30"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="15" width="33" height="2" rx="1" fill="#787F8C" />
                <rect
                  class="m"
                  x="15"
                  y="33"
                  width="33"
                  height="2"
                  rx="1"
                  fill="#787F8C"
                />
              </svg>
            </span>
            <p
              class="description"
              :class="`item` + item.id"
              v-html="item.answer"
            ></p>
          </li>
        </ul>
        <a
        v-if="showButtons"
          :href="$appConfig.caristaFaqUrl"
          target="_blank"
          class="btn-feedback-outlined lighter only-mobile"
          :class="{ active: isActive }"
        >
          {{ t("shared_view-all-faq") }} <ArrowRightIcon />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArrowRightIcon from "../assets/icons/supportedCars/ArrowRightIcon.vue";
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";

const { t, i18next } = useTranslation(['shared']);

const props = defineProps({
  active: Number,
  static: Boolean,
  faqList: Array,
  title: String,
  showButtons: {
    type: Boolean,
    default: true,
  }
});

const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);

const isActive = computed(() => props.active === 7);
const isStatic = computed(() => props.static);
const isLanguageEnglish = computed(() => i18next.language === 'en');

const title = computed(() => {
  if (!isMobile.value) {
    return props.title;
  }

  if (!isLanguageEnglish.value) {
    return t('shared_frequently-asked-questions');
  }

  return t('shared_faq');
});

const faqList = ref([]);

watch(
  () => props.faqList,
  (newList) => {
    if (!newList) {
      return;
    }
    faqList.value = newList.map((item) => ({ ...item }));
  },
  {
    immediate: true,
  }
);

const showItem = (activeItem) => {
  faqList.value.forEach((item) => {
    if (item.id === activeItem.id) {
      item.active = !item.active;
    }
  });
}
</script>

<style lang="scss">
  .item {
    align-items: center;
  }
</style>
