import GeoIP from "@/services/geoIP.service";

export default {
  namespaced: true,
  state: {
    geoIP: null,
  },
  mutations: {
    geoIP(state, payload) {
      state.geoIP = payload;
    },
  },
  actions: {
    getIP({ commit }) {
      return GeoIP.getIP().then(({ data }) => {
        commit("geoIP", data?.geoIpLocation);
      });
    },
  },
};
