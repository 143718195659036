<template>
  <svg
    width="48"
    height="38"
    viewBox="0 0 48 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.93525 24.3453H10.0144V27.1079H0V0H2.93525V24.3453ZM30.5612 36.9496H24.8633L32.9784 30.2158L34.8777 32.2878L30.5612 36.9496ZM28.6619 19.1655C17.9568 19.1655 9.32374 27.4532 9.32374 37.8129V37.9856H12.259V37.8129C12.259 29.5252 18.8201 22.6187 27.2806 21.9281V24H30.0432V21.9281C33.6691 22.2734 36.9496 23.6547 39.5396 25.8993L38.1583 27.4532L40.2302 29.3525L41.6115 27.7986C43.8561 30.5611 45.0647 33.8417 45.0647 37.6403V37.9856H48V37.8129C48 27.4532 39.1942 19.1655 28.6619 19.1655ZM14.8489 4.48921H17.7842V17.0935H14.8489V4.48921ZM24.6906 0.345324H21.7554V17.0935H24.6906V0.345324ZM7.76979 8.1151H10.705V17.0935H7.76979V8.1151Z"
      :fill="`url(#${id})`"
    />
    <defs>
      <linearGradient
        :id="id"
        x1="-2.44719"
        y1="18.8932"
        x2="101.132"
        y2="18.8932"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>

export default{
  data(){
    return {
      id:Math.random(1000) + 'gradient-id'
    }
  }
}

</script>