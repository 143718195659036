<template>
  <Tooltip>
    <template #base="{ toggle }">
      <span @click="toggle" class="tooltip-base">
        <TooltipIcon v-if="!isMobile" />
        {{ isMobile ? tooltipBase : null }}
      </span>
    </template>
    <template #tooltip="{ close }">
      <div class="text-container">{{ tooltipText }}</div>
    </template>
  </Tooltip>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import TooltipIcon from "@/assets/icons/TooltipIcon";
import Tooltip from '../Tooltip.vue';

const props = defineProps({
  tooltipBase: String,
  tooltipText: String
});

const store = useStore();

const isMobile = computed(() => store.state.device.isMobile);
</script>
