export const GET_DISTRIBUTOR_BY_UUID = () => {
    return `
query DistributorByUuid($uuid: String!) {
    distributorByUuid(input: { uuid: $uuid }) {
      	distributor {
        	id
        	uuid
        	name
        	language
        	linkEvo
        	linkEvoObd
    	}
      		result {
        		status
        		message
      		}
    	}
  	}
`;
};
