<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10312_8749)">
    <path d="M12.9641 11.723C7.29694 14.4201 3.77983 12.1635 1.52841 10.7929C1.3891 10.7065 1.15231 10.8131 1.35775 11.049C2.10781 11.9585 4.56591 14.1506 7.77445 14.1506C10.9852 14.1506 12.8953 12.3986 13.1342 12.093C13.3716 11.79 13.2039 11.6228 12.9641 11.723H12.9641ZM14.5557 10.844C14.4035 10.6458 13.6303 10.6089 13.1437 10.6686C12.6563 10.7267 11.9248 11.0245 11.9884 11.2034C12.021 11.2704 12.0877 11.2403 12.4225 11.2102C12.7582 11.1767 13.6987 11.058 13.8946 11.3142C14.0915 11.5722 13.5947 12.801 13.5039 12.9992C13.4163 13.1973 13.5374 13.2484 13.7021 13.1164C13.8645 12.9845 14.1586 12.6428 14.3559 12.1593C14.5518 11.6731 14.6714 10.9949 14.5557 10.844Z" fill="#FF9900"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.15319 6.21941C9.15319 6.92709 9.17107 7.51726 8.81338 8.14572C8.52468 8.65673 8.06736 8.97096 7.55641 8.97096C6.85891 8.97096 6.4527 8.43954 6.4527 7.65523C6.4527 6.10697 7.83994 5.82597 9.15319 5.82597V6.21941ZM10.985 10.647C10.8649 10.7542 10.6912 10.7619 10.5558 10.6904C9.95285 10.1896 9.84552 9.95716 9.51341 9.47938C8.51703 10.4962 7.81188 10.8002 6.51915 10.8002C4.99135 10.8002 3.80078 9.85748 3.80078 7.96946C3.80078 6.49535 4.60049 5.49127 5.73732 5.00078C6.72352 4.56641 8.10059 4.48978 9.15319 4.36974V4.13467C9.15319 3.70288 9.18636 3.19193 8.93347 2.81895C8.71117 2.48425 8.28708 2.34628 7.91404 2.34628C7.22171 2.34628 6.60343 2.70139 6.4527 3.43717C6.422 3.60072 6.30196 3.76169 6.13847 3.76934L4.3756 3.58031C4.22746 3.54703 4.06396 3.427 4.10483 3.19958C4.51104 1.06378 6.43988 0.419922 8.16693 0.419922C9.05092 0.419922 10.2057 0.654988 10.9032 1.32438C11.7872 2.14956 11.7028 3.25068 11.7028 4.4489V7.27966C11.7028 8.13043 12.0554 8.50341 12.3875 8.96331C12.505 9.12681 12.5306 9.32358 12.3824 9.44615C12.012 9.75527 11.3528 10.3301 10.99 10.6521L10.9849 10.647" fill="black"/>
    <path d="M12.9641 11.723C7.29694 14.4201 3.77983 12.1635 1.52841 10.7929C1.3891 10.7065 1.15231 10.8131 1.35775 11.049C2.10781 11.9585 4.56591 14.1506 7.77445 14.1506C10.9852 14.1506 12.8953 12.3986 13.1342 12.093C13.3716 11.79 13.2039 11.6228 12.9641 11.723H12.9641ZM14.5557 10.844C14.4035 10.6458 13.6303 10.6089 13.1437 10.6686C12.6563 10.7267 11.9248 11.0245 11.9884 11.2034C12.021 11.2704 12.0877 11.2403 12.4225 11.2102C12.7582 11.1767 13.6987 11.058 13.8946 11.3142C14.0915 11.5722 13.5947 12.801 13.5039 12.9992C13.4163 13.1973 13.5374 13.2484 13.7021 13.1164C13.8645 12.9845 14.1586 12.6428 14.3559 12.1593C14.5518 11.6731 14.6714 10.9949 14.5557 10.844Z" fill="#FF9900"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.15319 6.21941C9.15319 6.92709 9.17107 7.51726 8.81338 8.14572C8.52468 8.65673 8.06736 8.97096 7.55641 8.97096C6.85891 8.97096 6.4527 8.43954 6.4527 7.65523C6.4527 6.10697 7.83994 5.82597 9.15319 5.82597V6.21941ZM10.985 10.647C10.8649 10.7542 10.6912 10.7619 10.5558 10.6904C9.95285 10.1896 9.84552 9.95716 9.51341 9.47938C8.51703 10.4962 7.81188 10.8002 6.51915 10.8002C4.99135 10.8002 3.80078 9.85748 3.80078 7.96946C3.80078 6.49535 4.60049 5.49127 5.73732 5.00078C6.72352 4.56641 8.10059 4.48978 9.15319 4.36974V4.13467C9.15319 3.70288 9.18636 3.19193 8.93347 2.81895C8.71117 2.48425 8.28708 2.34628 7.91404 2.34628C7.22171 2.34628 6.60343 2.70139 6.4527 3.43717C6.422 3.60072 6.30196 3.76169 6.13847 3.76934L4.3756 3.58031C4.22746 3.54703 4.06396 3.427 4.10483 3.19958C4.51104 1.06378 6.43988 0.419922 8.16693 0.419922C9.05092 0.419922 10.2057 0.654988 10.9032 1.32438C11.7872 2.14956 11.7028 3.25068 11.7028 4.4489V7.27966C11.7028 8.13043 12.0554 8.50341 12.3875 8.96331C12.505 9.12681 12.5306 9.32358 12.3824 9.44615C12.012 9.75527 11.3528 10.3301 10.99 10.6521L10.9849 10.647" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_10312_8749">
    <rect width="14" height="14" fill="white" transform="translate(0.910156 0.419922)"/>
    </clipPath>
    </defs>
  </svg>
</template>
