<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4373 15.0001L2.43724 7.03299L3.23014 6.23948L10.4373 13.4179L17.6443 6.23948L18.4372 7.03299L10.4373 15.0001Z"
      fill="#D1D4D7"
    />
  </svg>
</template>
