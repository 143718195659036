<template>
    <div class="feature-highlights-section container">
        <div v-for="(feature, index) in features" :key="index + 'app-icon-highlights'" class="icon">
            <div><component :is="feature.iconComponent" /></div>
            <div>{{ t(feature.textKey) }}</div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import VWIcon from '@/assets/icons/VWIcon';
import TickRoundIcon from '@/assets/icons/TickRoundIcon';

const store = useStore();
const { t } = useTranslation(['shared']);

const isMobile = computed(() => store.state.device.isMobile);

const features = computed(() => {
    const vwFeature = {
        iconComponent: VWIcon,
        textKey: 'shared_vw-group-partner',
    };

    const userFriendlyFeature = {
        iconComponent: TickRoundIcon,
        textKey: 'shared_user-friendly-navigation',
    };

    const multiVehicleFeature = {
        iconComponent: TickRoundIcon,
        textKey: 'shared_multi-vehicle-control',
    };

    if (isMobile.value) {
        return [vwFeature, userFriendlyFeature, multiVehicleFeature];
    }

    return [userFriendlyFeature, vwFeature, multiVehicleFeature];
});

</script>

<style lang="scss" scoped>

.feature-highlights-section {
      display: flex;
      justify-content: space-around;
      margin: 0px auto 50px;
      padding: 50px 0;
      border-top: 1px solid $color-neutral-light;
      border-bottom: 1px solid $color-neutral-light;

      @media (max-width: $screen-md) {
        gap: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .icon {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        width: 25%;

        @media (max-width: $screen-md) {
          width: 100%;
          flex-direction: row;
          justify-content: flex-start;
        }

        svg {
          height: 27px;

          path {
            fill: $color-black;
          }
        }

        div {
          font-size: 24px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;

          @media (max-width: $screen-md) {
            text-align: left;
          }
        }
      }
    }

</style>
