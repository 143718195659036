<template>
  <div>
    <transition name="fade" mode="out-in">
      <div class="default-page">
          <div class="page">
              <div class="container" v-html="appPrivacy?.legalContent"></div>
              <div class="cookie-declaration" v-if="appPrivacy">
                <component
                  id="CookieDeclaration"
                  data-culture="en"
                  src="https://consent.cookiebot.com/1a48cbb2-7a82-4096-8dc0-b99dc3c2a09c/cd.js"
                  :is="'script'"
                  async
                  data-georegions="{'region':'US-06','cbid':'e8dc5922-47fa-418c-9020-8108b767a54a'},{'region':'BR','cbid':'9e7b6017-cd21-46a4-9b8f-b8f2bf4b6727'},{'region':'CY, BG, CZ, HU, PL, RO, SK, DK, EE, FI, IS, IE, LV, LT, NO, SE, GB, HR, GR, IT, MT, PT, SI, ES, AT, BE, FR, DE, LI, LU, NL, CH','cbid':'1a48cbb2-7a82-4096-8dc0-b99dc3c2a09c'}"
                ></component>
              </div>
          </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { createMetaInfo } from "@/utils/helpers";
import { useHead } from '@unhead/vue';

const store = useStore();
const loaded = ref(false);

const appPrivacy = computed(() => store.state.pages.app_privacy);

const setupCookieDeclaration = () => {
  // Move the Cookie Declaration to section 8
  const cookieDeclarationContainer = document.querySelector(".cookie-declaration-container");
  const declarationScript = document.querySelector(".cookie-declaration");
  cookieDeclarationContainer.append(declarationScript);

  // Remove 1st and 2nd sections of the Cookie Declaration
  const cookieDeclarationDialogText = document.querySelector(".CookieDeclarationDialogText");
  if (cookieDeclarationDialogText) cookieDeclarationDialogText.remove();
  const cookieDeclarationIntro = document.querySelector(".CookieDeclarationIntro");
  if (cookieDeclarationIntro) cookieDeclarationIntro.remove();
};

onMounted(() => {
  document.body.classList.remove("overflow-hidden");
  if (appPrivacy.value) setupCookieDeclaration();
  loaded.value = true;
});

watch(appPrivacy, async () => {
  await appPrivacy.value;
  setupCookieDeclaration();
});

useHead(() => {
  return createMetaInfo({
    title: appPrivacy.value?.metaTitle || "Carista",
    pageMeta: appPrivacy.value,
  });
});
</script>
