<template>
    <svg
      width="40"
      height="12"
      viewBox="0 0 40 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="4.8837"
        width="36.8405"
        height="2.23629"
        rx="1.11815"
        fill="#d1d4d7"
      />
      <rect
        width="11.1682"
        height="2.23541"
        rx="1.1177"
        transform="matrix(0.865682 0.500594 -0.499406 0.866368 30.332 0.00088501)"
        fill="#d1d4d7"
      />
      <rect
        width="11.1682"
        height="2.23541"
        rx="1.1177"
        transform="matrix(0.865682 -0.500594 0.499406 0.866368 29.2148 10.0634)"
        fill="#d1d4d7"
      />
    </svg>
</template>