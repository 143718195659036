<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.78906 7.25L9.11719 5.09375H7.03125V3.6875C7.03125 3.07812 7.3125 2.51562 8.25 2.51562H9.21094V0.664062C9.21094 0.664062 8.34375 0.5 7.52344 0.5C5.8125 0.5 4.6875 1.55469 4.6875 3.42969V5.09375H2.76562V7.25H4.6875V12.5H7.03125V7.25H8.78906Z"
      fill="white"
    />
  </svg>
</template>
