<template>
  <Transition name="fade-slide">
    <div
      v-if="modalData"
      class="modal-wrapper-dark-background"
      @click.self="close"
    >
      <div class="contact-us-modal">
        <p class="contact-us-modal__title">
          {{
            titleTxt ? titleTxt : showSuccess
            ? t("shared_thank-you") : t("shared_something-went-wrong")
          }}
          <button
            name="close-btn"
            class="contact-us-modal__title__close-btn"
            @click="close"
          >
            <XCloseIcon />
          </button>
        </p>
        <div class="contact-us-modal__description">
          <LottieAnimation
            :animationLink="animationSrc"
            :loop="false"
          ></LottieAnimation>
          <p>
            {{
              descriptionTxt ? descriptionTxt : showSuccess
              ? t("shared_message-sent") : t("shared_please-try-again")
            }}
          </p>
          <button
            class="btn-feedback-outlined"
            @click="close"
          >
            {{ btnTxt ? btnTxt : showSuccess ? t('translation_ok') : t('shared_try-again') }}
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from 'vuex';
import XCloseIcon from "@/assets/icons/XCloseIcon";
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon";
import { useTranslation } from "i18next-vue";
import { Vue3Lottie as LottieAnimation } from "vue3-lottie";

const { t } = useTranslation(['shared', 'translation']);

const store = useStore();

const modalData = computed(() => store.state.modals?.isNotificationModalOpen);
const showSuccess = computed(() => store.state.modals?.isNotificationModalOpen.showSuccess);
const titleTxt = computed(() => store.state.modals?.isNotificationModalOpen.titleTxt);
const descriptionTxt = computed(() => store.state.modals?.isNotificationModalOpen.descriptionTxt);
const btnTxt = computed(() => store.state.modals?.isNotificationModalOpen.btnTxt);

const animationSrc = computed(() => {
  return showSuccess.value ? "/animations/email-sent.json" : "/animations/information-red.json";
});

function close() {
  store.dispatch("resetModalStates");
}
</script>
