<template>
  <svg
    width="28"
    height="47"
    viewBox="0 0 34 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.3923 44.2885H2.89231V2.71154H11.9308V4.33846H18.2577V2.71154H26.3923V8.49615H29.1038V0H0V47H29.1038V35.6115H26.3923V44.2885Z"
      fill="#D1D4D7"
    />
    <path
      d="M15.7275 13.3765H20.2468V12.4727H23.1391V16.8111H20.2468V16.088H15.7275V13.3765ZM23.1391 30.5496H20.2468V29.6457H15.7275V26.9342H20.2468V26.2111H23.1391V30.5496ZM15.7275 22.9573V20.2457H24.4045V19.5227H27.2968V23.8611H24.4045V22.9573H15.7275ZM33.9852 29.8265H24.5852V27.115H33.9852V29.8265ZM33.9852 22.9573H28.9237V20.2457H33.9852V22.9573ZM33.9852 16.088H24.5852V13.3765H33.9852V16.088Z"
      fill="url(#paint0_linear_23709_37052)"
    />
    <path
      d="M33.984 13.377H24.584V16.0885H33.984V13.377Z"
      fill="url(#paint1_linear_23709_37052)"
    />
    <path
      d="M33.984 26.9346H24.584V29.6461H33.984V26.9346Z"
      fill="url(#paint2_linear_23709_37052)"
    />
    <path
      d="M33.9844 20.2461H28.9229V22.9576H33.9844V20.2461Z"
      fill="url(#paint3_linear_23709_37052)"
    />
    <path
      d="M27.2968 23.6807V19.3423H24.5852V20.2461H15.7275V22.9577H24.5852V23.6807H27.2968Z"
      fill="url(#paint4_linear_23709_37052)"
    />
    <path
      d="M20.2468 16.9919H23.1391V12.4727H20.2468V13.3765H15.7275V16.088H20.2468V16.9919Z"
      fill="url(#paint5_linear_23709_37052)"
    />
    <path
      d="M20.2468 26.935H15.7275V29.6465H20.2468V30.5504H23.1391V26.2119H20.2468V26.935Z"
      fill="url(#paint6_linear_23709_37052)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23709_37052"
        x1="14.8837"
        y1="21.555"
        x2="53.3671"
        y2="21.555"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23709_37052"
        x1="14.592"
        y1="14.7447"
        x2="54.2074"
        y2="14.7447"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_23709_37052"
        x1="14.592"
        y1="28.3782"
        x2="54.2074"
        y2="28.3782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_23709_37052"
        x1="14.5924"
        y1="21.5614"
        x2="54.2078"
        y2="21.5614"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_23709_37052"
        x1="14.5932"
        y1="21.5672"
        x2="54.2086"
        y2="21.5672"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_23709_37052"
        x1="14.5932"
        y1="14.7384"
        x2="54.2086"
        y2="14.7384"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_23709_37052"
        x1="14.5932"
        y1="28.3726"
        x2="54.2086"
        y2="28.3726"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
    </defs>
  </svg>
</template>
