<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.56119 7.90809L15.3267 1.14254L14.5309 0.34668L7.76533 7.11223L0.999961 0.34686L0.204102 1.14272L6.96947 7.90809L0.204285 14.6733L1.00014 15.4691L7.76533 8.70395L14.5307 15.4693L15.3266 14.6735L8.56119 7.90809Z"
      fill="#383D45"
    />
  </svg>
</template>