<template>
  <div class="homepage-into-effect" :class="{'active': active, 'hide': hide }">
    <div class="left-white-block"></div>
    <div class="right-white-block"></div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';

const active = ref(false);
const hide = ref(false);

function activateAnimation() {
  setTimeout(() => {
    active.value = true;
    deactivateAnimation();
  }, 1000);
}

function deactivateAnimation() {
  setTimeout(() => {
    hide.value = true;
    active.value = false;
  }, 2000);
}

onBeforeMount(() => {
  activateAnimation();
});
</script>

