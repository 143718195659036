<template>
  <Transition name="fade-slide">
    <div v-if="isLoginModalOpen" class="modal-wrapper">
      <div class="login-signup-modal">
        <p class="login-signup-modal__title">
          {{ t('shared_welcome-back') }}<button @click="closeLoginModal"><XCloseIcon /></button>
        </p>
        <p class="login-signup-modal__description">{{ t('shared_log-in-with-your-account') }}</p>
        <MaterialInput class="full-grid" type="text" :label="t('shared_username')" />
        <MaterialInput class="full-grid" type="text" :label="t('shared_password')" />
        <div class="login-signup-modal__checkbox-wrapper">
          <Checkbox :label="t('shared_remember-me')" />
          <button class="btn-link forgot-password" to="#">
            {{ t('shared_forgot-password') }}
          </button>
        </div>
        <button class="blue-btn-primary">{{ t('shared_log-in') }} <ArrowRightIcon /></button>
        <p class="login-signup-modal__text">{{ t('shared_or-log-in-with') }}</p>
        <button class="btn-outlined">
          <GoogleColoredIcon />{{ t('shared_log-in-with') }} Google
        </button>
        <button class="btn-outlined"><MetaIcon />{{ t('shared_log-in-with') }} Facebook</button>
        <p class="login-signup-modal__text-secondary">
          {{ t('shared_new-to-carista') }}
          <button class="btn-link" @click.prevent="openSignupModal">
            {{ t('shared_sign-up-now') }}
          </button>
        </p>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon.vue";
import XCloseIcon from "@/assets/icons/XCloseIcon.vue";
import MaterialInput from "@/components/forms/MaterialInput.vue";
import Checkbox from "@/components/forms/Checkbox.vue";
import GoogleColoredIcon from "@/assets/icons/GoogleColoredIcon.vue";
import MetaIcon from "@/assets/icons/MetaIcon.vue";

const store = useStore();
const { t } = useTranslation(['shared']);

const isLoginModalOpen = computed(() => store.state.auth.isLoginModalOpen);

function closeLoginModal() {
  store.commit("auth/isLoginModalOpen", false);
}

function openSignupModal() {
  store.commit("auth/isLoginModalOpen", false);
  store.commit("auth/isSignupModalOpen", true);
}
</script>
