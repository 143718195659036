<template>
  <div>
    <LoadingRing v-if="loading" />
    <div
      v-if="!loading"
      class="img-container"
      @click="openArticle({
        name: 'slug',
        title: article.title,
        slug: article.slug,
        id: article.id
      })"
    >
      <img
        :src="article?.picture?.url"
        :alt="article?.picture?.altText"
        :title="article?.picture?.title"
        loading="lazy"
      />
      <span class="tag">{{ article?.tags[0]?.name }}</span>
    </div>
    <div v-if="!loading" class="article-card__text">
      <div class="date-container">
        <div class="date">{{ blogCreatedAtFormatted(article?.createdAt) }} / Carista team</div>
        <ShareButton :article="article" />
      </div>
      <div
        class="article-title"
        @click="openArticle({
          name: 'slug',
          title: article.title,
          slug: article.slug,
          id: article.id
        })"
      >{{ article?.title }}</div>
      <button
        @click="openArticle({
          name: 'slug',
          title: article.title,
          slug: article.slug,
          id: article.id
        })"
        class="blog-btn-primary"
      >
        {{ t("page-blog_read-more") }} <ArrowRightIcon />
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon.vue";
import ShareButton from "./ShareButton.vue";
import LoadingRing from "@/assets/icons/LoadingRing.vue";
import { blogCreatedAtFormatted } from "../../utils/helpers";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation(['page-blog']);

const props = defineProps({
  article: Object,
  loading: Boolean,
});

const router = useRouter();
const route = useRoute();

function openArticle(event) {
  router.push({
    name: event.name,
    params: {
      ...route.params,
      [event.name]: event.title,
      slug: event.slug,
      id: event.id,
    },
  });
}
</script>
