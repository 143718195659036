import http from './http.service';

class Profile {
  get() {
    return http.get('/api/user')
      .then(res => res.data);
  }

  save(profile) {
    return http.post('/api/user', profile)
      .then(res => res.data);
  }

  updateProfile(profile) {
    const formData = new FormData();
    formData.append('main_image', profile.new_main_image !== undefined ? profile.new_main_image : '');
    formData.append('name', profile.name);
    formData.append('email', profile.email);
    formData.append('phone', profile.phone);
    formData.append('receive_notifications', profile.receive_notifications ? 1 : 0);
    formData.append('two_factor_enabled', profile.two_factor_enabled ? 1 : 0);
    formData.append('hourly_rate', profile.userDetails.hourly_rate);
    formData.append('password', profile.password !== undefined ? profile.password : '');
    formData.append('password_confirmation', profile.password_confirmation !== undefined ? profile.password_confirmation : '');

    return http.post('/api/account', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(res => res.data);
  }

  setPreferredLanguage(data) {
    return http.post('api/preferred-language', data)
      .then(res => res.data);
  }

  deactivateProfile() {
    return http.post('/api/deactivate-profile')
      .then(res => res.data);
  }

  deleteProfile() {
    return http.post('/api/delete-profile')
      .then(res => res.data);
  }
}

export default new Profile();
