<template>
  <svg
    width="170"
    height="40"
    viewBox="0 0 170 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="Blue C-shaped Carista brand logo full name"
  >
    <g clip-path="url(#clip0_27595_11992)">
      <path
        d="M46.9008 27.6682C40.0117 27.6682 38.1094 24.807 38.1094 20.0001C38.1094 15.1933 40.0117 12.332 46.9008 12.332H55.3433V15.3649H49.5292C44.8915 15.3649 43.5857 16.2576 43.5857 19.9086C43.5857 23.5595 44.8915 24.4865 49.5292 24.4865H55.574V27.6968L46.9008 27.6682Z"
        fill="white"
      />
      <path
        d="M64.8375 21.2474H70.9161L68.3045 16.4348C68.1807 16.1658 68.0625 15.9884 67.9443 15.9884C67.8261 15.9884 67.5897 16.2574 67.4715 16.4348L64.8375 21.2474ZM63.8919 14.0256C64.6011 12.8812 65.4341 12.2402 67.9274 12.2402C69.7116 12.2402 71.1356 12.5092 71.9686 14.0256L79.5724 27.668H73.9891L72.2049 24.1887H63.6499L61.8713 27.668H56.4062L63.8919 14.0256Z"
        fill="white"
      />
      <path
        d="M80.4004 14.7414C80.4004 13.1334 81.5936 12.4238 83.4903 12.4238H94.4205C98.698 12.4238 99.8856 14.2951 99.8856 17.4138C99.8856 20.0862 99.0526 22.6728 95.2535 22.6728H93.8296V22.7643L100.358 27.6685H93.9477L89.3156 23.6627C88.6008 23.039 87.5315 22.3237 87.5315 21.5225V21.1735C87.5315 20.1034 88.6008 19.8401 90.5032 19.8401H92.1636C94.0659 19.8401 94.5387 19.1248 94.5387 17.6084C94.5387 16.0919 93.9477 15.451 91.9272 15.451H87.0531C85.9893 15.451 85.6291 15.72 85.6291 16.5211V27.7543H80.4004V14.7414Z"
        fill="white"
      />
      <path
        d="M102.74 27.6685V12.4238H107.963V27.7543L102.74 27.6685Z"
        fill="white"
      />
      <path
        d="M110.698 24.6356H121.955C123.379 24.6356 123.379 23.9203 123.379 23.5655C123.379 23.0333 123.024 22.5869 121.837 22.318L113.878 20.5326C111.261 19.9088 110.434 18.3924 110.434 16.2579C110.434 13.9403 111.739 12.4238 115.184 12.4238H127.774V15.451H117.233C116.518 15.451 115.803 15.6341 115.803 16.4353C115.803 17.059 116.163 17.4138 117.492 17.6828L124.617 19.2851C127.589 19.9088 128.894 21.0705 128.894 23.5655C128.894 26.0605 127.707 27.6685 124.262 27.6685H110.816V24.6356H110.698Z"
        fill="white"
      />
      <path
        d="M137.19 27.6685V15.451H129.586V12.4238H150.017V15.451H142.413V27.6685H137.19Z"
        fill="white"
      />
      <path
        d="M155.342 21.2474H161.404L158.787 16.4348C158.669 16.1658 158.55 15.9884 158.432 15.9884C158.196 15.9884 158.078 16.2574 157.959 16.4348L155.342 21.2474ZM154.273 14.0256C154.988 12.8812 155.821 12.2402 158.314 12.2402C160.098 12.2402 161.522 12.5092 162.355 14.0256L169.976 27.668H164.511L162.732 24.1887H154.177L152.399 27.668H146.934L154.273 14.0256Z"
        fill="white"
      />
      <path
        d="M25.5081 24.864C24.5556 26.47 23.1523 27.7495 21.4787 28.5378C19.805 29.3261 17.9377 29.5872 16.1168 29.2875C14.296 28.9878 12.6051 28.1409 11.2616 26.856C9.91809 25.571 8.98352 23.9067 8.57812 22.0771C8.95417 25.6219 10.2717 28.9959 12.3894 31.8376C14.5072 34.6793 17.3455 36.8817 20.6002 38.2088L33.4947 30.5407L25.5081 24.864Z"
        fill="white"
      />
      <path
        d="M6.49508 20.0286C6.49438 16.0059 7.62601 12.067 9.75631 8.67714C11.8866 5.2873 14.9266 2.58812 18.5172 0.898426L17.0369 0L0 9.99714V30.0258L17.0369 40L18.5341 39.1073C14.9485 37.4232 11.9107 34.733 9.77792 31.3531C7.64516 27.9732 6.50621 24.0443 6.49508 20.0286Z"
        fill="white"
      />
      <path
        d="M20.5721 1.79688C17.3244 3.12924 14.4935 5.33297 12.3812 8.17299C10.2688 11.013 8.95442 14.3828 8.57812 17.9228C8.98106 16.0914 9.91446 14.4247 11.2579 13.1378C12.6014 11.8509 14.2933 11.0028 16.1153 10.7029C17.9374 10.403 19.806 10.665 21.4802 11.4551C23.1544 12.2453 24.5574 13.5274 25.5081 15.1359L33.4947 9.61376L20.5721 1.79688Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_27595_11992">
        <rect width="170" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
