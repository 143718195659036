<template>
  <Transition name="fade-slide">
    <div
      v-if="isLangModalOpen && allLanguages?.length > 1"
      class="modal-wrapper"
      @click.self="$store.dispatch('resetModalStates')"
    >
      <div class="container lang-modal">
        <h2>
          {{ t("shared_choose-language") }}
          <a @click="$store.commit('modals/isLangModalOpen', false)"
            ><CloseIconVue />
          </a>
        </h2>
        <ul>
          <li
            v-for="(language, index) in allLanguages"
            :key="language.code + index"
            :class="{ selected: language.code === lang.code }"
            @click="chooseLang(language)"
          >
            <span>{{ language.name }}</span>
            <LanguageIcon />
          </li>
        </ul>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import LanguageIcon from "@/assets/icons/LanguageIcon.vue";
import CloseIconVue from "@/assets/icons/modals/CloseIcon.vue";

const store = useStore();
const { t } = useTranslation(['shared']);

const lang = computed(() => store.state.language.lang);
const isLangModalOpen = computed(() => store.state.modals.isLangModalOpen);
const allLanguages = computed(() => store.state.language.all);

async function chooseLang(lang) {
  store.dispatch("language/changeLanguage", lang);
  store.dispatch("profile/setPreferredLanguage", lang.code);
  store.dispatch("resetModalStates");
  store.commit("loading", true);
  await store.dispatch("pages/getPages");
  store.commit("loading", false);
}
</script>
