<template>
  <div
    class="sale-tag"
  >
    {{ text }}
  </div>
</template>

<script setup>
import { useTranslation } from "i18next-vue";

const { t } = useTranslation(['shared']);

const props = defineProps({
  text: String,
});

</script>
