<template>
  <Transition name="fade-slide">
    <div
      v-if="customize?.length > 0 && isCustomizeModalOpen"
      class="modal-wrapper"
      @click.self="$store.dispatch('resetModalStates')"
    >
      <div class="customize-modal" :style="iframeModalStyle">
        <span class="customize-modal__type">{{ t('shared_customize') }}</span>

        <p class="customize-modal__title">
          <component :is="defineAsyncComponent(Icons.getIcon(getCurrent().userString))" />
          {{ getCurrent().userString }}
          <button
            class="customize-modal__title__close-btn"
            @click="closeLoginModal"
          >
            <XCloseIcon />
          </button>
        </p>
        <ul class="customize-modal__list">
          <li
            v-for="(setting,i) in getCurrent().settings"
            :key="'customize-settings'+ i +setting.userString"
          >
            <h5>{{ setting.userString }}</h5>
            <p v-if="setting.range && setting.range.start && setting.range.end">
              {{ setting.range.start }}{{ setting.unit ? setting.unit : '' }} - {{ setting.range.end }}{{ setting.unit ? setting.unit : '' }}
            </p>
            <p v-for="option in setting.options" :key="option">
              {{ option }}
            </p>
          </li>
        </ul>
        <div class="customize-modal__section-section">
          <button
            v-if="getNext()"
            @click="pushNext"
            class="customize-modal__section-section__next-btn"
          >
            <span>{{ getNext().userString }}</span>
            <ArrowRightIcon />
          </button>
          <button
            v-if="getPrev()"
            @click="pushPrev"
            class="customize-modal__section-section__prev-btn"
          >
            <ArrowRightIcon />
          <span>{{ getPrev().userString }}</span>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import ArrowRightIcon from "@/assets/icons/supportedCars/ArrowRightIcon.vue";
import XCloseIcon from "@/assets/icons/XCloseIcon.vue";
import Icons from "@/utils/icons";

const store = useStore();
const { t } = useTranslation(['shared']);

const isCustomizeModalOpen = computed(() => store.state.modals?.isCustomizeModalOpen);
const customize = computed(() => store.state.vehiclebrands.dynamicVehiclesData?.features?.customize || []);
const iframePositionY = computed(() => isCustomizeModalOpen.value?.positionY || null);

const iframeModalStyle = computed(() => {
  const positionCalculated = window.innerWidth < 1030 ? iframePositionY.value + 540 : iframePositionY.value;

  if (iframePositionY.value) {
    return {
      position: 'absolute',
      top: positionCalculated + 'px',
    };
  }

  return {};
});

function getCurrent() {
  return customize.value[isCustomizeModalOpen.value.current];
}

function getPrev() {
  return customize.value[isCustomizeModalOpen.value.prev];
}

function getNext() {
  return customize.value[isCustomizeModalOpen.value.next];
}

function pushNext() {
  store.dispatch('modals/nextModal');
}

function pushPrev() {
  store.dispatch('modals/prevModal');
}

function closeLoginModal() {
  store.dispatch('resetModalStates');
}
</script>
