<template>
    <div>
      <transition name="fade" mode="out-in">
        <div class="default-page">
          <div>
            <div class="page">
              <div class="container">
                <h1 class="title">Carista Web Legal</h1>
                <div class="content">
                    <p><router-link :to="`/${langCode}/web-terms`"
                        ><span>{{ t("menus_web-terms") }}</span></router-link
                    ></p>
                    <p><router-link :to="`/${langCode}/app-privacy`"
                        ><span>{{ t("menus_web-privacy") }}</span></router-link
                    ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";

export default {
  setup() {

    const store = useStore();
    const { t } = useTranslation(['menus']);

    const langCode = computed(() => store.state.language.lang?.code);

    onMounted(() => {
      document.body.classList.remove("overflow-hidden");
      loaded.value = true;
    });

    return {
      t,
      langCode,
    };
  },
};
</script>

  <style scoped lang="scss">
  .content {
    color: #4e565f;
    line-height: 30px;
    font-size: 24px;

    & p {
      margin: 0 0 11px;
    }

    & a {
      color: #3973b9;
      text-decoration: underline;
      word-wrap: break-word;

      &:hover {
        color: #274f7f;
      }
    }
  }
  </style>
