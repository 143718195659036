<template>
  <svg
    width="44"
    height="35"
    viewBox="0 0 44 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.13094 22.2163H2.67631V0H0V24.7373H9.13094V22.2163Z"
      fill="#D1D4D7"
    />
    <path
      d="M22.6699 33.7182H27.8651L31.8009 29.464L30.0691 27.5732L22.6699 33.7182Z"
      fill="#D1D4D7"
    />
    <path
      d="M26.1331 17.4893C16.3725 17.4893 8.50098 25.0523 8.50098 34.506V34.6636H11.1773V34.506C11.1773 26.943 17.1596 20.6405 24.8737 20.0103V21.901H27.3926V20.0103C30.6986 20.3254 33.6898 21.5859 36.0512 23.6342L34.7918 25.0523L36.6809 26.7854L37.9404 25.3674C39.987 27.8884 41.089 30.8821 41.089 34.3484V34.6636H43.7653V34.506C43.7653 25.0523 35.7363 17.4893 26.1331 17.4893Z"
      fill="#D1D4D7"
    />
    <path
      d="M16.2154 4.09668H13.5391V15.5987H16.2154V4.09668Z"
      fill="url(#paint0_linear_23709_37074)"
    />
    <path
      d="M22.5122 0.31543H19.8359V15.599H22.5122V0.31543Z"
      fill="url(#paint1_linear_23709_37074)"
    />
    <path
      d="M9.76078 7.40527H7.08447V15.5985H9.76078V7.40527Z"
      fill="url(#paint2_linear_23709_37074)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23709_37074"
        x1="5.51738"
        y1="9.84771"
        x2="40.5145"
        y2="9.84771"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23709_37074"
        x1="5.51706"
        y1="7.95721"
        x2="40.5142"
        y2="7.95721"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_23709_37074"
        x1="5.51741"
        y1="11.5019"
        x2="40.5146"
        y2="11.5019"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CAE7C" />
        <stop offset="0.1354" stop-color="#349E96" />
        <stop offset="0.5052" stop-color="#2581C4" />
      </linearGradient>
    </defs>
  </svg>
</template>
