<template>
  <div class="tooltip-container" v-click-outside="handleOutsideClick">
    <slot name="base" :toggle="toggleDropdown"></slot>
    <div v-if="isDropdownOpen" class="tooltip-dropdown-wrapper">
      <div class="tooltip-dropdown">
        <slot name="tooltip" :close="handleOutsideClick"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const isDropdownOpen = ref(false);

function toggleDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value;
}

function handleOutsideClick(event) {
  isDropdownOpen.value = false;
}

// Custom directive for click outside
const vClickOutside = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  beforeUnmount: (el) => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};
</script>
