<template>
  <div
    id="header"
    :class="`header ${position} ${otherStates.openMenuMobile ? 'open' : 'close'}`"
  >
    <div class="container">
      <div class="main-menu">
        <div :class="`logo-container ${otherStates.openMenuMobile ? 'open' : 'close'}`">
          <router-link :to="`/${langCode}`" @click="setOpenMenuMobile(false)">
            <Logo class="logo" />
          </router-link>
          <Hamburger @toggle="setOpenMenuMobile" :isActive="otherStates.openMenuMobile" />
        </div>
        <div
          class="navigation-wrapper"
          :class="[
            { 'open': otherStates.openMenuMobile },
            { 'close': !otherStates.openMenuMobile },
            { 'sale' : isInUK && $appConfig.saleEnabled }
          ]"
        >
          <div class="menu" @mouseenter="showDropdownUser = false">
            <!--   <div class="mobile-search show-on-mobile">
              <SearchIcon />
              <input type="text" :placeholder="$t('menus_search')" />
            </div> -->
            <ul class="main-dropdown">
              <li class="main-dropdown__item">
                <router-link
                  @click="setOpenMenuMobile(false)"
                  :to="`/${langCode}/supported-cars`"
                >
                  {{ t("menus_supported-cars") }}
                </router-link>
              </li>
              <li
                @mouseenter="handleDropdown({ dropdownProducts: true }, $event)"
                @mouseleave="
                  handleDropdown({ dropdownProducts: false }, $event)
                "
                @click="
                  handleDropdown(
                    { dropdownProducts: !dropdownStates.dropdownProducts },
                    $event
                  )
                "
                class="main-dropdown__item"
              >
                <a
                  class="main-dropdown__item__link"
                  :class="dropdownStates.dropdownProducts ? 'open-dropdown' : ''"
                  href="#"
                >
                  {{ t("menus_products") }}
                  <ArrowIcon :class="`arr ` + dropdownStates.dropdownProducts" />
                </a>
                <ul
                  :class="`dropdown products ` + dropdownStates.dropdownProducts"
                  @click="dropdownStates.dropdownProducts = false"
                >
                  <li class="dropdown-item">
                    <router-link
                      @click="setOpenMenuMobile(false)"
                      :to="`/${langCode}/adapters`"
                    >
                      {{ t("menus_adapter") }}
                      <SaleTag
                        :text="t('shared_evo-offer')"
                        v-if="isInUK && $appConfig.saleEnabled"
                      />
                    </router-link>
                  </li>
                  <li class="dropdown-item">
                    <router-link
                      class="dropdown-item__link"
                      :to="`/${langCode}/app`"
                      @click="setOpenMenuMobile(false)"
                    >
                      {{ t("menus_app") }}
                    </router-link>
                  </li>
                  <!--     <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_merchandise') }}</a>
                  </li> -->
                  <!--    <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_bundles') }}</a>
                  </li> -->
                  <div @click="dropdownStates.dropdownProducts = false" class="grayBg"></div>
                </ul>
              </li>
              <li @click="dropdownStates.dropdownProducts = false" class="main-dropdown__item">
                <router-link
                  @click="setOpenMenuMobile(false)"
                  :to="`/${langCode}/features`"
                >
                  {{ t("menus_app-features") }}
                </router-link>
              </li>
              <li
                @mouseenter="handleDropdown({ dropdownSupport: true }, $event)"
                @mouseleave="handleDropdown({ dropdownSupport: false }, $event)"
                @click="
                  handleDropdown({ dropdownSupport: !dropdownStates.dropdownSupport }, $event)
                "
                class="main-dropdown__item"
              >
                <a :class="dropdownStates.dropdownSupport ? 'open-dropdown' : ''">
                  {{ t("menus_support-and-resources") }}
                  <ArrowIcon :class="`arr ` + dropdownStates.dropdownSupport" />
                </a>
                <ul :class="`dropdown support ${dropdownStates.dropdownSupport}`">
                  <li class="dropdown-item">
                    <a
                      class="dropdown-item__link"
                      :href="$appConfig.caristaFaqUrl"
                      target="_blank"
                      >{{ t("menus_help-center") }}</a
                    >
                  </li>
                  <!--   <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_knowledge-base') }}</a>
                  </li> -->
                  <li class="dropdown-item">
                    <router-link
                      class="dropdown-item__link"
                      :to="`/${langCode}/blog`"
                      @click.native="setOpenMenuMobile(false)"
                    >
                      {{ t("menus_blog") }}
                    </router-link>
                  </li>
                  <li class="dropdown-item">
                    <router-link
                      class="dropdown-item__link"
                      :to="`/${langCode}/adapter-documents`"
                      @click="setOpenMenuMobile(false)"
                    >
                      {{ t("menus_adapter-documents") }}
                    </router-link>
                  </li>
                  <!--  <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_glossary') }}</a>
                  </li> -->
                  <!--   <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_events') }}</a>
                  </li> -->
                  <!--   <li class="dropdown-item">
                    <a href="#" class="clear beta">{{ $t('menus_beta') }}</a>
                  </li> -->
                  <div @click="dropdownStates.dropdownSupport = false" class="grayBg"></div>
                </ul>
              </li>
              <li
                @mouseenter="handleDropdown({ dropdownCompany: true }, $event)"
                @mouseleave="handleDropdown({ dropdownCompany: false }, $event)"
                @click="
                  handleDropdown({ dropdownCompany: !dropdownStates.dropdownCompany }, $event)
                "
                class="main-dropdown__item"
              >
                <a :class="dropdownStates.dropdownCompany ? 'open-dropdown' : ''">
                  {{ t("menus_company") }}
                  <ArrowIcon :class="`arr ` + dropdownStates.dropdownCompany" />
                </a>
                <ul :class="`dropdown ${dropdownStates.dropdownCompany}`">
                  <li class="dropdown-item">
                    <router-link
                      @click="setOpenMenuMobile(false)"
                      :to="`/${langCode}/contact-us`"
                    >
                      {{ t("menus_contact-us") }}
                    </router-link>
                  </li>
                  <div @click="dropdownStates.dropdownCompany = false" class="grayBg"></div>
                </ul>
              </li>
              <!-- TODO - COMPANY -->
              <!--  <li class="main-dropdown__item">
                <a
                  :class="dropdownAbout ? 'open-dropdown' : ''"
                  @mouseenter="
                    handleDropdown(
                      {
                        dropdownAbout: true,
                      },
                      $event
                    )
                  "
                  @mouseleave="
                    handleDropdown(
                      {
                        dropdownAbout: false,
                      },
                      $event
                    )
                  "
                  @click="
                    handleDropdown(
                      {
                        dropdownAbout: !dropdownAbout,
                      },
                      $event
                    )
                  "
                  href="#"
                  >
                  {{ $t('menus_company') }}
                  <ArrowIcon :_class="`arr ` + dropdownAbout" />
                </a>
                <ul
                  @mouseenter="dropdownAbout = true"
                  @mouseleave="dropdownAbout = false"
                  :class="`dropdown company ` + dropdownAbout"
                >
                  <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_about-us') }}</a>
                  </li>
                  <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_press') }}</a>
                  </li>
                  <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_partners') }}</a>
                  </li>
                  <li class="dropdown-item">
                    <a class="dropdown-item__link" href="#">{{ $t('menus_careers') }}</a>
                  </li>
                  <li class="dropdown-item">
                    <a href="#" class="clear">{{ $t('menus_contact-us') }}</a>
                  </li>
                  <div @click="dropdownAbout = false" class="grayBg"></div>
                </ul>
              </li> -->
            </ul>
          </div>
          <!-- TODO header-social -->
          <!--        <div class="social">
            <div class="social__item">
              <a class="icon-cart-link" href="#">
                <BasketIcon class="icon-cart" />
                <span class="icon-link-text show-on-mobile">{{ $t('menus_shopping-cart') }}</span>
              </a>
            </div>
            <div class="social__item">
              <SearchForm></SearchForm>
            </div>
            <div class="social__item sign-in">
              <span class="icon-user-link">
                <UserIcon class="social user" />
                <button
                  class="icon-link-text show-on-mobile"
                  @click="openLoginModal"
                >
                  {{ $t('menus_sign-in') }}
                </button>
              </span>
              <div class="dropdown-user">
                <a href="#" @click.prevent="openLoginModal">{{ $t('menus_log-in') }}</a>
                <a href="#" @click.prevent="openSignupModal">{{ $t('menus_sign-up') }}</a>
              </div>
            </div>
            <a
              @click="$store.commit('language/isLangModalOpen', true)"
              class="header-mobile-language show-on-mobile"
            >
              <LanguageIcon />
              <span class="icon-link-text show-on-mobile">{{ lang.name }}</span>
            </a>
          </div> -->

          <!-- TODO header-social -->
          <div class="social">
            <Language-selector></Language-selector>
            <a
              @click="
                $store.dispatch('modals/openModal', {
                  currentModal: 'isLangModalOpen',
                  data: true,
                })
              "
              class="header-mobile-language show-on-mobile"
            >
              <LanguageIcon />
              <span class="icon-link-text show-on-mobile">{{
                lang?.name
              }}</span>
            </a>
          </div>
          <div
            @click="
              facebookGoogleAnalitycs('get_started_click', {
                button_position: 'header',
              })
            "
            class="getStarted hide-on-mobile"
          >
            <router-link :to="`/${langCode}/supported-cars`">
              {{ t("menus_get-started") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import LanguageSelector from "../components/LanguageSelector.vue";
import Hamburger from "./UI-elements/Hamburger.vue";
import LanguageIcon from "@/assets/icons/LanguageIcon.vue";
import ArrowIcon from "@/assets/icons/ArrowIcon.vue";
import Logo from "@/assets/icons/Logo.vue";
import { facebookGoogleAnalitycs } from "@/utils/helpers";
import { useTranslation } from "i18next-vue";
import SaleTag from './SaleTag.vue';

const { t } = useTranslation(['menus', 'shared']);

const store = useStore();
const props = defineProps({
  position: Boolean,
});

const dropdownStates = reactive({
  dropdownProducts: false,
  dropdownSupport: false,
  dropdownAbout: false,
  dropdownCompany: false,
});

const otherStates = reactive({
  openMenuMobile: false,
  showDropdownUser: false,
});

const lang = computed(() => store.state.language.lang);
const langCode = computed(() => store.state.language.lang?.code);
const isMobile = computed(() => store.state.device.isMobile);
const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');

watch(() => otherStates.openMenuMobile, (val) => {
  if (val && isMobile.value) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
});

const emit = defineEmits(['removeRibbon']);

function openLoginModal() {
  store.commit("auth/isLoginModalOpen", true);
}

function openSignupModal() {
  store.commit("auth/isSignupModalOpen", true);
}

function showDropdownLogin() {
  otherStates.openMenuMobile = !otherStates.showDropdownUser;
}

function setOpenMenuMobile(isOpen) {
  if (isMobile.value) {
    otherStates.openMenuMobile = isOpen;
    emit('removeRibbon', isOpen);
  }
}

function handleDropdown(item, event) {
  const [key] = Object.keys(item);
  const resetDropdowns = { ...dropdownStates };
  resetDropdowns[key] = item[key];
  Object.assign(dropdownStates, resetDropdowns);

  if (isMobile.value && event.type === "click") {
    Object.assign(dropdownStates, resetDropdowns);
  }

  if (
    (!isMobile.value && event.type === "mouseenter") ||
    (!isMobile.value && event.type === "mouseleave")
  ) {
    Object.assign(dropdownStates, resetDropdowns);
  }
}

function onClickChildModal(value) {
  if (value === true) {
    otherStates.showDropdownUser = !otherStates.showDropdownUser;
    otherStates.showDropdownUser = false;
  }
}
</script>
