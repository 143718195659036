<template>
	<svg version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
	 	viewBox="0 0 800 248.2"
	 	style="enable-background:new 0 0 800 248.2;"
	 	xml:space="preserve"
	 	title="Blue C-shaped Carista brand logo full name"
	>
  	<g>
		<g>
			<path fill="#3387C4" d="M226.1,158.3L226.1,158.3c-31.2,0-39.8-12.7-39.8-34.2c0-21.5,8.6-34.2,39.8-34.2h38.2v13.5H238
				c-21,0-26.9,4-26.9,20.3S217,144,238,144h27.5v14.3H226.1z"/>
			<path fill="#3387C4" d="M303.1,97.5c3.2-5.2,7-7.9,18.3-7.9c8.1,0,14.5,1.2,18.3,7.9l34.5,60.8h-25.3l-8.1-15.5H302l-8.1,15.5h-24.8
				L303.1,97.5z M307.4,129.6h27.5L323,108.2c-0.5-1.2-1.1-2-1.6-2s-1.6,1.2-2.2,2L307.4,129.6z"/>
			<path fill="#3387C4" d="M377.9,100.6c0-7.2,5.4-10.3,14-10.3h49.5c19.4,0,24.8,8.3,24.8,22.3c0,11.9-3.8,23.4-21,23.4h-6.5v0.4
				l29.6,21.9h-29.1l-21-17.9c-3.2-2.8-8.1-6-8.1-9.5v-1.6c0-4.8,4.8-6,13.5-6h7.5c8.6,0,10.8-3.2,10.8-9.9c0-7.2-2.7-9.5-11.8-9.5
				h-22.1c-4.8,0-6.5,1.2-6.5,4.8v50.1h-23.7L377.9,100.6L377.9,100.6z"/>
			<path fill="#3387C4" d="M479.2,158.3V90.3h23.7v68.3L479.2,158.3L479.2,158.3z"/>
			<path fill="#3387C4" d="M515.2,144.7h51.1c6.5,0,6.5-3.2,6.5-4.8c0-2.4-1.6-4.4-7-5.6l-36.1-7.9c-11.8-2.8-15.6-9.5-15.6-19.1
				c0-10.3,5.9-17.1,21.5-17.1h57.1v13.5h-47.9c-3.2,0-6.5,0.8-6.5,4.4c0,2.8,1.6,4.4,7.5,5.6l32.3,7.2c13.5,2.8,19.4,7.9,19.4,19.1
				c0,10.7-5.4,18.3-21,18.3h-60.8v-13.5L515.2,144.7L515.2,144.7z"/>
			<path fill="#3387C4" d="M635.3,158.3v-54.4h-34.5V90.3h92.6v13.5H659v54.4L635.3,158.3z"/>
			<path fill="#3387C4" d="M712.8,97.5c3.2-5.2,7-7.9,18.3-7.9c8.1,0,14.5,1.2,18.3,7.9l34.5,60.8h-24.8l-8.1-15.5h-38.8l-8.1,15.5
				h-24.8L712.8,97.5z M717.7,129.6h27.5l-11.8-21.5c-0.5-1.2-1.1-2-1.6-2c-1.1,0-1.6,1.2-2.2,2L717.7,129.6z"/>
		</g>
		<g>
			<path fill="#2A75B9" d="M129.2,145.8c-7.4,12.1-20.6,20.3-35.9,20.3c-23.2,0-41.9-18.8-41.9-41.9c0-23.2,18.8-41.9,41.9-41.9
				c15.2,0,28.5,8.1,35.9,20.3l36.2-24.9l-72-42.6L16.1,79.5v89.1l77.2,44.6l71.9-42.3L129.2,145.8z"/>
			<g>
				<defs>
					<circle id="SVGID_1_" cx="136.8" cy="124.1" r="90.6"/>
				</defs>
				<clipPath id="SVGID_00000012440657711207562760000005982791107293064603_">
					<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
				</clipPath>

					<linearGradient id="SVGID_00000052078444738385343490000007276739629750213508_" gradientUnits="userSpaceOnUse" x1="16.1189" y1="124.0851" x2="165.3612" y2="124.0851">
					<stop  offset="0" style="stop-color:#5AC1D9"/>
					<stop  offset="1" style="stop-color:#2A75B9"/>
				</linearGradient>

					<path style="clip-path:url(#SVGID_00000012440657711207562760000005982791107293064603_);fill:url(#SVGID_00000052078444738385343490000007276739629750213508_);" d="
					M129.2,145.8c-7.4,12.1-20.6,20.3-35.9,20.3c-23.2,0-41.9-18.8-41.9-41.9c0-23.2,18.8-41.9,41.9-41.9c15.2,0,28.5,8.1,35.9,20.3
					l36.2-24.9l-72-42.6L16.1,79.5v89.1l77.2,44.6l71.9-42.3L129.2,145.8z"/>
			</g>
		</g>
	</g>
</svg>
</template>
