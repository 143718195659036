<template>
  <div class="breadcrumb">
    <div class="container">
      <div class="breadcrumb-links" :class="{ loaded: loaded }" ref="breadcrumbs">
        <div
          class="link-wrapper"
          v-for="(route, i) in breadcrumbRoutes"
          :key="`breadcrumb-key-` + i"
        >
          <span
            v-if="(!isOverflowed && i > 0)
              || (isOverflowed && (i === 1 || i === breadcrumbRoutes.length - 1))"
            class="breadcrumb-separator"
          > / </span>
          <BreadcrumbDropdown
            v-if="isOverflowed && i === breadcrumbRoutes.length - 2 && route.meta.breadcrumb !== 'home'"
            :dropdownItems="dropdownItems"
          />
          <router-link
            class="breadcrumb-link"
            :to="{ name: route.meta.breadcrumb || route.name }"
            v-else
          >
            <HomeIcon v-if="route.meta.breadcrumb === 'home'"/>
            <span v-else-if="!isOverflowed || (isOverflowed && i === breadcrumbRoutes.length - 1)">
              {{ breadcrumbLink(route, t(`breadcrumb_` + route.meta.breadcrumb)) }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from "i18next-vue";
import { isPricingPage } from "@/utils/helpers";
import HomeIcon from '@/assets/icons/HomeIcon.vue';
import BreadcrumbDropdown from './BreadcrumbDropdown';

const { t } = useTranslation(['breadcrumb']);

const store = useStore();
const route = useRoute();

const selector = computed(() => store.state.vehiclebrands.wizard.selector);
const openedArticle = computed(() => store.state.blog?.openedArticle);

const breadcrumbRoutes = computed(() => {
  let routes = route.matched.filter(
    (r) => r.meta.breadcrumb || route.params[r.name]
  );

  // add "Supported cars" breadcrumb for the Pricing pages
  const isPricing = isPricingPage(route.name);
  if (isPricing) {
    routes.splice(1, 0, {
      name: "supported-cars",
      meta: { breadcrumb: 'supported-cars' }
    });
  }

  if (isOverflowed) {
    dropdownItems.value = [...routes].filter((item, i) => i !== 0 && i !== routes.length - 1);
  }

  return routes;
});

const isMobile = computed(() => store.state.device.isMobile);

const breadcrumbs = ref();
const isOverflowed = ref(false);
const loaded = ref(false);
const dropdownItems = ref([]);

onMounted(() => {
  setTimeout(() => {
    isOverflowed.value = isOverflowing(breadcrumbs.value);
    loaded.value = true;
  }, 1000);
});

watch(isMobile, () => {
  isOverflowed.value = isOverflowing(breadcrumbs.value);
});
watch(route, () => {
  setTimeout(() => {
    isOverflowed.value = isOverflowing(breadcrumbs.value);
  }, 1000);
});

function isOverflowing(el) {
  const containerWidth = el.offsetWidth;

  let sum = 0;

  [...el.children].forEach((item) => {
    sum += item.offsetWidth;
  });

  return sum > containerWidth;
}

function breadcrumbLink(breadcrumbRoute, breadcrumbString) {
  let innerBreadcrumbString = route.params[breadcrumbRoute.name];

  if (route.params.make) {
    innerBreadcrumbString = selector.value[breadcrumbRoute.name]?.title;
  }

  if (route.name === 'slug') {
    innerBreadcrumbString = openedArticle.value?.title;
  }

  return breadcrumbRoute.meta.breadcrumb ? breadcrumbString : innerBreadcrumbString;
}
</script>
