<template>
    <div class="search-box" :class="{ active: selector?.model }">
        <button
            v-if="
                (dynamicVehiclesData?.param === 'model' ||
                    dynamicVehiclesData?.param === 'generation') &&
                showInternalResetButton
            "
            @click="handleReset"
            class="reset-btn"
        >
            <ResetIcon color="#9197A0" /> {{ t('page-supported-cars_reset') }}
        </button>
        <form
            @submit.prevent="() => null"
            v-if="!selector.model"
            class="search-box__form"
        >
            <SearchIcon />
            <input
                id="search-input"
                type="text"
                v-model="keyword"
                :placeholder="`${
                    expected?.param === 'make'
                        ? t('page-supported-cars_search-for-your-car-brand')
                        : t('page-supported-cars_search-for-your-car-model')
                }`"
            />
        </form>

        <Spinner key="spinner" v-if="selector.generation || loading" />

        <div class="results-list" :class="{ active: selector?.model }">
            <ul v-if="!loading">
                <li
                    v-for="option in expected.options
                        ? expected.options.filter((option) =>
                              option.title
                                  .toLocaleLowerCase()
                                  .includes(keyword.toLocaleLowerCase())
                          )
                        : []"
                    @click="
                        handleStep({
                            name: expected.param,
                            value: option,
                        })
                    "
                    :key="option.id"
                    :class="
                        isClassActive(
                            'results-list__item',
                            expected.param === 'generation'
                        )
                    "
                >
                    <div>
                        <span>{{ option?.title }}</span>
                        <p
                            class="results-list__years"
                            v-if="expected.param === 'generation'"
                        >
                            <span class="year">{{
                                t('page-supported-cars_year')
                            }}</span>
                            {{
                                option.startYear +
                                (option.endYear !== 0
                                    ? ' - ' + option.endYear
                                    : '+')
                            }}
                        </p>
                    </div>
                    <ArrowRightIcon v-if="expected.param === 'generation'" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from 'i18next-vue';
import SearchIcon from '@/assets/icons/SearchIcon.vue';
import Spinner from '@/assets/icons/Spinner.vue';
import ArrowRightIcon from '@/assets/icons/supportedCars/ArrowRightIcon.vue';
import ResetIcon from '@/assets/icons/ResetIcon.vue';
import { isClassActive, facebookGoogleAnalitycs } from '@/utils/helpers';
import { EVO_PRICING_PAGE_ROUTE } from '@/utils/constants';

const store = useStore();
const route = useRoute();
const { t } = useTranslation(['page-supported-cars']);

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    showInternalResetButton: {
        type: Boolean,
        default: false,
    },
});

const expected = computed(() => store.state.vehiclebrands.wizard.expected);
const selector = computed(() => store.state.vehiclebrands.wizard.selector);
const dynamicVehiclesData = computed(
    () => store.state.vehiclebrands.dynamicVehiclesData
);

const keyword = ref('');

async function handleStep(event) {
    let params = {};

    if (event.name === 'make') {
        params.make = event.value.id;
    }
    if (event.name === 'model') {
        params.make = selector.value.make.id;
        params.model = event.value.id;
    }
    if (event.name === 'generation') {
        params.make = selector.value.make.id;
        params.model = selector.value.model.id;
        params.generation = event.value.id;

        const isEvoPricingPage = route.name === EVO_PRICING_PAGE_ROUTE;

        const eventName = isEvoPricingPage
            ? 'vehicle_check_evo_pricing'
            : 'vehicle_check_pricing';

        facebookGoogleAnalitycs(eventName, {
            brand: params.make,
            model: params.model,
            generation: params.generation,
        });
    }
    await store.dispatch('vehiclebrands/getDynamicVehiclesData', params);
    keyword.value = '';
}

const handleReset = () => {
    store.dispatch('vehiclebrands/getDynamicVehiclesData', {});
};
</script>

<style lang="scss">
.reset-btn {
    position: absolute;
    top: -34px;
    right: 16px;
    text-transform: capitalize;
    color: $color-gray;
    background-color: transparent;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
        color: $color-gray;
    }
}
</style>
