import axios from './http.service';
import { mapLangCodeToFullName } from '@/utils/helpers';

class Testimonials {
  getTestimonials(lang, categoryId) {
    const query = {
      query: `
      {
        testimonialsByCategory(
          input: {
            language: ${mapLangCodeToFullName(lang)},
            environment: ${process.env.VUE_APP_WEB_CMS_ENV},
            categoryId: "${categoryId}",
            page: 1,
            perPage: 999
          }
        ) {
          testimonials {
            description
            id
            language
            title
          }
        }
      }`
    }

    return axios.post(`${process.env.VUE_APP_WEB_CMS}`, query)
      .then(res => res.data);
  }
}

export default new Testimonials();
