<template>
    <div v-if="!vehicleGenerationSelected">
        <div class="vehicle-selector-container">
            <div class="header-container">
                <div>
                    {{ title }}
                </div>
            </div>
            <div class="search-box-container">
                <VehicleSelectorSearchBox
                    :loading="loading"
                    :showInternalResetButton="true"
                />
                <p class="disclaimer-note">
                    {{
                        t(
                            'page-supported-cars_exact-features-availability-depends-on-your-vehicle'
                        )
                    }}
                </p>
            </div>

            <WizardProgressBar :showBtns="false" />
        </div>
    </div>
    <SupportedCarsResultPage
        v-if="vehicleGenerationSelected"
        :iFrameModeEnabled="true"
    />
    <CustomizeModal />
    <ServiceModal />
    <LiveDataModal />
</template>

<script setup>
import { onMounted, onBeforeMount, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from 'i18next-vue';
import VehicleSelectorSearchBox from '@/components/supported-cars/VehicleSelectorSearchBox';
import WizardProgressBar from '@/components/supported-cars/WizardProgressBar';
import SupportedCarsResultPage from './SupportedCarsResultPage.vue';
import CustomizeModal from '@/components/modals/CustomizeModal';
import ServiceModal from '@/components/modals/ServiceModal.vue';
import LiveDataModal from '@/components/modals/LiveDataModal.vue';
import { useGtm } from '@gtm-support/vue-gtm';
import '@iframe-resizer/child';

const store = useStore();
const route = useRoute();
const { t } = useTranslation(['shared', 'page-supported-cars']);

const { options } = useGtm();

onBeforeMount(async () => {
    await handleDistributorAndLanguage();

    // Disable Google Tag Manager
    // Note: Events will still be pushed to dataLayer, but GTM won't look for it
    options.enabled = false;
});

onMounted(() => {
    document.querySelector('body').style.paddingBottom = '0px';
    store.dispatch('vehiclebrands/getDynamicVehiclesData', {});
    store.dispatch('vehiclebrands/getSummary');
});

const loading = ref(false);

const dynamicVehiclesData = computed(
    () => store.state.vehiclebrands.dynamicVehiclesData
);

const vehicleGenerationSelected = computed(
    () => dynamicVehiclesData.value?.selector?.generation
);

const title = computed(() => {
    const selectorStep = dynamicVehiclesData.value.param;

    switch (selectorStep) {
        case 'make':
            return t('page-supported-cars_choose-your-car-brand');
        case 'model':
            return t('page-supported-cars_choose-your-car-model');
        case 'generation':
            return t('page-supported-cars_choose-your-car-generation');
        default:
            return t('page-supported-cars_choose-your-car-brand');
    }
});

const handleDistributorAndLanguage = async () => {
    if (isUuidValid(route.params.uuid)) {
        await store.dispatch('fetchDistributorByUuid', route.params.uuid);
        const langCode = store.state.distributorData.language;

        store.dispatch('language/changeLanguage', { code: langCode });
    }
};

const isUuidValid = (uuid) => {
    return typeof uuid === 'string' && uuid.length >= 15;
};

document.body.classList.remove('overflow-hidden');
</script>

<style lang="scss">
.vehicle-selector-container {
    background-color: $color-white;
    padding: 80px 0 80px 0;

    .header-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-bottom: 45px;

        @media (max-width: $screen-md) {
            width: 100%;
            padding: 0 20px 20px;
        }

        > div {
            font-weight: 500;
            font-size: 56px;

            @media (max-width: $screen-md) {
                font-size: 32px;
            }
        }
    }

    .search-box-container {
        @media (max-width: $screen-md) {
            padding: 0 20px;
        }

        .search-box {
            width: Min(700px, 50%);
            min-width: 300px;
            height: 440px;
            max-height: 500px;
            flex: 10;
            padding: 10px 33px 0 33px;
            background: $color-neutral-lightest;
            border-radius: 25px;
            box-sizing: 0px 0px 3px 0px $color-neutral-lighter;
            margin: 25px auto 15px auto;
            position: relative;
            display: flex;
            flex-direction: column;

            @media (max-width: $screen-md) {
                width: 100%;
                min-width: 100%;
                padding: 10px 20px 20px 20px;
                margin: 0 auto;
                border-radius: 15px;
            }

            @media (max-width: $screen-sm) {
                min-height: 100%;
                height: 330px;
            }

            &.active {
                @media (max-width: $screen-md) {
                    padding: 10px;
                }
            }

            &__form {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $color-neutral-lighter;
                margin: 15px 0px 10px;
                padding: 0px 0 26px 0;

                @media (max-width: $screen-xl) {
                    padding-top: 0px;
                    padding-bottom: 18px;
                }

                @media (max-width: $screen-lg) {
                    padding: 0;
                    padding-bottom: 14px;
                }

                @media (max-width: $screen-md) {
                    margin: 0px 0px 5px;
                    padding: 0px 0px 15px 0;
                }

                > svg {
                    margin-top: 5px;
                    transform: scale(1.45);

                    @media (max-width: $screen-md) {
                        transform: scale(1.3);
                    }
                }

                > input {
                    margin-top: 6px;
                    width: 100%;
                    border: none;
                    outline: none;
                    background: transparent;
                    font-size: rem(25px);
                    color: $color-gray;
                    font-weight: 500;
                    letter-spacing: -0.0025em;
                    margin-left: 5px;

                    @media (max-width: $screen-md) {
                        font-size: 14px;
                    }
                    &::placeholder {
                        font-size: 20px;
                        letter-spacing: -0.0025em;
                        color: inherit;
                        font-weight: inherit;

                        @media (max-width: $screen-md) {
                            font-size: 14px;
                        }
                    }
                }
            }

            .results-list {
                display: flex;
                flex-direction: column;
                flex: 10;
                position: relative;
                height: 100%;
                max-width: 100%;
                @media (max-width: $screen-md) {
                    max-height: 100%;
                    padding: 0 0;
                    margin-top: 0px;
                }

                &::-webkit-scrollbar {
                    overflow: overlay;
                    width: 3px;
                    max-height: 200px;
                    height: 200px;
                    background: rgb(230, 230, 230);
                    left: -100px;
                }

                &.active {
                    max-width: 100%;
                    padding: 0;

                    &:first-child {
                        @media (max-width: $screen-md) {
                            margin-top: 0;
                        }
                    }

                    &:last-child {
                        @media (max-width: $screen-md) {
                            margin-bottom: 0;
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &::-webkit-scrollbar {
                        display: none !important;
                        color: red;
                        background: red;
                    }
                }

                &__years {
                    color: $color-primary-default;
                    font-size: rem(14px);
                    font-weight: 500;
                    margin-top: 13px;
                    @media (max-width: $screen-md) {
                        font-size: 14px;
                    }

                    .year {
                        color: $color-gray;
                        margin-right: 16px;
                    }
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: $color-neutral-lighter;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: transparent;
                }

                overflow: auto;
                overflow-x: hidden;
                &__item {
                    cursor: pointer;
                    padding: 10px 0px;
                    border-radius: 5px;
                    font-size: 20px;
                    max-width: 100%;
                    font-weight: 500;
                    color: $color-neutral-darkest;
                    transition: background 0.3s ease;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @media (max-width: $screen-md) {
                        font-size: 18px;
                        max-width: 100%;
                    }

                    /* &:first-child{
              padding-top: 0;
            } */

                    &.active {
                        background: white;
                        padding: 25px;
                        margin: 5px 0px;
                        border-radius: 20px;

                        &:first-child {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }

        .disclaimer-note {
            font-size: 20px;
            color: $color-neutral-darker;
            text-align: center;

            @media (max-width: $screen-sm) {
                margin-top: 16px;
                font-size: 12px;
            }
        }
    }

    .progres-bar-wrapper {
        display: flex;
        align-items: center;
        margin-top: 130px;
        flex: 1;

        @media (max-width: $screen-md) {
            flex-direction: column-reverse;
            gap: 30px;
            justify-content: center;
        }

        @media (max-width: $screen-sm) {
            gap: 0;
            margin-top: 30px;
        }

        > a {
            padding: 10px 20px;
            font-size: rem(14px);
            font-weight: 500;
            border-radius: 50px;
            border: 1px solid $color-neutral-lighter;
            color: $color-neutral-darker;
            white-space: nowrap;
            @media (max-width: $screen-md) {
                width: 90%;
                text-align: center;
                font-size: 14px;
            }
        }

        .all-supported-cars-link {
            @media (max-width: $screen-md) {
                width: 100%;
            }

            @media (max-width: $screen-sm) {
                height: 40px;
                margin: 0;
            }

            @media (max-width: $screen-sm) and (max-height: 650px) {
            }
        }

        .step-progress-bar {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 4px;
            background: $color-light;
            background-image: url('/public/images/Rectangle\ 1.png');
            background-size: 5% 100%;
            background-repeat: no-repeat;
            width: 67.5% !important;
            @media (max-width: $screen-md) {
                justify-content: space-around;
                height: 6px;
                margin: 0;
                min-width: 100vw;
            }

            @media (max-width: $screen-sm) {
                margin-top: 80px;
                margin-bottom: 20px;
            }

            transition: background-size 0.5s ease;
            &.make {
                background-size: calc(16% + 58px) 100%;
                @media (max-width: $screen-md) {
                    background-size: 16% 100%;
                }

                @media (max-width: $screen-xs) {
                    background-size: 15% 100%;
                }
            }
            &.model {
                background-size: 50% 100%;
            }
            &.generation {
                background-size: 78% 100%;

                @media (max-width: $screen-lg) {
                    background-size: 75% 100%;
                }

                @media (max-width: $screen-md) {
                    background-size: 83% 100%;
                }
                @media (max-width: $screen-xs) {
                    background-size: 85% 100%;
                }
            }
            &.done {
                background-size: 100% 100%;
            }
            width: 100%;
            .item {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                background-color: inherit;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background 0.5s ease;
                &.brand-item {
                    transform: translateX(58px);
                    @media (max-width: $screen-md) {
                        transform: translateX(0);
                    }
                }

                &.model-item {
                }

                &.generation-item {
                    transform: translateX(-58px);

                    @media (max-width: $screen-md) {
                        transform: translateX(0);
                    }
                }

                @media (max-width: $screen-md) {
                    height: 25px;
                    width: 25px;
                }

                &.active {
                    background-color: $color-neutral-light !important;
                }
                .item-content-wrapper {
                    position: absolute;
                    bottom: 35px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 7px;

                    @media (max-width: $screen-md) {
                        bottom: 36px;
                        gap: 10px;
                    }
                }
                &__title {
                    white-space: nowrap;
                    font-size: rem(16px);
                    font-weight: 700;
                    text-transform: uppercase;
                    text-align: center;
                    color: $color-neutral-lighter;
                    transition: color 0.3s ease;
                    @media (max-width: $screen-md) {
                        font-size: 12px;
                    }
                    &.active {
                        color: $color-black;
                    }
                }
                &__selected {
                    font-size: rem(20px);
                    color: black;
                    font-weight: 500;
                    white-space: nowrap;
                    @media (max-width: $screen-md) {
                        font-size: 16px;
                        margin-bottom: -5px;
                    }
                }
                &__vertical-line {
                    height: 22px;
                    width: 2px;
                    background: $color-light;
                    transition: background 0.5s ease;
                    @media (max-width: $screen-md) {
                        height: 22px;
                    }
                    &.active {
                        background: $color-neutral-light !important;
                    }
                }
            }
        }
    }

    .results-box-container {
        padding: 0 20%;
        font-size: 16px;

        @media (max-width: $screen-md) {
            padding: 0 20px;
        }

        .results-header {
            .title {
                font-size: 32px;
                font-weight: 500;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                gap: 20px;

                @media (max-width: $screen-md) {
                    font-size: 24px;
                }

                button {
                    text-transform: capitalize;
                    background-color: transparent;
                    border: 0;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }

            .car-gen {
                font-size: 18px;
                color: $color-neutral-darker;
            }
        }

        .results-box {
            border: 1px solid $color-neutral-light;
            border-radius: 24px;
            padding: 20px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 30px;
            font-weight: 500;
            font-size: 24px;
            margin: 15px 0;

            @media (max-width: $screen-md) {
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
            }

            .category-container {
                display: flex;
                align-items: center;
                gap: 20px;
                width: 50%;
                flex-basis: calc(50% - 15px);
                max-width: calc(50% - 15px);

                @media (max-width: $screen-md) {
                    width: 100%;
                }

                .img-container {
                    border: 1px solid $color-neutral-light;
                    border-radius: 20px;
                    width: 80px;
                    height: 80px;
                    min-width: 80px;
                    min-height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .text-container {
                    @media (max-width: $screen-md) {
                        font-size: 20px;
                    }

                    .text-gradient {
                        font-weight: 600;
                    }
                }
            }
        }

        a {
            text-decoration: underline;
            color: $color-primary-default;
        }
    }
}
</style>
