<template>
  <div class="wrapper-supported-cars-all">
    <BreadCrumb />
    <div class="all-cars-container page">
      <Transition name="fade-slide" mode="out-in">
        <div key="loader" v-if="loading" class="supported-cars-all-loader">
          <Spinner />
          <p>{{ t("page-supported-cars-all_loading") }}</p>
        </div>
        <div key="container" v-else class="content-container">
          <p class="title">
            {{ t("page-supported-cars-all_all-supported-cars") }}
          </p>
          <div class="hero-and-navigation" ref="heroAndNavigation">
            <div class="hero-wrapper">
              <form @submit.prevent class="form">
                <button @click.prevent type="submit"><SearchIcon /></button>
                <input
                  type="text"
                  @keyup="handleSearch"
                  :placeholder="inputPlaceholder"
                />
                <span v-if="error" class="error hide-on-mobile" :class="{ visible: error }">{{
                  t("page-supported-cars-all_no-results-found")
                }}</span>
              </form>
              <span v-if="error" class="error show-on-mobile" :class="{ visible: error }">{{
                t("page-supported-cars-all_no-results-found")
              }}</span>
            </div>
            <ul class="navigation">
              <li
                v-for="(letter, index) in Object.entries(alphabet)"
                :key="letter + index"
              >
                <span
                  :class="{ selected: letter[0] === selectedLetter }"
                  @click="scrollToView(letter)"
                >
                  {{ letter[0] }}
                </span>
              </li>
            </ul>
          </div>
          <div
            ref="container"
            class="summary-container container"
          >
            <div
              v-for="(item, i) in summary"
              :key="i"
              class="item"
              :class="{ section: item.brandName }"
            >
              <span class="brand" :ref="item.brandRef">
                {{ item.brandName }}
              </span>
              <span class="model" :ref="item.modelRef?.id">
                {{
                  item.modelName && item.modelName.length > 28
                  ? item.modelName.substr(0, 28) + "..."
                  : item.modelName
                }}
              </span>
              <router-link
                class="generation"
                :to="`/${langCode}/supported-cars/${item.brandId}/${item.modelId}/${item.generationId}`"
              >
                {{ item.generationName }}<br />
                <span :ref="item.generationRef">
                  <span class="show-on-mobile">/</span> {{ item.startYear }}
                  {{ item.endYear ? ` - ${item.endYear}` : " +" }}
                </span>
              </router-link>
              <router-link
                class="features"
                :to="`/${langCode}/supported-cars/${item.brandId}/${item.modelId}/${item.generationId}`"
              >
                <span
                  v-for="(feature, indexChild) in item.features"
                  :key="feature.id"
                >
                  {{ feature.userString
                  }}<span v-if="indexChild != item.features.length - 1"
                    >,
                  </span>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount, watch, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import SearchIcon from '../assets/icons/SearchIcon';
import Spinner from '@/assets/icons/Spinner';
import { createMetaInfo } from '@/utils/helpers';
import { useRouter } from 'vue-router';
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';

const store = useStore();
const router = useRouter();
const { t } = useTranslation(['page-supported-cars-all']);
const instance = getCurrentInstance();
const loading = ref(false);
const error = ref(false);
const offsets = ref([]);
const container = ref(null);
const heroAndNavigation = ref(null);
const isMobile = computed(() => store.state.device.isMobile);

const inputPlaceholder = computed(() => t('page-supported-cars-all_search-for-your-car'));
const selectedLetter = computed(() => store.state.vehiclebrands.selectedLetter);
const alphabet = computed(() => store.state.vehiclebrands.alphabet);
const summary = computed(() => store.state.vehiclebrands.summary);
const langCode = computed(() => store.state.language.lang?.code);

onMounted(async () => {
  document.body.classList.remove("overflow-hidden");
  loading.value = true;
  await store.dispatch('vehiclebrands/getSummary');
  loading.value = false;
  getOffsets(alphabet.value);
  window.addEventListener("scroll", handleStickyElements);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleStickyElements);
});

watch(router.currentRoute, async () => {
  const langValue = router.currentRoute.value.params.lang;
  store.commit('vehiclebrands/selectedLetter', 'A');

  await store.dispatch('language/changeLanguage', { code: langValue });
  store.dispatch('vehiclebrands/getSummary');
});

const handleStickyElements = () => {
  handleScroll();
  if (window.top.scrollY > container.value.offsetTop - 100) {
    heroAndNavigation.value.classList.add('fixed');
  } else if (window.top.scrollY <= container.value.offsetTop - 100) {
    heroAndNavigation.value.classList.remove('fixed');
  }
};

// Getting offsets of each letter's section after component is mounted
const getOffsets = (data) => {
  setTimeout(() => {
    if (offsets.value.length) offsets.value = [];
    for (const [key, value] of Object.entries(data)) {
      const position = instance.refs[value][0].offsetTop - container.value.offsetTop;
      offsets.value.push({ letter: key, position: position });
    }
  }, 600);
};

const scrollToView = (letter) => {
  if (!letter[1]) return;
  store.commit("vehiclebrands/selectedLetter", letter[0]);
  const heroAndNavigationHeight = heroAndNavigation.value.offsetHeight;
  let y = instance.refs[letter[1]][0].parentElement.offsetTop - heroAndNavigationHeight - 100;
  if (isMobile.value) {
    y = instance.refs[letter[1]][0].parentElement.offsetTop - heroAndNavigationHeight - 70;
  }
  if (!heroAndNavigation.value.classList.contains('fixed')) {
    y -= heroAndNavigationHeight;
  }
  window.scrollTo({ top: y, behavior: "smooth" });
};

const showResult = async (value) => {
  error.value = false;
  let showError = true;
  for (let i = 0; i < summary.value.length; i++) {
    const item = summary.value[i];
    if (
      item.searchKeyword
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase())
    ) {
      const heroAndNavigationHeight = heroAndNavigation.value.offsetHeight;
      let y =
        instance.refs[item.generationRef][0].parentElement.offsetTop
        - heroAndNavigationHeight - 125;
      if (isMobile.value) {
        y = instance.refs[item.generationRef][0].parentElement.parentElement.offsetTop
        - heroAndNavigationHeight - 70;
      }
      if (!heroAndNavigation.value.classList.contains('fixed')) {
        y -= heroAndNavigationHeight;
      }

      window.scrollTo({ top: y, behavior: "smooth" });
      store.commit("vehiclebrands/selectedLetter", item.letter);
      showError = false;
      return false;
    }
  }
  if (showError) error.value = true;
};

const handleSearch = _.debounce((e) => {
  showResult(e.target.value);
}, 600);

const handleScroll = _.debounce(() => {
  offsets.value.forEach((item) => {
    if (window.scrollY > item.position - 100)
      store.commit("vehiclebrands/selectedLetter", item.letter);
  });
}, 600);

useHead(() => {
  return createMetaInfo({
    title: t("page-supported-cars-all_all-supported-cars"),
  });
});
</script>
