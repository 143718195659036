<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4269 19.5492C11.5206 19.5492 9.15234 17.181 9.15234 14.2746C9.15234 11.3682 11.5206 9 14.427 9C17.3333 9 19.7016 11.3682 19.7016 14.2746C19.7016 17.181 17.3333 19.5492 14.4269 19.5492ZM14.4269 21.5492C10.416 21.5492 7.15234 18.2856 7.15234 14.2746C7.15234 10.2637 10.416 7 14.427 7C18.4379 7 21.7016 10.2637 21.7016 14.2746C21.7016 15.9206 21.152 17.4407 20.2266 18.6611L25.0057 23.4401L23.5914 24.8543L18.8123 20.0751C17.5921 20.9999 16.0724 21.5492 14.4269 21.5492Z"
        fill="#9197A0"
      />
    </g>
  </svg>
</template>
