<template>
  <Tooltip>
    <template #base="{ toggle }">
      <button @click="toggle">
        <ThreeDotsIcon />
      </button>
    </template>
    <template #tooltip="{ close }">
      <ul>
        <li v-for="item in dropdownItems">
          <router-link :to="{ name: item.meta.breadcrumb || item.name }">
            <span>
              {{ t(`breadcrumb_` + item.meta.breadcrumb) }}
            </span>
          </router-link>
        </li>
      </ul>
    </template>
  </Tooltip>
</template>

<script setup>
import { ref } from 'vue';
import { useTranslation } from "i18next-vue";
import Tooltip from '../Tooltip.vue';
import ThreeDotsIcon from '@/assets/icons/ThreeDotsIcon.vue';

const { t } = useTranslation(['breadcrumb']);

const props = defineProps({
  dropdownItems: Array
});
</script>
