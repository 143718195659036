<template>
  <div>
    <transition name="fade" mode="out-in">
      <div class="default-page error-page">
        <div class="page">
          <div class="container">
            <h1 class="title">404</h1>
            <p>{{ t("shared_page-not-found") }}</p>
            <button
              @click="$router.push({ name: 'home' })"
              class="btn-feedback-outlined lighter"
            >
              {{ t("shared_go-to-homepage") }} <ArrowRightIcon />
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import ArrowRightIcon from '../assets/icons/supportedCars/ArrowRightIcon';
import { useTranslation } from "i18next-vue";

document.body.classList.remove("overflow-hidden");

const { t } = useTranslation(['shared']);
</script>
