<template>
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5622 14.9085L22.3277 8.14297L21.5319 7.34711L14.7663 14.1127L8.00094 7.34729L7.20508 8.14315L13.9704 14.9085L7.20526 21.6737L8.00112 22.4696L14.7663 15.7044L21.5317 22.4697L22.3275 21.6739L15.5622 14.9085Z"
      fill="#383D45"
    />
  </svg>
</template>
