<template>
  <section class="container">
    <div class="homepage__section homepage__tools">
      <div class="content" :class="{'active': isActive}">
        <div class="left">
          <h2 :class="{'text-gray': changeColor}">
            {{ tools?.headings?.h2[0] }}
            <span class="text-gradient" :class="{'samsung-bg-gradient':isSamsungBrowser}">
              {{ tools?.headings?.h2[1] }}
            </span>
            {{ tools?.headings?.h2[2] }}
          </h2>
        </div>
        <div class="right">
          <div>
            <CustomizeIcon class="svg-container"/>
            <h3 class="title" :class="{'text-gray': changeColor}">{{ tools?.headings?.h3[0] }}</h3>
            <span>
              {{ t('page-home_customize-content') }}
            </span>
          </div>
          <div>
            <DiagnoseIcon class="svg-container diagnose"/>
            <h3 class="title" :class="{'text-gray': changeColor}">{{ tools?.headings?.h3[1] }}</h3>
            <span>
              {{ t('page-home_diagnose-content') }}
            </span>
          </div>
          <div>
            <ServiceIcon class="svg-container"/>
            <h3 class="title" :class="{'text-gray': changeColor}">{{ tools?.headings?.h3[2] }}</h3>
            <span>
              {{ t('page-home_service-content') }}
            </span>
          </div>
          <div>
            <LiveDataIcon class="svg-container"/>
            <h3 class="title" :class="{'text-gray': changeColor}">{{ tools?.headings?.h3[3] }}</h3>
            <span>
              {{ t('page-home_live-data-content') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import CustomizeIcon from '../../assets/icons/supportedCars/CustomizeIcon.vue';
import DiagnoseIcon from '../../assets/icons/supportedCars/DiagnoseIcon.vue';
import ServiceIcon from '../../assets/icons/supportedCars/ServiceIcon.vue';
import LiveDataIcon from '../../assets/icons/supportedCars/LiveDataIcon.vue';
import { detectSamsungBrowser } from '@/utils/helpers';

const { t } = useTranslation(['page-home']);

const props = defineProps({
  active: Number,
});

const store = useStore();

const tools = computed(() => store.state.pages.tools);

const isActive = computed(() => props.active >= 2);
const changeColor = computed(() => props.active > 2);

const isSamsungBrowser = ref(detectSamsungBrowser());
</script>
