<template>
    <div class="embark">
        <div class="container">
            <div class="content">
                <h4 v-if="title && title.length" v-html="title" class="title">
                </h4>
                <h4 v-else class="title">
                    {{ t('shared_embark-on-your-carista-journey-today-1') }}
                    <span
                        class="text-gradient"
                        :class="{ 'samsung-bg-gradient': isSamsungBrowser }"
                        >{{
                            t('shared_embark-on-your-carista-journey-today-2')
                        }}</span
                    ><br />
                    {{ t('shared_embark-on-your-carista-journey-today-3') }}
                </h4>
                <GetStarted
                    :shouldOpenVendorModal
                    :to="to ? to : `/${langCode}/supported-cars`"
                    :title="buttonTitle"
                    :subtitle="buttonSubTitle"
                    :fbEventParam="fbEventParam"
                    :fbEvent="fbEvent"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from 'i18next-vue';
import GetStarted from '../components/GetStarted.vue';
import { detectSamsungBrowser, facebookGoogleAnalitycs } from '@/utils/helpers';

const { t } = useTranslation(['shared']);

const props = defineProps({
    title: {
        type: String,
    },
    buttonTitle: {
        type: String,
        default: 'Get started',
    },
    buttonSubTitle: {
        type: String,
        default: 'Check what we support for your car',
    },
    shouldOpenVendorModal: {
        type: Boolean,
        default: false,
    },
    to: [String, Object],
    fbEvent: String,
    fbEventParam: String,
});

const store = useStore();

const langCode = computed(() => store.state.language.lang?.code);

const gradientSpanStyle = computed(() => {
	if (!props.title || !props.title.length) {
		return {};
	}

	const shouldDisplayInline = props.title[1]?.toLowerCase() === 'free' || props.title[1]?.toLowerCase() === 'evolve'

    return { display: shouldDisplayInline ? 'inline' : 'block' };
});

const isSamsungBrowser = ref(detectSamsungBrowser());
</script>
