<template>
  <div class="embark">
    <div class="container">
      <div class="content">
        <h4 v-if="title" class="title">
          {{ title[0] }}
          <span
            class="text-gradient"
            :class="{ 'samsung-bg-gradient': isSamsungBrowser }"
            >{{ title[1] }}</span
          >
          {{ title[2] }}
        </h4>
        <h4 v-else class="title">
          {{ t("shared_embark-on-your-carista-journey-today-1") }}
          <span
            class="text-gradient"
            :class="{ 'samsung-bg-gradient': isSamsungBrowser }"
            >{{ t("shared_embark-on-your-carista-journey-today-2") }}</span
          ><br />
          {{ t("shared_embark-on-your-carista-journey-today-3") }}
        </h4>
        <GetStarted
          :to="to ? to : `/${langCode}/supported-cars`"
          :title="btnTitle"
          :subtitle="btnSubtitle"
          :fbEventParam="fbEventParam"
          :fbEvent="fbEvent"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import GetStarted from "../components/GetStarted.vue";
import { detectSamsungBrowser, facebookGoogleAnalitycs } from "@/utils/helpers";

const { t } = useTranslation(['shared']);

const props = defineProps({
  title: Array,
  btnTitle: String,
  btnSubtitle: String,
  to: [String, Object],
  fbEvent: String,
  fbEventParam: String,
});

const store = useStore();

const langCode = computed(() => store.state.language.lang?.code);

const isSamsungBrowser = ref(detectSamsungBrowser());
</script>
