<template>
  <div v-if="contactUs" class="contact-us-page">
    <BreadCrumb></BreadCrumb>
    <div class="page">
      <div class="container container-hero">
        <h1>{{ contactUs?.headings.h1[0] }}</h1>
        <h2>{{ contactUs?.headings.h2[0] }}</h2>
        <h3>{{ contactUs?.headings.h3[0] }}</h3>
      </div>
    </div>
    <Faq :active="7" :static="true" :faqList="contactUsFaq" :title="contactUs?.headings?.h4[0]" />
    <div class="contact-form-section">
      <div class="container">
        <div class="form-container">
          <h2>{{ contactUs?.headings.h2[1] }}</h2>
          <form
            @submit.prevent
          >
            <div class="form-field-container">
              <input
                id="name-input"
                type="text"
                :placeholder="t('page-contact-us_form-name-placeholder') + '*'"
                v-model="name"
                :class="{ 'error': showError.includes('name') }"
                @focus="showError.filter((item) => item !== 'name')"
              />
              <div v-if="showError.includes('name')" class="error-msg-container">
                <div class="error-msg">*{{ t("page-contact-us_enter-first-and-last-name") }}</div>
              </div>
            </div>
            <div class="form-field-container">
              <div class="category-dropdown-wrapper" v-click-outside="handleOutsideClick">
                <ul class="category-dropdown" :class="{ 'opened': categoryDropdownOpen }">
                  <li
                    class="placeholder"
                    :class="{ 'selected': selectedCategory, 'error': showError.includes('category') }"
                    @click="toggleCategoryDropdown"
                  >
                    {{ placeholderText }}* <ArrowIcon />
                  </li>
                  <li
                    v-if="categoryDropdownOpen"
                    @click="selectCategory('support')"
                    :class="{ 'highlight': selectedCategory === 'support' }"
                  >{{ t('page-contact-us_form-dropdown-support') }}</li>
                  <li
                    v-if="categoryDropdownOpen"
                    @click="selectCategory('partnerships')"
                    :class="{ 'highlight': selectedCategory === 'partnerships' }"
                  >{{ t('page-contact-us_form-dropdown-partnerships') }}</li>
                  <li
                    v-if="categoryDropdownOpen"
                    @click="selectCategory('marketing')"
                    :class="{ 'highlight': selectedCategory === 'marketing' }"
                  >{{ t('page-contact-us_form-dropdown-marketing') }}</li>
                  <li
                    v-if="categoryDropdownOpen"
                    @click="selectCategory('other')"
                    :class="{ 'highlight': selectedCategory === 'other' }"
                  >{{ t('page-contact-us_form-dropdown-other') }}</li>
                </ul>
              </div>
              <div v-if="showError.includes('category')" class="error-msg-container">
                <div class="error-msg">
                  *{{ t("page-contact-us_select-an-option-from-dropdown-menu") }}
                </div>
              </div>
            </div>
            <div class="form-field-container">
              <input
                id="subject-input"
                type="text"
                :placeholder="t('page-contact-us_form-subject-placeholder') + '*'"
                v-model="subject"
                :class="{ 'error': showError.includes('subject') }"
                @focus="showError.filter((item) => item !== 'subject')"
              />
              <div v-if="showError.includes('subject')" class="error-msg-container">
                <div class="error-msg">*{{ t("page-contact-us_enter-subject") }}</div>
              </div>
            </div>
            <div class="form-field-container">
              <input
                id="email-input"
                type="email"
                :placeholder="t('page-contact-us_form-email-placeholder') + '*'"
                v-model="email"
                :class="{ 'error': showError.includes('email') }"
              />
              <div v-if="showError.includes('email')" class="error-msg-container">
                <div class="error-msg">*{{ t("menus_enter-valid-email") }}</div>
              </div>
            </div>
            <div class="form-field-container">
              <textarea
                cols="30"
                rows="1"
                :placeholder="t('page-contact-us_form-message-placeholder') + '*'"
                @input="expandTextarea"
                v-model="message"
                :class="{ 'error': showError.includes('message') }"
              ></textarea>
              <div v-if="showError.includes('message')" class="error-msg-container">
                <div class="error-msg">*{{ t("page-contact-us_enter-message") }}</div>
              </div>
            </div>
            <button
              :disabled="loading"
              class="btn-feedback-outlined"
              @click.prevent="sendContactUsMessage"
              type="submit"
            >
              {{ t('page-contact-us_form-send-btn') }}
              <ArrowRightIcon />
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="contact-info">
      <div class="img-container">
        <img
          :src="contactUs?.images[0].url"
          :alt="contactUs?.images[0].imageMeta.metaAltDescription"
          :title="contactUs?.images[0].imageMeta.metaTitle"
          loading="lazy"
        />
      </div>
      <div class="contacts-container">
        <div class="contact address">
          <div class="title">{{ t("page-contact-us_address") }}</div>
          <div class="info-container">1715 Knyaz Boris I 147, <br />Sofia, Bulgaria</div>
        </div>
        <div class="contact email">
          <div class="title">{{ t("page-contact-us_contacts") }}</div>
          <div class="info-container">support@caristaapp.com</div>
        </div>
        <div class="contact socials">
          <div class="title">{{ t("page-contact-us_socials") }}</div>
          <div class="info-container">
            <div>
              <a
                v-if="socialsLinkLinkedin"
                :href="socialsLinkLinkedin"
                rel="nofollow"
                target="_blank"
                @click="
                  facebookGoogleAnalitycs('social_media_click', {
                    channel: 'linkedin',
                    button_position: 'contact us page',
                  })
                "
              >
                <ArrowRightIcon />Linkedin
              </a>
            </div>
            <div>
              <a
                v-if="socialsLinkFacebook"
                :href="socialsLinkFacebook"
                rel="nofollow"
                target="_blank"
                @click="
                  facebookGoogleAnalitycs('social_media_click', {
                    channel: 'facebook',
                    button_position: 'contact us page',
                  })
                "
              >
                <ArrowRightIcon />Facebook
              </a>
            </div>
            <div>
              <a
                v-if="socialsLinkInstagram"
                :href="socialsLinkInstagram"
                rel="nofollow"
                target="_blank"
                @click="
                  facebookGoogleAnalitycs('social_media_click', {
                    channel: 'instagram',
                    button_position: 'contact us page',
                  })
                "
              >
                <ArrowRightIcon />Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Embark
      :title="[
        contactUs?.headings?.h4[1],
        contactUs?.headings?.h4[2],
        contactUs?.headings?.h4[3],
      ]"
      :btnSubtitle="t('shared_check-what-we-support-for-your-car')"
      fbEventParam="contact us page"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount } from "vue";
import { VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3";
import { useStore } from "vuex";
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import Faq from "../components/Faq";
import Embark from "../components/Embark";
import { createMetaInfo } from "@/utils/helpers";
import ArrowRightIcon from "../assets/icons/supportedCars/ArrowRightIcon";
import ArrowIcon from "@/assets/icons/ArrowIcon";
import { facebookGoogleAnalitycs, getMobileOS } from "@/utils/helpers";
import {
  SOCIALS_LINK_FACEBOOK,
  SOCIALS_LINK_LINKEDIN,
  SOCIALS_LINK_INSTAGRAM,
  FAQ_CATEGORY_ID,
} from "@/utils/constants";
import http from "@/services/http.service";
import app from "../main";
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';

const { t } = useTranslation(['page-contact-us', 'shared', 'translation', 'menus']);

app.use(VueReCaptcha, { siteKey: `${process.env.VUE_APP_CONTACT_US_CAPTCHA_SITEKEY}` });
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

const store = useStore();

const socialsLinkFacebook = SOCIALS_LINK_FACEBOOK;
const socialsLinkLinkedin = SOCIALS_LINK_LINKEDIN;
const socialsLinkInstagram = SOCIALS_LINK_INSTAGRAM;
const categoryDropdownOpen = ref(false);
const selectedCategory = ref(null);
const name = ref(null);
const subject = ref(null);
const email = ref(null);
const message = ref(null);
const showError = ref([]);
const loading = ref(false);
const mobileOS = getMobileOS();

const contactUsFaqCategory = FAQ_CATEGORY_ID.CONTACT_US_PAGE;
const contactUsFaq = computed(() => store.state.faq?.faq[contactUsFaqCategory - 1]);
const contactUs = computed(() => store.state.pages?.contact_us);
const langCode = computed(() => store.state.language.lang?.code);

const placeholderText = computed(() => {
  return selectedCategory.value === "support" ? t('page-contact-us_form-dropdown-support')
      : selectedCategory.value === "partnerships" ? t('page-contact-us_form-dropdown-partnerships')
      : selectedCategory.value === "marketing" ? t('page-contact-us_form-dropdown-marketing')
      : selectedCategory.value === "other" ? t('page-contact-us_form-dropdown-other')
      : t('page-contact-us_form-category-placeholder');
});

async function sendContactUsMessage() {
  showError.value = [];
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  // at least one English, Japanese or Russian letter or number
  const inputFormat = /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤ヶ]+|[ЁёА-я]/u;

  if (name.value === null || !name.value.match(inputFormat)) {
    showError.value.push("name");
  } else {
    showError.value.filter((item) => item !== "name");
  }
  if (selectedCategory.value === null) {
    showError.value.push("category");
  } else {
    showError.value.filter((item) => item !== "category");
  }
  if (subject.value === null || !subject.value.match(inputFormat) || subject.value.length > 60) {
    showError.value.push("subject");
  } else {
    showError.value.filter((item) => item !== "subject");
  }
  if (email.value === null || !email.value.match(mailFormat)) {
    showError.value.push("email");
  } else {
    showError.value.filter((item) => item !== "email");
  }
  if (message.value === null || message.value === "") {
    showError.value.push("message");
  } else {
    showError.value.filter((item) => item !== "message");
  }

  if (showError.value.length) {
    openNotificationModal({
      showSuccess: false,
      titleTxt: t("shared_sending-failed"),
      descriptionTxt: t("shared_sending-failed-try-again"),
      btnTxt: t('shared_try-again')
    });

    return;
  }

  loading.value = true;

  // (optional) Wait until recaptcha has been loaded.
  await recaptchaLoaded();

  // Execute reCAPTCHA with action "login".
  const token = await executeRecaptcha('login');

  http.post(process.env.VUE_APP_CONTACT_US_FORM_API,
    {
      "name": name.value,
      "category": selectedCategory.value,
      "subject": subject.value,
      "email": email.value,
      "message": message.value,
      "recaptcha-token": token,
      "mobile-os": mobileOS
    },
    {
      headers: {
        Accept: "application/json",
      },
    },
  ).then((response) => {
    if (response.status >= 400) {
      if (response.error?.name?.length > 0) {
        showError.value.push("name");
      }
      if (response.error?.category?.length > 0) {
        showError.value.push("category");
      }
      if (response.error?.subject?.length > 0) {
        showError.value.push("subject");
      }
      if (response.error?.email?.length > 0) {
        showError.value.push("email");
      }
      if (response.error?.message?.length > 0) {
        showError.value.push("message");
      }

      openNotificationModal({
        showSuccess: false,
        titleTxt: t("shared_sending-failed"),
        descriptionTxt: t("shared_sending-failed-try-again"),
        btnTxt: t('shared_try-again')
      });
    }

    if (response.status >= 200 && response.status < 300) {
      name.value = null;
      selectedCategory.value = null;
      subject.value = null;
      email.value = null;
      message.value = null;

      openNotificationModal({
        showSuccess: true,
        titleTxt: t("shared_thank-you"),
        descriptionTxt: t("shared_message-sent"),
        btnTxt: t('translation_ok')
      });
    }
  })
  .catch((error) => {
    if (error.response.status >= 400 || error.status >= 400) {
      if (error?.name?.length > 0) {
        showError.value.push("name");
      }
      if (error?.category?.length > 0) {
        showError.value.push("category");
      }
      if (error?.subject?.length > 0) {
        showError.value.push("subject");
      }
      if (error?.email?.length > 0) {
        showError.value.push("email");
      }
      if (error?.message?.length > 0) {
        showError.value.push("message");
      }

      openNotificationModal({
        showSuccess: false,
        titleTxt: t("shared_sending-failed"),
        descriptionTxt: t("shared_sending-failed-try-again"),
        btnTxt: t('shared_try-again')
      });
    }
  })
  .finally(() => {
    loading.value = false;
  });
  return;
}

function expandTextarea(e) {
  e.target.style.height = "";
  const height = e.target.scrollHeight + 1;
  e.target.style.height = height + "px";
}

function toggleCategoryDropdown() {
  categoryDropdownOpen.value = !categoryDropdownOpen.value;
}

function handleOutsideClick() {
  categoryDropdownOpen.value = false;
}

function selectCategory(category) {
  categoryDropdownOpen.value = false;
  selectedCategory.value = category;
  showError.value = showError.value.filter((item) => item !== "category");
}

function openNotificationModal(option) {
  store.dispatch("modals/openModal", {
    currentModal: "isNotificationModalOpen",
    data: option,
  });
}

onBeforeMount(async () => {
  if (
    !contactUsFaq.value ||
    (contactUsFaq.value &&
      mapLangFullNameToCode(contactUsFaq?.value[0].language) !== langCode.value)
  ) {
    await store.dispatch("faq/getFaq", { lang: langCode.value, categoryId: contactUsFaqCategory });
  }
});

watch(langCode, (newVal) => {
  store.dispatch("faq/getFaq", { lang: newVal, categoryId: contactUsFaqCategory });
});
document.body.classList.remove("overflow-hidden");

// Custom directive for click outside
const vClickOutside = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  beforeUnmount: (el) => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
};

useHead(() => {
  return createMetaInfo({
    title: contactUs.value?.metaTitle,
    pageMeta: contactUs.value,
  });
});
</script>
