import axios from "./http.service";

class GeoIP {
  getIP() {
    const query = {
        query: `
        {
            geoIpLocation {
                countryCode
                isInEu
                showCookies
                result {
                    message
                    status
                }
            }
        }`,
    };

    return axios
      .post(`${process.env.VUE_APP_WEB_CMS}`, query)
      .then((res) => res.data);
  }
}

export default new GeoIP();
