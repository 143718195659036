import Blog from "@/services/blog.service";

export default {
  namespaced: true,
  state: {
    initialBlogData: null,
    allArticles: null,
    openedArticle: null,
    articlesByTag: null,
    allTags: null,
  },
  mutations: {
    initialBlogData(state, payload) {
        state.initialBlogData = payload;
    },
    allArticles(state, payload) {
        state.allArticles = payload;
    },
    openedArticle(state, payload) {
        state.openedArticle = payload;
    },
    articlesByTag(state, payload) {
        state.articlesByTag = payload;
    },
    allTags(state, payload) {
        state.allTags = payload;
    },
  },
  actions: {
    // this action getAllTags is for the tags translations
    getAllTags({ commit, state }) {
        return Blog.getAllTags().then(({ data }) => {
            const allTags = data.allTags.tags;
            const initialBlogData = state.initialBlogData;

            for (let i = 0; i < initialBlogData.allArticles.articles.length; i++) {
                const article = initialBlogData.allArticles.articles[i];
                const tagId = article.tags[0].id;
                article.tags[0].name = allTags[tagId - 1].name;
            }
            const latestArticleTagId = initialBlogData.latestArticle.article.tags[0].id;
            initialBlogData.latestArticle.article.tags[0].name = allTags[latestArticleTagId-1].name;

            const allArticles = state.allArticles;
            if (allArticles) {
                for (let i = 0; i < allArticles.articles.length; i++) {
                    const article = allArticles.articles[i];
                    const tagId = article.tags[0].id;
                    article.tags[0].name = allTags[tagId - 1].name;
                }
            }

            const articlesByTag = state.articlesByTag;
            if (articlesByTag) {
                for (let i = 0; i < articlesByTag.articlesByTag.articles.length; i++) {
                    const article = articlesByTag.articlesByTag.articles[i];
                    const tagId = article.tags[0].id;
                    article.tags[0].name = allTags[tagId - 1].name;
                }
            }

            commit("initialBlogData", initialBlogData);
            commit("allTags", data.allTags);
            commit("allArticles", allArticles);
            commit("articlesByTag", articlesByTag);
        });
    },
    getInitialBlogData({ commit }, pageData) {
        return Blog.getInitialBlogData(pageData?.page, pageData?.itemsPerPage).then(({ data }) => {

            // logic for tags translations
            const latestArticleTagId = data.latestArticle.article.tags[0].id;
            const allTags = data.allTags.tags;
            const latestArticleTagName = allTags[latestArticleTagId-1].name;

            for (let i = 0; i < data.allArticles.articles.length; i++) {
                const article = data.allArticles.articles[i];
                const tagId = article.tags[0].id;
                article.tags[0].name = allTags[tagId - 1].name;
            }

            const initialBlogData = {
                ...data,
                latestArticle: {
                    ...data.latestArticle,
                    article: {
                        ...data.latestArticle.article,
                        tags: [
                            {
                                ...data.latestArticle.article.tags[0],
                                name: latestArticleTagName
                            }
                        ]
                    }
                },
            };
            // logic for tags translations end

            commit("initialBlogData", initialBlogData);
            commit("allTags", data.allTags);
            commit("allArticles", data.allArticles);
        });
    },
    getAllArticles({ commit, state }, pageData) {
        return Blog.getAllArticles(pageData?.page, pageData?.itemsPerPage).then(({ data }) => {
            // logic for tags translations
            const allTags = state.allTags.tags;

            for (let i = 0; i < data.allArticles.articles.length; i++) {
                const article = data.allArticles.articles[i];
                const tagId = article.tags[0].id;
                article.tags[0].name = allTags[tagId - 1]?.name || article.tags[0].name;
            }
            // logic for tags translations end

            commit("allArticles", data.allArticles);
        });
    },
    getArticleById({ commit }, id) {
        return Blog.getArticleById(id).then(({ data }) => {
            commit("openedArticle", data.articleById.article);
        });
    },
    getArticleBySlug({ commit, state }, slug) {
        return Blog.getArticleBySlug(slug).then(({ data }) => {
            if (data.articleBySlug.article) {
                // start logic for tags translations
                const allTags = state.allTags.tags;

                const article = data.articleBySlug.article;
                const tagId = article?.tags[0].id;
                article.tags[0].name = allTags[tagId - 1]?.name || article.tags[0].name;
                // end logic for tags translations

                commit("openedArticle", data.articleBySlug.article);
            }
        });
    },
    getArticlesByTag({ commit, state }, pageData) {
        return Blog.getArticlesByTag(pageData?.tagId, pageData?.page, pageData?.itemsPerPage).then(({ data }) => {

            // logic for tags translations
            const allTags = state.allTags.tags;

            for (let i = 0; i < data.articlesByTag.articles.length; i++) {
                const article = data.articlesByTag.articles[i];
                const tagId = article.tags[0].id;
                article.tags[0].name = allTags[tagId - 1]?.name || article.tags[0].name;
            }
            // logic for tags translations end

            if (pageData?.relatedArticles) {
                const relatedArticles = data.articlesByTag.articles.filter(article => article.id !== state.openedArticle.id);
                if (relatedArticles.length > 3) relatedArticles.pop();

                const payload = {
                    ...state.openedArticle,
                    relatedArticles: relatedArticles,
                };

                commit("openedArticle", payload);
            } else {
                commit("articlesByTag", data);
            }
        });
    },
  },
};
