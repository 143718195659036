<template>
    <div class="results-box-container">
        <div class="results-header">
            <div class="title">
                {{ t('page-pricing_caristas-features-for-your-car') }}
                <button class="search-box__reset-btn" @click="reset">
                    <ResetIcon /> {{ t('page-supported-cars_reset') }}
                </button>
            </div>
            <div class="car-gen">
                {{
                    `${selectedVehicle.make?.title}
                ${selectedVehicle.model?.title}
                ${selectedVehicle.generation?.title}`
                }}
            </div>
        </div>
        <div class="results-box">
            <VehicleFeatureItem
                v-for="(feature, index) in vehicleFeatures"
                :key="`car-feat-${index}`"
                :icon="feature.icon"
                :text="feature.text"
                :title="feature.title"
                :htmlTitle="feature.htmlTitle"
            ></VehicleFeatureItem>
        </div>
        <div @click="handleClick" v-html="exploreFeaturesLink"></div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTranslation } from 'i18next-vue';
import CustomizeIcon from '@/assets/icons/supportedCars/CustomizeIcon.vue';
import DiagnoseIcon from '@/assets/icons/supportedCars/DiagnoseIcon.vue';
import ServiceIcon from '@/assets/icons/supportedCars/ServiceIcon.vue';
import LiveDataIcon from '@/assets/icons/supportedCars/LiveDataIcon.vue';
import ResetIcon from '@/assets/icons/ResetIcon';
import VehicleFeatureItem from './VehicleFeatureItem.vue';
import { formatPhraseWithHtml, facebookGoogleAnalitycs } from '@/utils/helpers';
import { EVO_PRICING_PAGE_ROUTE } from '@/utils/constants';

const countVehicleFeaturesInCategory = (category) => {
    if (!Array.isArray(category)) {
        return 0;
    }

    return category.reduce((accumulator, feature) => {
        return Array.isArray(feature.settings)
            ? accumulator + feature.settings.length
            : accumulator;
    }, 0);
};

const route = useRoute();
const store = useStore();
const { t } = useTranslation(['shared', 'page-pricing', 'page-supported-cars']);

const baseUrl = process.env.VUE_APP_APP;

const langCode = computed(() => store.state.language.lang?.code);

const dynamicVehiclesData = computed(
    () => store.state.vehiclebrands.dynamicVehiclesData
);

const vehicleFeatures = computed(() => {
    const allFeatures = dynamicVehiclesData?.value?.features;

    return [
        {
            icon: CustomizeIcon,
            title: t('shared_customizations'),
            text: countVehicleFeaturesInCategory(allFeatures.customize),
        },
        {
            icon: DiagnoseIcon,
            title: diagnozeTitleFormatted.value,
            htmlTitle: true,
        },
        {
            icon: ServiceIcon,
            title: t('shared_service-tools'),
            text: allFeatures.service?.length || 0,
        },
        {
            icon: LiveDataIcon,
            title: t('shared_live-data'),
            text: allFeatures.liveData?.length || 0,
        },
    ];
});

const selectedVehicle = computed(
    () => dynamicVehiclesData?.value?.selector ?? {}
);

const diagnozeTitleFormatted = computed(() => {
    return formatPhraseWithHtml(
        t('shared_complete_diagnostics'),
        t('shared_complete_diagnostics_gradient'),
        'div',
        'text-gradient'
    );
});

const exploreFeaturesLink = computed(() => {
    const linkHref = `${baseUrl}/${langCode.value}/${selectedVehicle.value.make?.id}/${selectedVehicle.value.model?.id}/${selectedVehicle.value.generation?.id}`;

    return formatPhraseWithHtml(
        t('shared_explore-the-features-in-detail-here-1'),
        t('shared_explore-the-features-in-detail-here-2'),
        'a',
        '',
        `href="${linkHref}" target="_blank"`
    );
});

const reset = () => {
    store.dispatch('vehiclebrands/getDynamicVehiclesData', {});
};

const handleClick = (event) => {
    const isLinkClicked = event.target.tagName == 'A';
    if (isLinkClicked) {
        const eventName =
            route.name === EVO_PRICING_PAGE_ROUTE
                ? 'here_click_evo_pricing'
                : 'here_click_pricing';
        facebookGoogleAnalitycs(eventName);
    }
};
</script>

<style lang="scss">
.search-box__reset-btn {
    @media (max-width: $screen-md) {
        white-space: nowrap;
    }
}
</style>
