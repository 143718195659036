<template>
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1172 3.42969C12.9766 2.86719 12.5312 2.42188 11.9922 2.28125C10.9844 2 7 2 7 2C7 2 2.99219 2 1.98438 2.28125C1.44531 2.42188 1 2.86719 0.859375 3.42969C0.578125 4.41406 0.578125 6.52344 0.578125 6.52344C0.578125 6.52344 0.578125 8.60938 0.859375 9.61719C1 10.1797 1.44531 10.6016 1.98438 10.7422C2.99219 11 7 11 7 11C7 11 10.9844 11 11.9922 10.7422C12.5312 10.6016 12.9766 10.1797 13.1172 9.61719C13.3984 8.60938 13.3984 6.52344 13.3984 6.52344C13.3984 6.52344 13.3984 4.41406 13.1172 3.42969ZM5.6875 8.42188V4.625L9.01562 6.52344L5.6875 8.42188Z"
      fill="white"
    />
  </svg>
</template>
