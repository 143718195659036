import axios from "./http.service";
import appConfig from "@/config/app.config";

class Prices {
  getPrices(countryCode) {
    // Default code 00 should be static, If there are 2 results, it will take this with the country code.
    // Prices should come only from siteId 1
    const query = {
      query: `
      {
        countryByCountryCode(input: {countryCode: "${countryCode}", environment: PRODUCTION}) {
          country {
            adapterPrice
            appPricePerMonth
            appPricePerThreeMonths
            appPricePerYear
            countryCode
            countryName
            currency
            evoAdapterPrice
            id
            vendors {
              evoSiteUrl
              id
              image
              siteName
              siteUrl
            }
          }
          result {
            message
            status
          }
        }
      }`,
    };

    return axios
      .post(`${process.env.VUE_APP_WEB_CMS}`, query)
      .then((res) => res.data);
  }
}

export default new Prices();
