<template>
  <div
    class="content-item adapter"
    :class="[
      type,
      { 'border': type === 'evo' },
    ]"
  >
    <div class="image-and-title-container" >
      <div class="image-holder">
        <img
          :class="{ 'evo-img': type === 'evo', 'obd-img': type === 'obd' }"
          :src="imgSrc"
          :alt="imgAlt"
          :title="imgTitle"
          loading="lazy"
        />
      </div>
      <div>
        <h2 class="title">{{ title }}</h2>
        <p
          class="price"
        >{{ prices.currency }}{{ price }}</p>
        <div v-if="type === 'evo' && isInUK && $appConfig.saleEnabled">
          <a :href="saleTagLink" target=”_blank>
            <SaleTag :text="t('shared_free-diagnostics')"  />
          </a>
          <a
          @click="facebookGoogleAnalitycs('special_offer_evo','');"
          :href="saleTagLink"
          class="offer-details-link"
          target="_blank"
        >
          {{ t('shared_special-offer-details') }}
        </a>
        </div>
      </div>
    </div>
    <ActionButton
        @click="handleClick"
        :customText="t('shared_buy-now')"
        :isLink="isSingleVendor(type)"
        :linkUrl="isSingleVendor ? getSiteUrl(type, prices.vendors) : ''"
        :isHighlighted="type === 'evo'"
        :width="'100%'"
        class="action-button"
        linkTarget="_blank"
    />
    <div class="brands-container">
      <div class="brands-title">{{ t("shared_supported-brands") }}</div>
      <ul class="brands-list">
        <template
          v-for="brand in brands"
          :key="brand.id"
        >
          <li
            v-if="
              type === 'evo' ||
              (brand.id !== 'ford' && brand.id !== 'lincoln' && brand.id !== 'land-rover')
            "
          >
            <component :is="defineAsyncComponent(Icons.getIcon(brand.title + 'Logo'))"></component>
            <span
              v-if="
                brand.id === 'audi' ||
                brand.id === 'cupra' ||
                brand.id === 'seat' ||
                brand.id === 'skoda' ||
                brand.id === 'vw'
              "
            >*</span>
          </li>
        </template>
      </ul>
      <div>{{
        type === 'evo'
        ? t("page-pricing_compatible-with-vw-until-2023")
        : t("page-pricing_compatible-with-vw-until-2020")
      }}</div>
    </div>
    <p
      class="subtitle"
    >
      {{
        type === 'evo'
        ? t("page-pricing_230-car-models-supported")
        : t("page-pricing_180-car-models-supported")
      }}
    </p>
    <ul
      class="selling-points"
    >
      <li
        v-for="bulletpoint in bulletpoints"
      ><TickRoundIcon :class="{ 'evo': type === 'evo' }" /> <p v-html="bulletpoint"></p></li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref, defineAsyncComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import TickRoundIcon from "@/assets/icons/TickRoundIcon";
import {
  facebookGoogleAnalitycs,
  detectSamsungBrowser,
} from "@/utils/helpers";
import { useTranslation } from "i18next-vue";
import SaleTag from '../SaleTag.vue';
import Icons from "@/utils/icons";
import { ADAPTER_TYPE } from '@/utils/constants';
import ActionButton from '../UI-elements/ActionButton.vue';

const { t } = useTranslation(['shared', 'page-pricing']);

const props = defineProps({
  type: String,
  imgSrc: String,
  imgAlt: String,
  imgTitle: String,
  title: String,
  price: String,
  bulletpoints: Array
});

const store = useStore();
const route = useRoute();

const prices = computed(() => store.state.prices?.prices);
const brands = computed(() => store.state.vehiclebrands.brands);

const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');
const allowAnalyticsEmitInModal = computed(() => store.state.allowVendorsModalEventEmit);

onMounted(() => {
  store.dispatch("vehiclebrands/getBrands");
});

const handleClick = () => {
  const adapterType = props.type;
  const siteUrl = getSiteUrl(adapterType, prices.value.vendors);
  const isAdapterEvo = adapterType === ADAPTER_TYPE.EVO;
  const eventName = buildEventName(route.name);
  const position = 'adapter card';

  if (prices?.value.vendors.length <= 1) {
    emitAnalyticsEvent(eventName, siteUrl, prices.value.vendors[0]?.siteName, isAdapterEvo, position);
    redirectToVendor(siteUrl);

    return;
  }

  if (!allowAnalyticsEmitInModal.value) {
    emitAnalyticsEvent(eventName, siteUrl, prices.value.vendors[0]?.siteName, isAdapterEvo, position);
    showModal(adapterType);

    return;
  }

  showModal(adapterType);
}

const buildEventName = (routeName) => {
  let eventName = "buy_now";
  if (routeName === 'pricing') {
    eventName += "_pricing";
  } else if (routeName === 'pricing-evo') {
    eventName += "_evo_pricing";
  }
  return eventName;
}

const isSingleVendor = function(adapterType){
    if (prices.value.vendors?.length == 1) {
        return getSiteUrl(adapterType, prices.value.vendors);
    }

    return false;
};

const getSiteUrl = (adapterType, vendors) => {
  return adapterType === 'evo'
    ? vendors[0]?.evoSiteUrl
    : vendors[0]?.siteUrl;
}

const showModal =  (adapterType) => {
  store.dispatch("modals/openModal", {
    currentModal: "isVendorModalOpen",
    data: adapterType || true,
  });
}

const redirectToVendor = (url) => {
  window.open(url);
}

const emitAnalyticsEvent = (eventName, siteUrl, siteName, isEvo, position) => {
  facebookGoogleAnalitycs(eventName, {
      siteName,
      siteUrl,
      button_position: position,
      adapterName: isEvo ? 'EVO' : 'OBD',
  })
}

const saleTagLink = computed(() => {
  return process.env.VUE_APP_FREE_DIAGNOSTICS_URL;
})
</script>


<style lang="scss" scoped>

.content-item {
  width: 477px;
}

.offer-details-link {
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  color: #3973B9;
  text-decoration: underline;
}

.action-button {
  align-self: center;
}
</style>
