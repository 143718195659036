import mitt from 'mitt';

class Events {
  constructor() {
    this.emitter = mitt();
  }

  emit(event, data = null) {
    this.emitter.emit(event, data);
  }

  on(event, callback) {
    this.emitter.on(event, callback);
  }

  off(event, callback) {
    this.emitter.off(event, callback);
  }
}

export default new Events();
