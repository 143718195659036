<template>
  <section class="container">
    <div class="homepage__section homepage__intro">
      <div class="content">
        <h1 :class="{ 'title-animate': isActive, show: show }">
          {{ h1text }}
        </h1>
        <h2 :class="{ sub_title_animate: isActive }">
          {{ h2text }}
        </h2>
        <div class="homepage__intro__row">
          <div class="left">
            <div
              class="animations-phone"
              :class="{ animate: isActive, 'move-top': MoveUp }"
            >
              <img
                :alt="imagePhoneAlt"
                :src="imagePhone"
                :title="imagePhoneTitle"
                loading="lazy"
              />
            </div>
            <div
              class="aminations-adapter"
              :class="{ animate: isActive, 'move-top': MoveUp }"
            >
              <img
                :alt="imageAdapterAlt"
                :src="imageAdapter"
                :title="imageAdapterTitle"
                loading="lazy"
              />
            </div>
          </div>
          <div
            class="embark"
            :class="{ animate: isActive, 'move-top': MoveUp }"
          >
            <GetStarted
              :to="`/${langCode}/supported-cars`"
              :subtitle="t('shared_check-what-we-support-for-your-car')"
              className="white"
              fbEventParam="home page up"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import GetStarted from '../GetStarted.vue';

const { t } = useTranslation(['page-home', 'shared']);

const props = defineProps({
  active: Number,
  imagePhone: String,
  imageAdapter: String,
  imagePhoneAlt: String,
  imagePhoneTitle: String,
  imageAdapterAlt: String,
  imageAdapterTitle: String,
  show: Boolean,
  h1text: String,
  h2text: String,
});

const store = useStore();

const langCode = computed(() => store.state.language.lang?.code);

const isActive = computed(() => props.active > 0);
const MoveUp = computed(() => props.active > 1);
</script>
