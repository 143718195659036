<template>
  <div class="title-container">
    <Transition name="fade-slide" mode="out-in">
      <h1
        key="title0"
        v-if="
          !Object.keys(expected).length &&
          !(selector.make && selector.model && selector.generation)
        "
      >
        {{ t("page-supported-cars_loading-vehicles") }}
      </h1>

      <h1 key="title1" v-else-if="expected.param === 'make' ? true : false">
        {{ t("page-supported-cars_choose-your-car-brand") }}
      </h1>

      <h1 key="title2" v-else-if="expected.param === 'model' ? true : false">
        {{ modelHeading }}
      </h1>

      <h1
        key="title3"
        v-else-if="expected.param === 'generation' ? true : false"
      >
        {{ genHeading }}
      </h1>
    </Transition>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTranslation } from "i18next-vue";
import { addParamsToStringFromCMS } from "@/utils/helpers";

const store = useStore();
const { t } = useTranslation(['page-supported-cars']);

const expected = computed(() => store.state.vehiclebrands.wizard.expected);
const selector = computed(() => store.state.vehiclebrands.wizard.selector);
const isMobile = computed(() => store.state.device.isMobile);

const modelHeading = computed(() => {
  return addParamsToStringFromCMS(
    t("page-supported-cars_choose-your-model"),
    [selector.value.make?.title]
  );
});

const genHeading = computed(() => {
  const model = (isMobile.value && selector.value.model?.title.length > 12)
    ? selector.value.model?.title.substr(0, 12) + "..."
    : selector.value.model?.title;
  const makeAndModel = selector.value.make?.title + " " + model;
  return addParamsToStringFromCMS(t("page-supported-cars_choose-your-generation"), [makeAndModel]);
});
</script>
