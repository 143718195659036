<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.59375 11H1.41406V3.99219H3.59375V11ZM2.49219 3.05469C1.8125 3.05469 1.25 2.46875 1.25 1.76562C1.25 0.804688 2.28125 0.195312 3.125 0.6875C3.52344 0.898438 3.75781 1.32031 3.75781 1.76562C3.75781 2.46875 3.19531 3.05469 2.49219 3.05469ZM11.7266 11H9.57031V7.60156C9.57031 6.78125 9.54688 5.75 8.42188 5.75C7.29688 5.75 7.13281 6.61719 7.13281 7.53125V11H4.95312V3.99219H7.03906V4.95312H7.0625C7.36719 4.41406 8.07031 3.82812 9.125 3.82812C11.3281 3.82812 11.75 5.28125 11.75 7.15625V11H11.7266Z"
      fill="white"
    />
  </svg>
</template>
