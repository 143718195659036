<template>
  <div v-if="adapter" class="adapter-page">
    <BreadCrumb class="text-white"></BreadCrumb>
    <div class="container-wrapper-evo page">
      <div class="container container-hero">
        <h1 class="section-title">{{ adapter.headings.h1[0] }}</h1>
        <h2 class="section-subtitle">
          {{ adapter.headings.h2[0] }}
        </h2>
        <div v-if="isMobile && isInUK && $appConfig.saleEnabled" class="sale-tag-container">
          <SaleTag :text="t('shared_free-diagnostics')" />
        </div>
        <img
          :src="adapter.images[0].url"
          :alt="adapter?.images[0]?.imageMeta?.metaAltDescription"
          :title="adapter?.images[0]?.imageMeta?.metaTitle"
          loading="lazy"
        />
        <div class="container-hero__text">
          <p v-if="!isMobile && isInUK && $appConfig.saleEnabled">
            <SaleTag :text="t('shared_free-diagnostics')" />
          </p>
          <p>
            {{ t("page-adapter_adapter-description-1") }}
          </p>
          <p>
            {{ t("page-adapter_adapter-description-2") }}
          </p>
          <p v-if="isInUK && $appConfig.saleEnabled">{{
            t("Order NOW for FREE Carista App Diagnostics. Lifetime access is included. Terms apply.")
          }}</p>
          <p class="price">{{ prices?.currency }}{{ prices?.evoAdapterPrice }}</p>
          <button
            @click="openVendorModal('evo')"
            class="btn-feedback-outlined lighter"
          >
            {{ t("page-adapter_buy-now") }} <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
    <div class="container-wrapper page">
      <div class="container container-hero">
        <h2 class="section-title">{{ adapter.headings.h2[1] }}</h2>
        <h2 class="section-subtitle">
          {{ adapter.headings.h2[2] }}
        </h2>
        <img
          :src="adapter.images[1].url"
          :alt="adapter?.images[1]?.imageMeta?.metaAltDescription"
          :title="adapter?.images[1]?.imageMeta?.metaTitle"
          loading="lazy"
        />
        <div class="container-hero__text">
          <p>
            {{ t("page-adapter_adapter-description-3") }}
          </p>
          <p class="price">{{ prices?.currency }}{{ prices?.adapterPrice }}</p>
          <button
            @click="openVendorModal('obd')"
            class="btn-feedback-outlined lighter"
          >
            {{ t("page-adapter_buy-now") }} <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
    <SupportedBrands />
    <HowItWorks
      id="adapterPageHowItWorks"
      :active="active"
      :activeStep="activeStep"
      @changeActiveStep="changeActiveStep"
    />
    <AppPricing id="appPricing" />
    <FeatureHighlights />
    <UserRatings/>
    <VueperSlider
      :title="adapter?.headings?.h4[0]"
      :testimonials="adapterTestimonials"
      :active="6"
      :static="1"
    />
    <Faq :active="7" :static="true" :faqList="adapterFaq" :title="adapter?.headings?.h4[1]" />
    <Embark
      :title="[
        adapter.headings?.h4[2],
        adapter.headings?.h4[3],
        adapter.headings?.h4[4],
      ]"
      :btnSubtitle="t('shared_check-what-we-support-for-your-car')"
      fbEventParam="adapter page"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeMount, onBeforeUnmount, watch } from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import SupportedBrands from "../components/adapter/SupportedBrands";
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import HowItWorks from "../components/HowItWorks";
import AppPricing from "../components/adapter/AppPricing";
import VueperSlider from "../components/VueperSlider";
import Faq from "../components/Faq";
import Embark from "../components/Embark";
import ArrowRightIcon from "../assets/icons/supportedCars/ArrowRightIcon";
import {
  createMetaInfo,
  facebookGoogleAnalitycs,
  mapLangFullNameToCode
} from "@/utils/helpers";
import { useTranslation } from "i18next-vue";
import { useHead } from '@unhead/vue';
import SaleTag from '@/components/SaleTag.vue';
import FeatureHighlights from '../components/FeatureHighlights.vue';
import UserRatings from '../components/UserRatings.vue';
import { TESTIMONIALS_CATEGORY_ID, FAQ_CATEGORY_ID } from '@/utils/constants';

const store = useStore();
const router = useRouter();
const { t } = useTranslation(['shared', 'page-adapter']);
const active = ref(0);
const activeStep = ref(1);
const loading = computed(() => store.state.loading);
const sectionHowItWorksOffset = ref(null);
const sectionMobileAppAndAdapterOffset = ref(null);
const reducedConstant = ref(400);
const isMobile = computed(() => store.state.device.isMobile);

const adapter = computed(() => store.state.pages?.adapter);
const prices = computed(() => store.state.prices?.prices);
const adapterFaqCategory = FAQ_CATEGORY_ID.ADAPTERS_PAGE;
const adapterFaq = computed(() => store.state.faq?.faq[adapterFaqCategory - 1]);
const adapterTestimonials = computed(
  () => store.state.testimonials?.testimonials[TESTIMONIALS_CATEGORY_ID.ADAPTERS_PAGE - 1]
);
const langCode = computed(() => store.state.language.lang?.code);
const isInUK = computed(() => store.state.prices?.prices?.countryCode === 'GB');

const changeActiveStep = ($event) => {
  activeStep.value = $event;
};

const checkForCampability = (brand) => {
  store.commit("vehiclebrands/wizard", {
    name: "make",
    value: brand,
  });
  router.push({ name: "supported-cars" });
};

const openVendorModal = (adapterType) => {
  const siteUrl = adapterType === 'evo'
    ? prices.value.vendors[0]?.evoSiteUrl
    : prices.value.vendors[0]?.siteUrl;
  const isEvo = adapterType === 'evo';

  if (prices?.value.vendors.length <= 1) {
    facebookGoogleAnalitycs("buy_now", {
      siteName: prices.value.vendors[0]?.siteName,
      siteUrl: siteUrl,
      adapterName: isEvo ? 'EVO' : 'OBD',
    });
    window.open(siteUrl);
  } else {
    store.dispatch("modals/openModal", {
      currentModal: "isVendorModalOpen",
      data: adapterType || true,
    });
  }
};

const handleAdapterScroll = () => {
  if (
    window.top.scrollY >
      sectionHowItWorksOffset.value - reducedConstant.value &&
    window.top.scrollY <
      sectionMobileAppAndAdapterOffset.value - reducedConstant.value
  ) {
    active.value = 5;
    calcActiveStep();
  } else if (
    window.top.scrollY >
    sectionMobileAppAndAdapterOffset.value - reducedConstant.value
  ) {
    active.value = 6;
  }
};

const calcOffsets = () => {
  if (!loading.value) {
    setTimeout(() => {
      // Select and get all sections offsets for visual animations
      const sectionHowItWorks = document.querySelector("#adapterPageHowItWorks");
      const mobileAppAndAdapter = document.querySelector("#appPricing");

      sectionHowItWorksOffset.value = sectionHowItWorks?.getBoundingClientRect().top + window.scrollY;
      sectionMobileAppAndAdapterOffset.value = mobileAppAndAdapter?.getBoundingClientRect().top + window.scrollY;

      // decrease the value for mobile devices to switch active state 200 pixels before section's offset top
      if (isMobile.value) reducedConstant.value = 200;

      window.addEventListener("scroll", handleAdapterScroll);
    }, 1000);
  }
};

const calcActiveStep = () => {
  const stepHeight =
    (sectionMobileAppAndAdapterOffset.value - sectionHowItWorksOffset.value) / 3;
  if (
    window.top.scrollY <
    sectionHowItWorksOffset.value + stepHeight - 400
  ) {
    activeStep.value = 1;
  } else if (
    window.top.scrollY > sectionHowItWorksOffset.value + stepHeight - 400 &&
    window.top.scrollY < sectionHowItWorksOffset.value + stepHeight * 2 - 700
  ) {
    activeStep.value = 2;
  } else if (
    window.top.scrollY >
    sectionHowItWorksOffset.value + stepHeight * 2 - 700
  ) {
    activeStep.value = 3;
  }
};

onBeforeMount(async () => {
  if (
    !adapterTestimonials.value ||
    (adapterTestimonials.value &&
      mapLangFullNameToCode(adapterTestimonials?.value[0].language) !== langCode.value)
  ) {
    await store.dispatch(
      "testimonials/getTestimonials",
      { lang: langCode.value, categoryId: TESTIMONIALS_CATEGORY_ID.ADAPTERS_PAGE }
    );
  }

  if (
    !adapterFaq.value ||
    (adapterFaq.value && mapLangFullNameToCode(adapterFaq?.value[0].language) !== langCode.value)
  ) {
    await store.dispatch("faq/getFaq", { lang: langCode.value, categoryId: adapterFaqCategory });
  }
});

onMounted(() => {
  document.body.classList.remove("overflow-hidden");
  store.dispatch("vehiclebrands/getBrands");
  calcOffsets();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleAdapterScroll);
});

watch(loading, (newVal) => {
  if (!newVal) {
    calcOffsets();
  }
});
watch(langCode, (newVal) => {
  store.dispatch(
    "testimonials/getTestimonials", { lang: newVal, categoryId: TESTIMONIALS_CATEGORY_ID.ADAPTERS_PAGE }
  );
  store.dispatch("faq/getFaq", { lang: newVal, categoryId: adapterFaqCategory });
});

useHead(() => {
    return createMetaInfo({
        title: adapter.value?.metaTitle,
        pageMeta: adapter.value,
    });
});
</script>
