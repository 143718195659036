import http from './http.service';
import app from '../main';

export default {

  getCookie() {
    return true;
    // return http.get('/csrf-cookie');
  },

  async register(user) {
    await this.getCookie();
    return http.post('/api/register', user);
  },

  async login(user) {
    await this.getCookie();
    return http.post('/login', user);
  },

  async logout() {
    await this.getCookie();
    return http.post('/logout');
  },

  isAuthenticated() {
    this.getCookie();
    // http.get('/api/user')
    //   .then((res) => {
    //     if (res.status === 200) {
    //       const profile = res.data.data;
    //       app.$store.commit('profile/profile', profile);
    //       app.$store.dispatch('auth/authSuccess', {
    //         isAuthenticated: true,
    //         redirect: app.$router.currentRoute.name === 'home',
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     app.$store.dispatch('auth/resetState');
    //   });
  },

};
